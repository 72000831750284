import React, { FC } from 'react'
const styles = require('./Toggle.module.scss')

type Props = { selected?: boolean; onChange?: () => void }
const Toggle: FC<Props> = ({ selected, onChange }) => (
  <button className={`${styles.Main} ${selected ? styles['Main--Active'] : ''}`} onClick={onChange}>
    <div className={`${styles.Thumb} ${selected ? styles['Thumb--Active'] : ''}`}></div>
  </button>
)
export { Toggle }
