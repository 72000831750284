import React, { FC, ReactNode, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { SimpleCard } from '../SimpleCard'
import { Content, Footer } from '../SimpleCard/components'

const styles = require('./SimpleTab.module.scss')

export type Pane = { icon?: string; title: string; render: () => ReactNode } & any
type Props = { initial?: number; footer?: ReactNode; panes?: Pane[]; onChange?: (index: number) => void }
const SimpleTab: FC<Props> = ({ initial, footer, panes, onChange }) => {
  const [index, setIndex] = useState(initial || 0)
  const pane = panes && <Content>{panes[index].render}</Content>
  const handleIndexChange = (i: number) => {
    setIndex(i)
    onChange && onChange(i)
  }

  return (
    <>
      <SimpleCard padding="heavy" fluid base>
        {panes && (
          <Button.Group className={styles.Tabs}>
            {panes?.map((p: Pane, i: number) => (
              <Button
                key={p.type}
                size="large"
                type="button"
                active={index === i}
                onClick={() => handleIndexChange(i)}
              >
                {p.icon && <img height={23} src={p.icon} />}
                {p.title}
              </Button>
            ))}
          </Button.Group>
        )}
        {/* pane */}
        {pane}
        {footer && <Footer>{footer}</Footer>}
      </SimpleCard>
    </>
  )
}
export { SimpleTab }
