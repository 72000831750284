import React from 'react'

const TrsutpilotIcon = require('~assets/images/trustpilot/trustpilot-star.svg')
const WhiteStar = require('~assets/images/trustpilot/star-white.svg')
const ShieldIcon = require('~assets/images/trustpilot/shield.svg')
const LockIcon = require('~assets/images/trustpilot/lock.svg')

const StarFull = (): React.JSX.Element => (
  <div className="order-summary-trustpilot-top-stars-full">
    <img height={11} src={WhiteStar} alt="star" />
  </div>
)

const BottomRow = ({ icon, text }: { icon: string; text: string }): React.JSX.Element => (
  <div className="order-summary-trustpilot-bottom-row">
    <img height={16} src={icon} alt="shield" />
    <span>{text}</span>
  </div>
)

const TrustpilotCard = ({ className }:{ className?:string }): React.JSX.Element => (
  <div className={`order-summary-trustpilot ${className}`}>
    <div className="order-summary-trustpilot-top">
      <div className="order-summary-trustpilot-top-title">
        <img height={20} src={TrsutpilotIcon} alt="trustpilot" />
        <span>Trustpilot</span>
      </div>
      <div className="order-summary-trustpilot-top-scores">
        <div className="order-summary-trustpilot-top-stars">
          <StarFull />
          <StarFull />
          <StarFull />
          <StarFull />
          <div className="order-summary-trustpilot-top-stars-half">
            <img height={11} src={WhiteStar} alt="star-1" />
          </div>
        </div>
        <span className="order-summary-trustpilot-top-reviews">TrustScore <span className="order-summary-trustpilot-top-reviews-bold">4.6 | 428</span> reviews</span>
      </div>
    </div>
    <div className="order-summary-trustpilot-divider" />
    <div className="order-summary-trustpilot-bottom">
      <BottomRow icon={ShieldIcon} text="Privacy Guarantee" />
      <BottomRow icon={LockIcon} text="Encrypted Secure Checkout" />
    </div>
  </div>)

export default TrustpilotCard
