import React, { FC } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Loader } from 'semantic-ui-react'
import { Alerts } from './alerts/Alerts'
import { ProtectionArea } from './protectionArea'
import { LaunchIdentisafe } from './LaunchIdentisafe'
import { IdentisafeInformation } from '../../type'

type FetchData = {
  account: { usage: { identityProtection: { current: number } } }
  identisafeInformation: IdentisafeInformation
}

const FETCH_QUERY = gql`
  {
    account { usage { identityProtection { current } } }
    identisafeInformation { disabled issueDetected personalInformation darkWebMonitoring alerts }
  }
`

const Dashboard: FC = () => {
  const { data, loading } = useQuery<FetchData>(FETCH_QUERY, { fetchPolicy: 'no-cache' })

  const isLoading = (data === null || data === undefined) && loading
  const hasDarkWebMonitoring = (data?.account.usage.identityProtection.current || 0) >= 2

  return (
    <div>
      {isLoading && (
        <Loader active />
      ) || data && (
        <>
          <ProtectionArea data={data.identisafeInformation} hasDarkWebMonitoring={hasDarkWebMonitoring} />
          <Alerts alerts={data.identisafeInformation.alerts} />
        </>
      )}
    </div>
  )
}

export { Dashboard }
