import React, {useState, VFC} from 'react'
import {IntroStep} from './cancel/IntroStep'
import {CancelledStep} from './cancel/CancelledStep'
import {CancelStep} from './cancel/CancelStep'
import {QuestionnaireStep} from './cancel/QuestionnaireStep'
import {CancelSupportStep} from './cancel/CancelSupportStep'

/// Props for the component
type Props = {
  onCancelled: () => void
  onFinished: () => void
}

/**
 * Shows a confirmation modal for when user want's to cancel the subscription
 *
 * @constructor
 */
const CancelConfirmationModal: VFC<Props> = ({ onCancelled, onFinished }) => {
  /// Current cancel step
  const [currentStep, setCurrentStep] = useState<'intro' | 'questionnaire' | 'support' | 'cancel' | 'cancelled'>('intro')

  const [refundAmount, setRefundAmount] = useState<number>(0)

  switch (currentStep) {
    case 'intro':
      return <IntroStep onNext={() => setCurrentStep('questionnaire')}/>
    case 'questionnaire':
      return <QuestionnaireStep onNext={() => setCurrentStep('support')}/>
    case 'support':
      return <CancelSupportStep onNext={() => setCurrentStep('cancel')}/>
    case 'cancel':
      return <CancelStep onNext={_refundAmount => {
        setRefundAmount(_refundAmount)
        setCurrentStep('cancelled')
      }} onCancelled={onCancelled} />
    case 'cancelled':
      return <CancelledStep refundAmount={refundAmount} onNext={() => {
        onFinished()
      }}/>
  }
}

export {CancelConfirmationModal}
