import React, { FC, useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useNavigation } from '../hooks/useNavigation'
import Router, { Authorizeable } from '../shared/router'
import Notifications from '~shared/notifications'
import Session from '../shared/storages/session'
import Mutation from '../shared/mutation'
import { action } from 'mobx'
import {
  Form,
  FormField,
  Input,
  Image,
  Header,
  Loader,
  Container,
  InputProps,
  InputOnChangeData
} from 'semantic-ui-react'

import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { SimpleLayout } from '../components/SimpleLayout'
import { SimplePasswordInput } from '../components/SimplePasswordInput/SimplePasswordInput'

const SuccessImage = require('~assets/images/success.png')

type Payload = { token: string, firstName: string, lastName: string, password: string }
type CheckToken = { validateInvite: string }
const Invite: FC & Authorizeable = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()
  const { data, loading: gqlLoading } = useQuery<CheckToken>(gql(`
    query($token: String!) { validateInvite(token: $token) }
  `), { variables: { token: Router.qs.token } })

  const [strength, setStrength] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [payload, setPayload] = useState<Payload>({
    token: (Router.qs.token as string),
    firstName: '',
    lastName: '',
    password: '',
  })

  const submitMutation = new Mutation<{ acceptInvite: { token: string } }>(`
    mutation($user: AcceptInviteInput!) { acceptInvite(user: $user) { token } }
  `)

  const submit = () => {
    if(loading) {
      return
    }

    setLoading(true)
    submitMutation.exec({ user: payload }).then(action(() => {
      setLoading(false)

      if(submitMutation.hasErrors) {
        Notifications.error(submitMutation.error())
      } else if(submitMutation.data?.acceptInvite) {
        Session.authenticated = true
        Session.accessToken = submitMutation.data.acceptInvite.token
        Router.redirect('/apps')
      }
    }))
  }

  useEffect(() => {
    setNav('simple')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  useEffect(() => {
    if(data && !data.validateInvite) {
      Notifications.error('Invitation not found')
      Router.redirect('/apps')
    }
  }, [data])

  if(gqlLoading) {
    return (
      <Container>
        <Loader active />
      </Container>
    )
  }

  const handlePasswordChange = (v: string, s: number) => {
    setPayload({ ...payload, password: v })
    setStrength(s)
  }

  const handlePayloadChange = (ev: React.ChangeEvent, d: InputOnChangeData) => {
    setPayload({ ...payload, [d.name]: d.value })
  }

  const ready = strength >= 2 && payload.firstName.length > 0 && payload.lastName.length > 0
  const inputProps = { size: ('large' as InputProps['size']), fluid: true }

  return (
    <SimpleLayout>
      <SimpleBox display="grid" alignItems="center" width="100%">
        <SimpleBox width={590} mx="auto" className="form">
          <SimpleBox textAlign="center">
            <Image src={SuccessImage} height={200} width={200} style={{ margin: '0 auto' }} />
            <SimpleBox my={4}>
              <Header as="h4">Create Your Account</Header>
            </SimpleBox>
          </SimpleBox>
          <SimpleCard rounded="heavy" padding="heavy" fluid>
            <SimpleBox>
              <Form id="reset-password" onSubmit={submit}>
                <SimpleBox mt={2} mb={6}>
                  <FormField>
                    <label htmlFor="email">Email</label>
                    <Input name="email" value={data?.validateInvite} readOnly={true} {...inputProps} />
                  </FormField>
                  <FormField>
                    <label htmlFor="firstName">First Name</label>
                    <Input name="firstName" value={payload.firstName} onChange={handlePayloadChange} {...inputProps} />
                  </FormField>
                  <FormField>
                    <label htmlFor="lastName">Last Name</label>
                    <Input name="lastName" value={payload.lastName} onChange={handlePayloadChange} {...inputProps} />
                  </FormField>
                  <FormField>
                    <label htmlFor="password">Password</label>
                    <SimplePasswordInput value={payload.password} onChange={handlePasswordChange} {...inputProps} />
                  </FormField>
                  <SimpleBox mt={2}>
                    <div>
                      Your password should contain at least 8 characters and include a combination of upper and
                      lower case letters, numbers, and symbols.
                    </div>
                  </SimpleBox>
                </SimpleBox>
                <SimpleBox maxWidth={276} mx="auto">
                  <Form.Button
                    color="red"
                    size="huge"
                    content="Create account"
                    disabled={loading || !ready}
                    loading={loading}
                    primary
                    fluid
                  />
                </SimpleBox>
              </Form>
            </SimpleBox>
          </SimpleCard>
        </SimpleBox>
      </SimpleBox>
    </SimpleLayout>
  )
}

Invite.authorize = false
Invite.auth = '/apps'

export default Invite
