import React, { FC } from 'react'
import { Form } from 'semantic-ui-react'

const countries = require('../../../../assets/countries.json')
const countryOptions = Object.keys(countries)
  .map(key => ({key: key, value: key, flag: countries[key].toLowerCase(), text: countries[key]}))
  .sort((a, b) => a.text.localeCompare(b.text))

type Props = {
    country: string
    onChange: (country: string) => void
}

const CountryInput: FC<Props> = ({country, onChange}) => (
  <Form.Field>
    <label>Country</label>
    <Form.Dropdown
      fluid
      search
      selection
      required
      value={country}
      onChange={(event, data) => { onChange(data.key) }}
      options={countryOptions}
    />
  </Form.Field>
)

export { CountryInput }
