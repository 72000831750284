import React, { ChangeEvent, useState, VFC } from 'react'
import { Image, Input as SemanticInput, InputOnChangeData, InputProps } from 'semantic-ui-react'
import { emailRegex } from '../../../../const'

const OKIcon = require('~assets/images/icons/ok-circle.svg')
const ErrorIcon = require('~assets/images/icons/error-circle.svg')

type Props = Partial<Omit<InputProps, 'onChange'>> & {
  restrict?: boolean
  showValidation?: boolean
  onChange?: (state: { name: string, value: string }) => void
  handleInputBlur?: () => void
}

const Input: VFC<Props> = ({
  type,
  name,
  pattern,
  restrict,
  value,
  required,
  disabled,
  showValidation,
  onChange,
  handleInputBlur,
  ...others
}) => {
  const isEmail = type === 'email'
  //const [localValue, setLocalValue] = useState(value)
  //const [isDirty, setIsDirty] = useState(false)
  const [focused, setFocused] = useState(false)
  const regex = isEmail ? emailRegex : new RegExp(pattern || '', '')

  const isValid = regex && regex.test(value)
  const displayValidation = (value || focused) && required && showValidation

  const handleFocus = (_e: any) => {
    setFocused(true)
  }

  const handleBlur = (_e: any) => {
    if (!required && !restrict) {
      setFocused(false)
    }
    handleInputBlur && handleInputBlur()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    if (!restrict || (restrict && isValid)) {
      onChange && onChange({ name, value: data.value })
    }
  }

  const validationCheckMarkup = isValid ? (
    <Image src={OKIcon} height={22} style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }} />
  ) : (
    <Image src={ErrorIcon} height={22} style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }} />
  )

  const iconMarkup = displayValidation && validationCheckMarkup

  return (
    <SemanticInput
      name={name}
      type={type}
      value={value}
      onChange={handleChange}
      icon={iconMarkup}
      required={required}
      disabled={disabled}
      error={displayValidation && !isValid}
      {...others}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  )
}
export { Input }
