import Mutation from '../shared/mutation'
import {toMoney, toPercent} from '../shared/format'

export type ChargebeeCoupon = {
    id: string
    name: string
    durationType: 'FOREVER' | 'ONCE' | 'LIMITED_PERIOD'
    discountType: 'FIXED_AMOUNT' | 'PERCENTAGE'
    discountPercentage: number
    discountAmount: number
}

export type Coupon = Partial<ChargebeeCoupon>

export type CouponData = {
    coupon: ChargebeeCoupon
}

export const CouponDiscount = (coupon: Coupon): string => {
  if (!coupon?.discountType) {
    return toMoney(0)
  } else if (coupon.discountType === 'FIXED_AMOUNT') {
    return toMoney(coupon.discountAmount || 0)
  } else {
    return toPercent(coupon.discountPercentage || 0, 0)
  }
}

/**
 * Fetches a coupon. Promise is resolved if couponName or priceId is missing
 *
 * @param priceId The id of the plan to fetch coupon for
 * @param couponName The coupon name to fetch coupon for
 * @returns A promise that either resolves with a or without a coupon
 */
export const FetchCoupon = (priceId?: string, couponName?: string): Promise<ChargebeeCoupon | undefined> => {
  if (!couponName || !priceId) {
    return Promise.resolve(undefined)
  }

  const couponLoad = new Mutation<CouponData>(`
    mutation($code: String!, $priceId: ID) {
      coupon: redeemCoupon(code: $code, itemPriceId: $priceId) {
        id name discountType discountPercentage discountAmount
      }
    }
  `)

  return new Promise((resolve, reject) => {
    couponLoad.exec({code: couponName, priceId: priceId}, undefined, {fetchPolicy: 'no-cache'}).then(() => {
      (!couponLoad.hasErrors && !!couponLoad.data) ? resolve(couponLoad.data.coupon) : reject()
    })
  })
}
