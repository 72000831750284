import React, { Component, FunctionComponent, ReactNode, MouseEvent } from 'react'

import {
  Icon,
  List,
  Segment,
  Transition,
  SemanticICONS,
  SemanticCOLORS,
  StrictSegmentProps,
} from 'semantic-ui-react'
import { action, observer, observable } from '@decorators'

// import { isEnv } from '#root/config'

export type NotificationTypes = 'success' | 'error' | 'warning' | 'fatal' | 'info' | 'highlight'
export type NotificationStype = { color: SemanticCOLORS, icon?: SemanticICONS } & StrictSegmentProps

type ItemProps = {
  id: string
  type: NotificationTypes
  message: ReactNode
  dismissible?: boolean
  timing?: number
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  onDissmiss?: (e?: MouseEvent<HTMLDivElement>) => void
} & NotificationStype

type AddRest = Partial<Exclude<ItemProps, 'id' | 'message'>>
type NotificationFn = (message: ReactNode, rest?: AddRest) => void

// const INTERNAL_ERROR_MESSAGE = 'Oops! You should not be seeing this.'
// const IS_PRODUCTION = isEnv('production')

const DEFAULT_TIME = 5000
const DEFAULT_WORD_TIME = 300

const STYLES: Record<NotificationTypes, NotificationStype> = {
  success:   { color: 'green', icon: 'check circle' },
  error:     { color: 'red', icon: 'exclamation triangle' },
  warning:   { color: 'yellow', icon: 'warning circle' },
  fatal:     { color: 'red', icon: 'warning circle' },
  info:      { color: 'teal', icon: 'warning' },
  highlight: { color: 'blue', inverted: true },
}

function noop() {
  // do nothing
}

class Notifications {
  static View: FunctionComponent

  @observable
  static items: Record<string, ItemProps> = {}

  // TODO: Improve this! Typescript is hard to work on static level
  static info: NotificationFn = noop

  static error: NotificationFn = noop

  static fatal: NotificationFn = noop

  static success: NotificationFn = noop

  static warning: NotificationFn = noop

  static highlight: NotificationFn = noop

  static get random(): number {
    return Math.floor(1000000 * Math.random())
  }

  @action
  static add(type: NotificationTypes, message: ReactNode, rest: AddRest = {}): void {
    const id = `${Date.now()}.${Notifications.random}`
    Notifications.items[id] = Object.assign(STYLES[type], rest, { id, type, message })
  }
}

class NotificationItem extends Component<ItemProps> {
  static defaultProps: Partial<ItemProps> = {
    dismissible: false,
  }

  timing: ReturnType<typeof setTimeout> | null = null

  get wordsSize(): number {
    const { message } = this.props
    return (message && typeof(message) === 'string') ? message.split(/\s+/).length : 0
  }

  componentDidMount(): void {
    const { dismissible, timing } = this.props
    if(dismissible) {
      return
    }

    const setTime = timing || (this.wordsSize * DEFAULT_WORD_TIME)
    this.timing = setTimeout(this.dissmiss, (setTime < DEFAULT_TIME) ? DEFAULT_TIME : setTime)
  }

  dissmiss = action((ev?: MouseEvent<HTMLDivElement>): void => {
    if(this.timing) {
      clearTimeout(this.timing)
    }

    const { id, onClick, onDissmiss } = this.props
    ev?.type === 'click' && onClick && onClick(ev)
    onDissmiss && onDissmiss(ev)

    delete Notifications.items[id]
  })

  render() {
    const { type, message, dismissible, icon, color, ...rest } = this.props
    const inverted = !!rest.inverted

    return (
      <Segment className={type} color={color} {...rest} onClick={this.dissmiss} inverted={!inverted}>
        {icon && <Icon color={inverted ? color : undefined} name={icon} />}
        {message}
      </Segment>
    )
  }
}

Object.keys(STYLES).forEach((key: NotificationTypes) => {
  Object.defineProperty(Notifications, key, {
    writable: false,
    enumerable: false,
    configurable: true,
    value: (message: ReactNode, rest: AddRest = {}): void => {
      Notifications.add(key, message, rest)
    },
  })
})

Notifications.items = {}
Notifications.View = observer(() => (
  <Transition.Group as={List} id="notifications" animation="slide down" duration={200} divided>
    {Object.keys(Notifications.items).map(key => (
      <List.Item key={key}><NotificationItem {...Notifications.items[key]} /></List.Item>
    ))}
  </Transition.Group>
))

export default Notifications
