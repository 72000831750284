import React, {FC, useEffect} from 'react'
import Router from './router'
import Cookies from 'universal-cookie'
import Session from './storages/session'

const cookies = new Cookies()

/**
 * Helper component to watch for ?ref query params.
 *
 * @param children
 * @constructor
 */
const AffiliateWatcher: FC = ({children}) => {
  useEffect(() => {
    Session.affiliate = {
      affiliateId: (Router.qs.aff as string),
      clickId: (Router.qs.cid as string),
      pubId: (Router.qs.pubid as string),
    }
  }, [Router.qs.aff, Router.qs.cid, Router.qs.pubid])

  return <>{children}</>
}

export default AffiliateWatcher
