import React, {useEffect, VFC} from 'react'
import {Container, Loader, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../components/SimpleBox'
import {gql, useQuery} from '@apollo/client'
import Mutation from '../../shared/mutation'
import {action} from 'mobx'
import Notifications from '../../shared/notifications'

const checkmarkIcon = require('../../assets/images/icons/icon-checkmark-white.svg')

type QuestionnaireStepProps = {
  onNext: () => void;
}

type CancellationReason = {
  id: string;
  text: string;
  sorting: number
}

type CancellationReasonResponse = {
  cancellationReasons: CancellationReason[];
}

/**
 * Renders a list of cancellation reasons for the user to vote on
 *
 * @param onNext
 * @constructor
 */
const QuestionnaireStep: VFC<QuestionnaireStepProps> = ({onNext}) => {
  /// The currently selected reason
  const [selectedReason, setSelectedReason] = React.useState<CancellationReason|undefined>(undefined)

  /// Fetch the cancellation reasons
  const { data, loading } = useQuery<CancellationReasonResponse>(
    gql`
      query {
        cancellationReasons {
          id
          text 
          sorting
        }
      }
    `
  )

  /// Vote on the selected reason
  const voteMutation = new Mutation<{voteCancellationReason: boolean}>(`
    mutation voteCancellationReason($id: ID!) {
      voteCancellationReason(id: $id)
    }
  `)

  /// When user selects a reason then tell the API
  useEffect(() => {
    if(selectedReason) {
      voteMutation.exec({id: selectedReason.id}).then(action(() => {
        if (voteMutation.data?.voteCancellationReason) {
          onNext()
        } else {
          Notifications.error(voteMutation.error() || 'Unable to save answer', {timing: 8000})
        }
      }))
    }
  }, [selectedReason])

  /// While loading reasons we just show a spinner
  if(loading) {
    return (
      <Container>
        <Loader active />
      </Container>
    )
  }

  /// Return true if provided reason is the selected one
  const isSelected = (reason: CancellationReason): boolean => selectedReason?.id === reason.id

  /**
   * Render a cancellation reason
   *
   * @param reason
   */
  const renderQuestion = (reason: CancellationReason) => <a key={`question-${reason.id}`} onClick={() => { setSelectedReason(reason) }}><SimpleBox
    display={'flex'}
    flexDirection={'row'}
    backgroundColor={'#ffffff'}
    borderRadius={18}
    marginBottom={4}
    padding={20}
    alignItems={'center'}>
    {!isSelected(reason) && (<SimpleBox borderColor={'#B0B4C2'} borderStyle={'solid'} borderWidth={1} borderRadius={8} width={24} height={24} marginRight={12}/>)}
    {isSelected(reason) && (<SimpleBox
      borderColor={'#1AB233'}
      borderStyle={'solid'}
      borderWidth={1}
      borderRadius={8}
      width={24}
      height={24}
      backgroundColor={'#1AB233'}
      marginRight={12}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}><img src={checkmarkIcon} height={9} width={12} /></SimpleBox>)}
    {!isSelected(reason) && (<SimpleBox as={'p'} fontSize={'16px'} color={'#787E8D'} fontWeight={400}>{reason.text}</SimpleBox>)}
    {isSelected(reason) && (<SimpleBox as={'p'} fontSize={'16px'} color={'#000000'} fontWeight={500}>{reason.text}</SimpleBox>)}
  </SimpleBox></a>

  const renderOptions = () => [... data?.cancellationReasons || []]
    .sort((a, b) => a.sorting - b.sorting)
    .map(renderQuestion)

  return <><Modal.Header>
    <SimpleBox display="flex" justifyContent="center" alignItems="center">
      <SimpleBox as={'p'} color={'#000000'} fontWeight={700} fontSize={30} textAlign={'center'} marginTop={55} maxWidth={343}>Could you help us improve the services?</SimpleBox>
    </SimpleBox>
  </Modal.Header>
  <Modal.Content>
    <SimpleBox as={'p'} color={'##787E8D'} fontWeight={400} fontSize={16} textAlign={'center'}>We&apos;re truly sorry to see you go.
      We take great pride in offering industry leading services, and value each and everyone of our customers. Please let us know how we could have better served you.</SimpleBox>
    <SimpleBox as={'p'}
      color={'##787E8D'}
      fontWeight={600}
      fontSize={18}
      textAlign={'center'}
      marginTop={43}
      marginBottom={40}>Why would you like to cancel subscription?</SimpleBox>
    {renderOptions()}
  </Modal.Content></>
}

export {QuestionnaireStep}

