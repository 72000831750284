import { FC } from 'react'
import Router from '../../shared/router'

const SimpleCheckout: FC & { authorize: boolean; auth: string; } = () => {
  Router.redirect(`/checkout-v2/${Router.qs.price}`)
  return null
}

SimpleCheckout.authorize = false
SimpleCheckout.auth = '/apps'

export default SimpleCheckout
