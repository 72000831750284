import React, { FC, useMemo } from 'react'
import { Image } from 'semantic-ui-react'
import { ProtectionStatus } from './ProtectionStatus'

const securedImage = require('../../../assets/images/icons/protection-active-icon.svg')
const issueImage = require('../../../assets/images/icons/protection-issue-icon.svg')
const logo = require('../../../assets/images/logo-symbol-white.svg')

interface Props {
  status: ProtectionStatus;
}

/**
 * Renders a rounded icon with a background image and the logo in the center
 */
export const ProtectionStatusIcon: FC<Props> = ({ status }) => {
  const backgroundImage = useMemo(() => {
    switch (status) {
      case 'secured':
        return securedImage
      case 'issue-detected':
        return issueImage
    }
  }, [status])

  return (
    <div
      className="protection-status-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Image src={logo} className="protection-active-icon" />
    </div>
  )
}
