import React, { FC, useState } from 'react'
import MapBox, { Marker } from 'react-mapbox-gl'
import { detect } from 'detect-browser'
import { gql, useQuery } from '@apollo/client'
import { Container, Image, Button, Loader } from 'semantic-ui-react'
import Session from '~shared/storages/session'

import 'mapbox-gl/dist/mapbox-gl.css'

const unlocked = require('~assets/images/ip-status/unlocked.svg')
const locked = require('~assets/images/ip-status/locked.svg')
const alert = require('~assets/images/ip-status/alert.svg')
const marker = require('~assets/images/ip-status/marker.svg')

const Map = MapBox({ accessToken: (process.env.MAPBOX_API_KEY as string) })

type Result = {
  currentIp: {
    ip: string,
    city: string,
    state: string,
    countryName: string,
    latitude: number,
    longitude: number,
    isp: string,
    isSecure: boolean,
  }
}

const IPStatusBox: FC = () => {
  const browser = detect()
  const [position, setPosition] = useState<number[]>([0, 0, 2])
  const { data } = useQuery<Result>(gql`
     query @product(key: "vpn") { currentIp {
       ip city state countryName latitude longitude isp isSecure
     } }
  `)

  const hideLink = Session.authenticated ? '/apps' : '/pricing'

  const onLoad = () => {
    if(data && data.currentIp) {
      const base = data.currentIp
      setPosition([base.longitude, base.latitude, 13])
    } else {
      setTimeout(onLoad, 200)
    }
  }

  if(!data || !data.currentIp) {
    return (
      <section className="your-id">
        <Container>
          <Loader active />
        </Container>
      </section>
    )
  }

  const { ip, city, state, countryName, isp, isSecure } = data.currentIp
  const className = isSecure ? 'active' : 'inactive'
  const image = isSecure ? locked : unlocked

  return (
    <section className="your-id">
      <Container className={className}>
        <div className="content">
          <div className="ip-infos">
            <div className="image">
              <Image src={image} className={className} height={100} width={100} style={{ position: 'inline-block' }}/>
            </div>
            <div className="infos">
              <div>
                <h5>Your Public IP address:</h5>
                {!isSecure && (<div className="status">EXPOSED</div>)}
              </div>
              <div>
                <h3>{ip}</h3>
                {!isSecure && (<Button data-href={hideLink}>Hide IP address</Button>)}
              </div>
            </div>
          </div>
          <div className="ip-personal">
            <div>
              <div>YOUR BROWSER</div>
              <h6>{isSecure ? 'Unknown' : browser?.name}</h6>
            </div>
            <div>
              <div>INTERNET SERVICE PROVIDER</div>
              <h6>{isSecure ? 'VirtualShield' : isp}</h6>
            </div>
            <div>
              <div>YOUR OPERATING SYSTEM</div>
              <h6>{isSecure ? 'Unknown' : browser?.os}</h6>
            </div>
            <div>
              <div>LOCATION</div>
              <h6>{`${city} - ${state}, ${countryName}`}</h6>
            </div>
          </div>
          {!isSecure && (
            <div className="advices">
              <Image src={alert} height={60} width={60} style={{ position: 'inline-block' }}/>
              WARNING: Your physical location is exposed!
              Any website you visit can see all of this sensitive information about you.
            </div>
          )}
        </div>
        <Map
          style="mapbox://styles/mapbox/satellite-v9"
          className="map"
          onStyleLoad={onLoad}
          zoom={[position[2]]}
          center={[position[0], position[1]]}
          containerStyle={{ width: '100%', height: '100%' }}
        >
          <Marker coordinates={[position[0], position[1]]}>
            <Image src={marker} height={75} width={75} />
          </Marker>
        </Map>
      </Container>
    </section>
  )
}

export { IPStatusBox }
