import React, {useState, VFC} from 'react'
import PhoneInput from 'react-phone-number-input/input'
import {Image} from 'semantic-ui-react'

const styles = require('./PhoneNumberField.module.scss')

const OKIcon = require('~assets/images/icons/ok-circle.svg')
const ErrorIcon = require('~assets/images/icons/error-circle.svg')

type Props = {
  label: string
  value: string | null
  onChange: (value: string) => void
}

const PhoneNumberField:VFC<Props> = ({label, value, onChange}) => {
  const [focused, setFocused] = useState(false)

  const isValid = (value?.length || 0) > 0
  const displayValidation = (value || focused)

  const handleFocus = (_e: any) => {
    setFocused(true)
  }

  const handleBlur = (_e: any) => {
    setFocused(false)
  }

  const validationCheckMarkup = isValid ? (
    <Image src={OKIcon} height={22} style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }} />
  ) : (
    <Image src={ErrorIcon} height={22} style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }} />
  )

  const iconMarkup = displayValidation && validationCheckMarkup

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.input}>
        <PhoneInput
          country="US"
          value={value || ''}
          onChange={onChange as any}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {iconMarkup}
      </div>
    </div>
  )
}

export { PhoneNumberField }
