import React, { FC, useEffect, useState } from 'react'
import { useNavigation } from '../hooks/useNavigation'
import Router, { Authorizeable } from '../shared/router'
import Session from '../shared/storages/session'
import { gql, useMutation } from '@apollo/client'

/**
 * Fresdesh Authorization Inititation URL
 *
 * Navigate to this URL to begin the JWT signin process
 */
export const FRESHDESK_INITIATE_AUTHORIZE_URL = process.env.FRESHDESK_INITIATE_AUTHORIZE_URL || ''

/**
 * Freshdesk Component
 *
 * A user wants to login into freshdesk with their VirtualShield acount. They press sign in on help portal and is redirected here.
 *
 * This component validates if the user is logged in, and if he is then calls the backend to generate a JWT token. When the JWT token is genarted
 * the user is redirect back to freshdesk.
 *
 * If the user is not logged in, the user is redirected to the Sign In screen. When sign in is finished the user is redirected back here and if
 * state/nonce/redirect_uri (from freshdesk) is missing we just restart the process and redirect to INITIATE_AUTHORIZE_URL.
 */
const Freshdesk: FC & Authorizeable = () => {
  /// Navigation context to control layuot
  const { setNav, resetNav, setSide, resetSide } = useNavigation()

  /// Status message if something goes wrong
  const [statusMessage, setStatusMessage] = useState<string>('Please wait while we redirect you ...')

  /// The GraphQL call that generates the JWT token used to pass over to Freshdesk
  const [tokenMutation] = useMutation(gql`
    mutation freshdeskJwt($input: FreshdeskJwtInput!) {
      freshdeskJwt(input: $input)
    }
  `)

  /**
   * Handles the authorization -- see doc of component to understand the
   * flow.
   *
   * @returns Redirects or sets error message
   */
  const handleAuthState = async () => {
    if(Session.authenticated) {
      try {
        const redirectUri = Router.qs.redirect_uri
        const state = Router.qs.state
        const nonce = Router.qs.nonce

        // eslint-disable-next-line no-console
        console.log('Freshdesk SSO Auth', redirectUri, state, nonce)

        /// If any of theses is missing we must restart the flow and go back to freshdesk to get it
        if (!redirectUri || !state || !nonce) {
          // eslint-disable-next-line no-console
          console.log('Redirecting to ', FRESHDESK_INITIATE_AUTHORIZE_URL)
          location.href = FRESHDESK_INITIATE_AUTHORIZE_URL
          return
        }

        // eslint-disable-next-line no-console
        console.log('Generating JWT token ...')
        const result = await tokenMutation({variables: {input: {nonce}}})
        // eslint-disable-next-line no-console
        console.log('JWT token generated', result.data.freshdeskJwt)

        const finalRedirectUri = `${redirectUri}?state=${state}&id_token=${result.data.freshdeskJwt}`

        // eslint-disable-next-line no-console
        console.log('Redirecting to ', finalRedirectUri)
        location.href = finalRedirectUri
      } catch(e) {
        setStatusMessage('There seems to be a problem ...')
      }
    } else {
      Router.redirect('/?ref=freshdesk')
    }
  }

  /**
   * An affect to disable the navigation and inititate `handleAuthStat`. When unmounted
   * navigation is reset.
   */
  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    handleAuthState()
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  return <div className="freshdesk">{statusMessage}</div>
}

Freshdesk.displayName = 'Freshdesk'
Freshdesk.authorize = false

export default Freshdesk
