import { Coupon } from '../utils/coupon'
import { Currencies } from './types'
import Session from './storages/session'

export const DEFAULT_CURRENCY: Currencies = 'USD'
export const LOCALE = 'en'

export function resolveCurrency(currency?: Currencies | string): Currencies {
  return (currency || Session.currency || DEFAULT_CURRENCY).toUpperCase() as Currencies
}

type Extra = Intl.NumberFormatOptions
export function toMoney(value: number, options?: Extra): string {
  const decimal = Number(value).toFixed(2).split('.')[1]
  const val = (value > 0 && (decimal === '00' || decimal === '50')) ? Math.round(value*2)/2 - 0.01 : value
  const { currency, ...rest } = options || {}

  return Intl.NumberFormat(LOCALE, { style: 'currency', currency: resolveCurrency(currency), ...rest }).format(val)
}

export function toMoneyWithoutCalculation(value: number, options?: Extra): string {
  const { currency, ...rest } = options || {}
  return Intl.NumberFormat(LOCALE, { style: 'currency', currency: resolveCurrency(currency), ...rest }).format(value)
}

export function toPercent(value: number, decimals = 0): string {
  return Intl.NumberFormat(LOCALE, {
    style: 'percent',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(value)
}

/**
 * Returns the price substracted by the coupon if given
 * @param price
 * @param coupon
 */
export const toCouponPrice = (price: number, coupon?: Coupon): number => {
  if (coupon) {
    switch (coupon.discountType) {
      case 'PERCENTAGE':
        return price - (price * coupon.discountPercentage!)
      case 'FIXED_AMOUNT':
        return price - coupon.discountAmount!
      default:
        return price
    }
  } else {
    return price
  }
}
