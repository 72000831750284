import React, {FC} from 'react'

const styles = require('./FormContainer.module.scss')

const FormContainer:FC = ({children}) => (
  <div className={styles.container}>
    {children}
  </div>
)

const FormContainerColumn:FC = ({children}) => (
  <div className={styles.column}>
    {children}
  </div>
)

export { FormContainer, FormContainerColumn }
