import React, { FC } from 'react'
import { classNames, variationName } from '@shopify/css-utilities'
import { Action } from '../../../../type'
import { SimpleText } from '../../../SimpleText'
import { CardPadding } from '../../SimpleCard'

const styles = require('./Header.module.scss')

export type HeaderProps = { title?: string; action?: Action; padding?: CardPadding }
export const Header: FC<HeaderProps> = ({ children, title, action, padding }) => {
  const styleMain = classNames(styles.Main, padding && styles[variationName('padding', padding)])
  return (
    <header className={styleMain}>
      {title && (
        <div className={styles.Toolbar}>
          <SimpleText size="title2">{title}</SimpleText>
          {action && (
            <div className="Actions">
              <a onClick={action.onAction}>{action.content}</a>
            </div>
          )}
        </div>
      )}
      {children}
    </header>
  )
}
