import { classNames, variationName } from '@shopify/css-utilities'
import React, { FC } from 'react'
import { CardPadding } from '../../SimpleCard'

const styles = require('./Footer.module.scss')

type Props = { padding?: CardPadding }
const Footer: FC<Props> = ({ children, padding }) => {
  const styleMain = classNames(styles.Main, padding && styles[variationName('padding', padding)])

  return <footer className={styleMain}>{children}</footer>
}
export { Footer }
