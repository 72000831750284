import React, { FC, useCallback } from 'react'
import { Button, Card, Image } from 'semantic-ui-react'
import { gql, useQuery, useMutation } from '@apollo/client'
import Notifications from '../../shared/notifications'
import { App } from '../../shared/types'

type LicenseInfo = { license: string; downloadUrl: string }

const GET_LICENSE = gql`
  { currentLicense(app: "windows-antivirus") { license downloadUrl } }
`

const ADD_LICENSE = gql`
  mutation { createAntivirusLicense { license downloadUrl } }
`

const WindowsAntivirus: FC<{ app: App }> = ({ app }) => {
  const { data, loading: readLoader } = useQuery<{ currentLicense: LicenseInfo }>(GET_LICENSE)
  const [addLicense, { loading: createLoader }] = useMutation<{ createAntivirusLicense: LicenseInfo }>(ADD_LICENSE)
  const loading = readLoader || createLoader

  const onClick = useCallback(() => {
    if (loading) {
      return
    }

    if (data?.currentLicense) {
      window.open(data.currentLicense.downloadUrl, '_blank')
      return
    }

    addLicense().then(({ data }) => {
      const info = data?.createAntivirusLicense

      if (info) {
        window.open(info.downloadUrl, '_blank')
      } else {
        Notifications.error('Something went wrong!')
      }
    }).catch(error => {
      const message = error.graphQLErrors[0]?.message
      message && Notifications.error(message)
    })
  }, [data])

  return (
    <Card fluid className="dense">
      <Card.Content>
        <div className="grid grid-apps-item">
          <Image src={require('~assets/images/icons/os/windows.svg')} height={58} className="mr-1" />
          <h6>{app.name}</h6>
          <div className="text--subdued text--small mr-auto">{app.notes}</div>
          <Button size="big" primary onClick={onClick} loading={loading} disabled={loading}>
            Download
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export { WindowsAntivirus }
