/* eslint-disable @typescript-eslint/ban-ts-comment */
import { classNames, variationName } from '@shopify/css-utilities'
import React, { FC, ReactNode } from 'react'
import { PlanSellingPoint } from '../../../../../../type'
// @ts-ignore
import styles from './Points.module.scss'

type Props = {
  items: PlanSellingPoint[] | undefined
  secondary?: boolean
  description?: boolean
  footer?: ReactNode
  newCheckout?: boolean
  columns?: boolean
}

const Points: FC<Props> = ({ items, secondary, description, footer, newCheckout, columns }) => {
  const styleMain = classNames(
    styles.Main,
    columns && styles.IsColumns,
    secondary && styles.IsSecondary,
    description && styles.IsDescription,
    newCheckout && styles.IsNewCheckout,
    'points-wrapper'
  )

  return (
    <div className={styleMain}>
      {items && (
        <ul>
          {items.map((p: PlanSellingPoint, i: number) => (
            <li key={`sp-${i}`} className={p.status && styles[variationName('status', p.status)]}>
              {p.icon && <img className={styles.Icon} src={p.icon} />}
              {p.title && <span>{p.title}</span>}
              {p.subtitle && <span className={styles.Subtitle}>{p.subtitle}</span>}
            </li>
          ))}
        </ul>
      )}
      {footer && <footer>{footer}</footer>}
    </div>
  )
}

export { Points }
