import React, { FC } from 'react'
import { Form } from 'semantic-ui-react'
import { SimpleForm } from '../../../SimpleForm'

type Prop = {
  email: string
  onChange: (email: string) => void
}

const EmailInput: FC<Prop> = ({email, onChange}) => (
  <Form.Field>
    <label>Email address</label>
    <SimpleForm.Input
      fluid
      value={email}
      required
      showValidation={email.length > 0}
      onChange={({value}) => onChange(value)}
      loading={false}
    />
  </Form.Field>
)

export { EmailInput }
