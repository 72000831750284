// @ts-nocheck
const preChatScript = document.createElement('script')
preChatScript.async = true
preChatScript.src = 'https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js'
document.head.appendChild(preChatScript)

const chatScript = document.createElement('script')
chatScript.async = true
chatScript.src = 'https://wchat.freshchat.com/js/widget.js'
document.head.appendChild(chatScript)

const preChatTemplate = {
  mainbgColor: 'rgb(244, 0, 34)',
  maintxColor: '#fff',
  heading: 'VirtualShield Chat',
  textBanner: 'Thanks for contacting VirtualShield.<br/><br/>Please take a couple of moments to tell us a bit about yourself.',
  SubmitLabel: 'Start Chat',
  fields : {
    field1 : {
      type: 'name',
      label: 'Name',
      fieldId: 'name',
      required: 'yes',
      error: 'Please Enter a valid name'
    },
    field2 : {
      type: 'email',
      label: 'Email',
      fieldId: 'email',
      required: 'yes',
      error: 'Please Enter a valid Email'
    },
    field3 : {
      type: 'phone',
      label: 'Phone',
      fieldId: 'phone',
      required: 'no',
      error: 'Please Enter a valid Phone Number'
    },
  }
}

window.fcSettings = {
  token: '72f29ccf-6c4f-4be6-8a9c-c47d10b0d326',
  host: 'https://wchat.freshchat.com',
  config: {
    cssNames: {
      widget: 'custom_fc_frame',
      expanded: 'custom_fc_expanded'
    }
  },
  onInit: function() {
    window.fcPreChatform.fcWidgetInit(preChatTemplate)
  }
}
