import React, { FC, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { SimpleForm } from '../../../SimpleForm'

type Props = {
  title: string
  value: string
  onChange: (creditCardNumber: string) => void
}

const MaskedNumberInput:FC<Props> = props => {
  /**
   * Keeps track of the real value behind the mask
   */
  const [realValue, setRealValue] = useState<string>(props.value)

  /**
   * Generates a masked value using the real value. The masked value
   * has a form of **** **** **** 1234
   */
  const maskedRealValue = Array.from(realValue).map((value, index) => {
    if (index > 11) {
      return value
    } else if (index === 3 || index === 7 || index === 11) {
      return '• '
    } else {
      return '•'
    }
  }).join('')

  /**
   * Handle input changes. Takes the new input value and calculates
   * the real value (removing mask) and validates that
   * the user only inputs numbers
   *
   * @param value The value of the input field
   */
  const handleChange = (value: string) => {
    let spaces = 0
    if (realValue.length >= 12) {
      spaces = 3
    } else if (realValue.length >= 8) {
      spaces = 2
    } else if (realValue.length >= 4) {
      spaces = 1
    }

    const valueLength = value.length - spaces
    if (valueLength > 16) {
      return
    }

    let newValue = ''
    if (valueLength > realValue.length) {
      newValue = realValue + value.replace(maskedRealValue, '')
    } else {
      newValue = realValue.slice(0, -1)
    }

    /// Only accept numbers
    if (!/^\d+$/.test(newValue.slice(-1)) && newValue.length > 0) {
      return
    }

    setRealValue(newValue)
    props.onChange(newValue)
  }

  return (<Form.Field>
    <label>{props.title}</label>
    <SimpleForm.Input
      fluid
      value={maskedRealValue}
      required
      showValidation={realValue.length === 16}
      onChange={({value}) => handleChange(value)}
      loading={false}
    />
  </Form.Field>)
}

export { MaskedNumberInput }
