import React, { FC } from 'react'
import { Image } from 'semantic-ui-react'
import { SimpleBox } from '../../../SimpleBox'

type Props = {
  image: ImageBitmapSource
}

const InputContainer: FC<Props> = props => (<SimpleBox className="form-input-container">
  <SimpleBox className="image">
    <Image src={props.image} />
  </SimpleBox>
  <SimpleBox className="content">
    {props.children}
  </SimpleBox>
</SimpleBox>)

export { InputContainer }
