import React, {FC, useEffect} from 'react'
import {Button, Image } from 'semantic-ui-react'
import { SimpleBox } from '../SimpleBox'
import {useNavigation} from '../../hooks/useNavigation'
import {gql, useMutation} from '@apollo/client'
import Notifications from '../../shared/notifications'

const CompletedImage = require('../../assets/images/data-removal-setup-complete.svg')

const styles = require('./Onboarding.module.scss')

const SyncPrivacyProfile = gql`mutation sync_privacy_profiles { syncPrivacyProfile { id } }`

type Props = {
  onViewProtectionClicked: () => void
}

const SetupCompleted:FC<Props> = ({ onViewProtectionClicked }) => {
  const { setNav, setSide, resetNav } = useNavigation()
  const [syncPrivacyProfile] = useMutation(SyncPrivacyProfile)

  useEffect(() => {
    syncPrivacyProfile().then(() => {
      Notifications.success('Your privacy profile has been updated.')
      setTimeout(() => {
        onViewProtectionClicked()
      }, 2000)
    }).catch(error => {
      Notifications.error(error.message || 'Something went wrong. Please try again later.')
    })
  }, [])

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    return () => {
      resetNav()
    }
  }, [])

  return (
    <SimpleBox className={styles.onboarding}>
      <Image src={CompletedImage} />
      <h5 className={styles.title} style={{paddingTop: '30px'}}>Setup Complete, redirecting ...</h5>
      <Button primary={true} size="large" onClick={onViewProtectionClicked}>
        View Protection
      </Button>
    </SimpleBox>
  )
}

export { SetupCompleted }
