import React, { FC, useState } from 'react'
import { VerificationFailed } from './VerificationFailed'
import { VerificationSteps } from './VerificationSteps'
import { VerificationSucccess } from './VerificationSuccess'

const VerificationModal: FC = () => {
  const [step, setStep] = useState<'verification' | 'success' | 'failed'>('verification')

  switch(step) {
    case 'verification':
      return <VerificationSteps onCompleted={() => { setStep('success') }} />
    case 'success':
      return <VerificationSucccess />
    case 'failed':
      return <VerificationFailed />
  }
}

export { VerificationModal }
