import React, {VFC} from 'react'
import {Button, Container, Loader, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../components/SimpleBox'
import {gql, useQuery} from '@apollo/client'

const vpnImage = require('../../assets/images/cancel-subscription-vpn.png')
const antivirusImage = require('../../assets/images/cancel-subscription-antivirus.png')
const idMonitoringImage = require('../../assets/images/cancel-subscription-id-monitoring.png')
const shieldWhite = require('../../assets/images/icons/shield-white.svg')

type IntroStepProps = {
    onNext: () => void;
}

const IntroStep: VFC<IntroStepProps> = ({onNext}) => {
  /// Fetch info about current subscription
  const {data} = useQuery(
    gql`
      query {
        account {
          addons
          subscriptions {
            nextBillingAt
          }
        }
      }
    `
  )

  /// Do not show the modal if the user has no subscriptions
  if (!data) {
    return (<Container>
      <Loader active />
    </Container>)
  }

  /// If user has vpn one subscription
  const vpnOne = data?.account?.addons?.find((value: string) => ['vpn_one', 'vpn-one'].includes(value))

  /// When subscription is cancelled
  const nextBillingAt = data?.account?.subscriptions[0]?.nextBillingAt
  const dateFormatter = Intl.DateTimeFormat('en-US', ({dateStyle: 'long'} as any))

  /**
       * Renders a info fox about a specific protection
       *
       * @param title
       * @param description
       * @constructor
       */
  const ProtectionDisabled = (title: string, description: string) => (
    <SimpleBox display={'flex'} flexDirection={'row'} alignItems={'center'} gap={15}>
      <SimpleBox boxShadow={'0px 14px 34px rgba(174, 119, 1, 0.2)'}
        backgroundColor={'#FFA438'}
        borderRadius={18}
        width={36}
        height={36}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}>
        <img src={shieldWhite} alt="Shield" width={16} height={19} />
      </SimpleBox>
      <SimpleBox display={'flex'} flexDirection={'column'} alignItems={'start'}>
        <SimpleBox as={'p'} color={'#000000'} fontWeight={400} fontSize={14}>{title}</SimpleBox>
        <SimpleBox as={'p'} color={'#DE8010'} fontWeight={400} fontSize={12}>{description}</SimpleBox>
      </SimpleBox>
    </SimpleBox>
  )

  /**
       * Renders a simple box with information about the protection
       *
       * @param background
       * @param title
       * @param description
       */
  function getSimpleBox(background: NodeRequire, title: string, description: string) {
    return <SimpleBox background={`url(${background}) no-repeat`}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      height={376}
      maxWidth={323}
      backgroundColor={'#E8EAF0'}
      borderRadius={'10px'}
      padding={'23px'}
      flex={1}
      justifyContent={'space-between'}
      flexDirection={'column'}>
      <SimpleBox as={'p'} color={'#000000'} fontWeight={700} fontSize={20} textAlign={'left'}>{title}</SimpleBox>
      {ProtectionDisabled('Protection Disabled', description)}
    </SimpleBox>
  }

  /// Generate a cancel description based on the next billing date and vpn plus status
  const dateString = dateFormatter.format(new Date(nextBillingAt))
  const cancelDescription = `If you cancel, you will be unprotected starting on ${dateString}.`

  return (<>
    <Modal.Header>
      <SimpleBox as={'p'} color={'#000000'} fontWeight={600} fontSize={40} textAlign={'center'}>Are you sure?</SimpleBox>
    </Modal.Header>
    <Modal.Content>
      <SimpleBox as={'p'} color={'##787E8D'} fontWeight={400} fontSize={16} textAlign={'center'}>{cancelDescription}</SimpleBox>
      <SimpleBox display={'flex'} flexDirection="row" justifyContent="space-evenly" gap={25} paddingTop={35} paddingBottom={35}>
        {getSimpleBox(vpnImage, 'VPN', 'Your data will not be encrypted.')}
        {vpnOne && getSimpleBox(antivirusImage, 'Antivirus', 'Your system will be vulnerable.')}
        {vpnOne && getSimpleBox(idMonitoringImage, 'ID Monitoring', 'Your identity will not be monitored.')}
      </SimpleBox>
      <SimpleBox paddingTop="20px" display="flex" justifyContent="center">
        <Button
          onClick={() => { onNext() }}
          content="Yes, cancel my subscription"
          size="big"
          secondary
        />
      </SimpleBox>
    </Modal.Content>
  </>
  )
}

export {IntroStep}
