import React, {VFC} from 'react'
import {Card} from 'semantic-ui-react'

type Props = {
  onCancelClicked: () => void
}

/**
 * Renders a component with information about how to cancel the subscription
*/
const CancelSubscription: VFC<Props> = ({onCancelClicked}) => <Card fluid>
  <Card.Content>
    <div className="card__title display-flex">
      <div className="mr-auto">Cancel subscription</div>
    </div>
    <p className="text text--subdued text--small">
                        If you are not fully satisfied with our service, you are able to cancel {' '}
      <a onClick={onCancelClicked} className="link" target="_blank" rel="noreferrer">
                            here
      </a>
    </p>
  </Card.Content>
</Card>

export {CancelSubscription}
