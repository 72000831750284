import React, { FC, useEffect } from 'react'
import { Header, Loader } from 'semantic-ui-react'
import { gql, useQuery } from '@apollo/client'
import { Dashboard } from '../components/IdentitySecurity/Dashboard'
import { Onboarding } from '../components/IdentitySecurity/Onboarding'
import { SignUp } from '../components/IdentitySecurity/SignUp'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleLayout } from '../components/SimpleLayout'
import { useNavigation } from '../hooks/useNavigation'

type FetchData = { account: { identisafeUserId?: string, services: string[] } }

const FETCH_QUERY = gql`
  { account { identisafeUserId services } }
`

const IdentitySecurity: FC = () => {
  const { setNav, resetNav } = useNavigation()
  const { data, loading, refetch } = useQuery<FetchData>(FETCH_QUERY, { fetchPolicy: 'no-cache' })

  const isLoading = (data === null || data === undefined) && loading
  const hasAccess = data?.account?.services.includes('IDENTITY_PROTECTION')
  const hasAccount = data?.account?.identisafeUserId != null

  useEffect(() => {
    setNav('hidden')
    return resetNav
  }, [])

  return (
    <SimpleLayout>
      <SimpleBox className="identity-security">
        <Header as="h4">Identity Security</Header>
        {isLoading && (
          <Loader active />
        ) || !hasAccess && (
          <SignUp />
        ) || !hasAccount && (
          <Onboarding onSetupComplete={refetch} />
        ) || (
          <Dashboard />
        )}
      </SimpleBox>
    </SimpleLayout>)
}

IdentitySecurity.displayName = 'IdentitySecurity'

export default IdentitySecurity
