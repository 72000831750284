/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react'
import { Observer } from 'mobx-react'
import { Image, Menu } from 'semantic-ui-react'
import Session from '~shared/storages/session'
import { SideBarDisplay } from '../../contexts/Navigation'
import { SimpleBox } from '../SimpleBox'
import { SimpleCard } from '../SimpleCard'
// @ts-ignore
import styles from './Sidebar.module.scss'
import Cookies from 'universal-cookie'
/* import { FRESHDESK_INITIATE_AUTHORIZE_URL } from '../../pages/freshdesk' */

const Logo = require('~assets/images/logo-black.svg')
const MySubscriptionIcon = require('~assets/images/icons/my-subscription.svg')
const SettingsIcon = require('~assets/images/icons/settings.svg')
const AppsIcon = require('~assets/images/icons/apps.svg')
const SupportIcon = require('~assets/images/icons/support.svg')
const UserIcon = require('~assets/images/icons/user.svg')
const IdentitySecurityIcon = require('~assets/images/icons/identity-security.svg')
const PersonalDataRemovalIcon = require('~assets/images/icons/data-removal.svg')

const cookies = new Cookies()

type Props = { type: SideBarDisplay, className?: string, setVisibleFalse: () => void }
const Sidebar: FC<Props> = ({ type, className, setVisibleFalse }) => {
  const logOut = () => {
    cookies.remove('accessToken')
    Session.clear('/')
    setVisibleFalse()
  }

  return (
    <>
      {type === 'full' && (
        <div className={`${styles.Main} ${className}`}>
          <Menu size="huge" fluid text icon vertical>
            <Menu.Item header>
              <Image src={Logo} height={33} id="logo" />
            </Menu.Item>
            <SimpleBox height={18} />
            <Menu.Item as="a" href="/dashboard" onClick={setVisibleFalse}>
              <SimpleBox display="flex" alignItems="center">
                <Image src={MySubscriptionIcon} className="mr-2" />
                My Subscription
              </SimpleBox>
            </Menu.Item>
            <Menu.Item as="a" href="/identity-security" onClick={setVisibleFalse}>
              <div className="display-flex align-center">
                <Image src={IdentitySecurityIcon} className="mr-2" />
                Identity Security
              </div>
            </Menu.Item>
            {<Menu.Item as="a" href="/personal-data-removal" onClick={setVisibleFalse}>
              <div className="display-flex align-center">
                <Image src={PersonalDataRemovalIcon} className="mr-2" />
                Data Removal
              </div>
            </Menu.Item>}
            <Menu.Item as="a" href="/settings" onClick={setVisibleFalse}>
              <div className="display-flex align-center">
                <Image src={SettingsIcon} className="mr-2" />
                Settings
              </div>
            </Menu.Item>
            <Menu.Item as="a" href="/apps" onClick={setVisibleFalse}>
              <div className="display-flex align-center">
                <Image src={AppsIcon} className="mr-2" />
                Apps
              </div>
            </Menu.Item>
            <Menu.Item as="a" href="/freshdesk" target="_blank" rel="noreferrer" onClick={setVisibleFalse}>
              <div className="display-flex align-center">
                <Image src={SupportIcon} className="mr-2" />
                Support
              </div>
            </Menu.Item>
          </Menu>
          <Observer>
            {() => (
              <SimpleBox className={styles.User}>
                <SimpleCard outline>
                  <Image src={UserIcon} />
                  <div className="mt-1 header">{Session.profile?.fullName}</div>
                  <div className="text text--subdued text--small text--ellipsis">
                    <a className="link text--subdued" href="/settings">{Session.profile?.email}</a>
                  </div>
                  <div className="mt-2">
                    <a href="" onClick={logOut} className="link">
                      Sign Out
                    </a>
                  </div>
                </SimpleCard>
              </SimpleBox>
            )}
          </Observer>
        </div>
      )}
    </>
  )
}

export { Sidebar }
