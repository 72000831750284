import { gql, useMutation } from '@apollo/client'
import React, { FC, useState } from 'react'
import { Button, FormField, Image, Input, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../../components/SimpleBox'
import { emailRegex } from '../../../const'

const CheckIcon = require('~assets/images/icons/tick-green.svg')

type Props = { onContinue: (data?: any) => void }
const Email: FC<Props> = ({ onContinue }) => {
  const [email, setEmail] = useState('')

  const [resetPassword, { loading }] = useMutation(
    gql`
      mutation($email: String!) {
        forgotPassword(email: $email)
      }
    `
  )

  const handleConfirm = async () => {
    await resetPassword({ variables: { email } })
    onContinue!({ email })
  }

  const isEmailValid = emailRegex.test(email!)

  return (
    <>
      <Modal.Header className="white">
        <h6 className="title">Reset password</h6>
      </Modal.Header>
      <Modal.Content className="white">
        <SimpleBox maxWidth={360} mx="auto">
          <SimpleBox mb={3}>
            <p>We will send a request to your mailbox so you can set a new password.</p>
          </SimpleBox>
          <FormField>
            <label htmlFor="email">Email address</label>
            <Input
              id="email"
              value={email}
              type="email"
              placeholder="name@example.com"
              size="large"
              onChange={e => setEmail(e.target.value)}
              fluid
              icon={
                isEmailValid && (
                  <Image
                    src={CheckIcon}
                    height={22}
                    style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }}
                  />
                )
              }
            />
          </FormField>
        </SimpleBox>
        <SimpleBox mt={7} mb={4} maxWidth={300} mx="auto">
          <Button size="huge" fluid primary onClick={handleConfirm} loading={loading} disabled={!isEmailValid}>
            Confirm
          </Button>
        </SimpleBox>
      </Modal.Content>
    </>
  )
}

export { Email }
