import React, { FC, useMemo, useState } from 'react'
import classNames from 'classnames'
import Badge from '../../Badge/Badge'
import {DataRemovalItem, DataRemovalStatus} from './List'
const arrowDown = require('../../../assets/images/icons/arrow-head-down.svg')
const phone = require('../../../assets/images/data-removal/phone.svg')
const email = require('../../../assets/images/data-removal/email.svg')
const styles = require('./List.module.scss')

interface Props {
  item: DataRemovalItem
  active: boolean
  isMobile?: boolean
  status: DataRemovalStatus
}

/**
 * List item component for DataRemoval Dashboard
 */
const ListItem: FC<Props> = ({ item, active, isMobile, status }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const borderClassName = expanded ? styles.expanded : styles.collapsed
  const colorClassName = active ? styles.grey : styles.white

  const transformStatus = (status: DataRemovalStatus) => {
    switch (status) {
      case DataRemovalStatus.EXPOSED:
        return 'Exposed'
      case DataRemovalStatus.IN_PROGRESS:
        return 'In Progress'
      case DataRemovalStatus.REMOVED:
        return 'Removed'
      case DataRemovalStatus.PROTECTED:
        return 'Protected'
    }
  }

  /**
   * Mobile item displayed on small screens (<= 768px)
   */
  const mobileItem = useMemo(() => (<div className={classNames([colorClassName, styles.mobile])}>
    <div onClick={() => setExpanded(!expanded)} className={styles.menu}>
      <span>
        <img src={`data:image/jpeg;base64,${item.site.logo}`} className={styles.logo} />
        <b>{item.site.name}</b>
      </span>
      <Badge
        height={30}
        width={30}
        backgroundColor={active ? '#FFF' : '#F5F6F9'}
        style={{ cursor: 'pointer' }}
        text={<img
          src={arrowDown}
          alt="Expand area"
          style={{
            transform: `rotate(${expanded ? 180 : 0}deg)`,
          }}
        />}
      />
    </div>
    {expanded && <div className={styles.info}>
      <table>
        <tr>
          <td style={{ width: 75 }}><b>Name</b></td>
          <td>{item.name}</td>
        </tr>
        <tr>
          <td><b>Location</b></td>
          <td>{item.location}</td>
        </tr>
        <tr>
          <td><b>Relatives</b></td>
          <td>{item.relatives.map((relative, index) => <React.Fragment key={`relative-${index}`}>{relative}<br /></React.Fragment>)}</td>
        </tr>
        <tr>
          <td><b>Phone</b></td>
          <td>{item.phone}</td>
        </tr>
        <tr>
          <td><b>Status</b></td>
          <td>{transformStatus(item.status)}</td>
        </tr>
      </table>
      <span style={{ fontWeight: 'bold' }}>This site also exposes your:</span>
      {item.info.exposed.phone && (<span><img src={phone} /> Phone numbers</span>)}
      {item.info.exposed.email && (<span><img src={email} /> Email adresses</span>)}
      <span style={{ fontWeight: 'bold' }}>This info putts you at risk of:</span>
      <ul>
        <li>Identity theft</li>
        <li>Robocalls</li>
        <li>Email spam</li>
        <li>Hackers</li>
        <li>Stalkers and creeps</li>
      </ul>
    </div>}
  </div>), [active, expanded, item])

  /**
   * Desktop item displayed on large screens (> 768px)
   */
  const desktopItem = useMemo(() => (<>
    <tr className={classNames([borderClassName, colorClassName])}>
      <td className={styles.textLeft}>
        <img src={`data:image/jpeg;base64,${item.site.logo}`} className={styles.logo} />
        <span style={{ fontWeight: 'bold' }}>{item.site.name}</span>
      </td>
      <td className={styles.textLeft}>
        {item.name}
        <br />
          Age: {item.age ? 'available' : 'unavailable'}
      </td>
      <td className={styles.textLeft}>{item.location}</td>
      <td className={styles.textLeft}>
        {item.relatives.map((relative, index) => <React.Fragment key={`relative-${index}`}>{relative}<br /></React.Fragment>)}
      </td>
      <td className={styles.textLeft}>{item.phone}</td>
      <td className={styles.textCenter}>
        <Badge
          text={transformStatus(item.status)}
          color="#FB0023"
          backgroundColor="rgba(251, 0, 35, 0.15)"
          width={70}
          height={36}
        />
      </td>
      <td className={styles.textCenter} onClick={() => setExpanded(!expanded)}>
        <Badge
          height={30}
          width={30}
          backgroundColor={active ? '#FFF' : '#F5F6F9'}
          text={<img
            src={arrowDown}
            alt="Expand area"
            style={{
              transform: `rotate(${expanded ? 180 : 0}deg)`
            }}
          />}
        />
      </td>
    </tr>
    {expanded && <>
      <tr className={classNames([styles.seperator, colorClassName])}>
        <td colSpan={7}></td>
      </tr>
      <tr className={classNames([styles.expandedView, colorClassName])}>
        <td>
        </td>
        <td colSpan={2} className={styles.info}>
          <span><b>This site also exposes your:</b></span>
          <span>
            <img src={phone} /> Phone numbers</span>
          <span>
            <img src={email} /> Email adresses
          </span>
          <span><b>This info putts you at risk of:</b></span>
          <ul>
            <li>Identity theft</li>
            <li>Robocalls</li>
            <li>Email spam</li>
            <li>Hackers</li>
            <li>Stalkers and creeps</li>
          </ul>
        </td>
        <td colSpan={4} style={{ verticalAlign: 'top', paddingBottom: 36 }}>
          {status === DataRemovalStatus.IN_PROGRESS && (<><span><b>When will this be removed?</b></span><br/><span>It can take {item.site.name} up to 45 days to honor our request.
            We’ll automatically check back every few days and update the status when it’s been removed.</span></>)}
        </td>
      </tr>
    </>}
  </>), [active, expanded, item])

  return isMobile ? mobileItem : desktopItem
}

export default ListItem
