import React, { FC } from 'react'
import { Form } from 'semantic-ui-react'

type Props = {
    state: string
    onChange: (string: string) => void
}

const states = require('../../../../assets/states.json')
const stateOptions = Object.keys(states).map(key => ({key: key, value: key, text: states[key]}))

const StateInput: FC<Props> = ({state, onChange}) => (
  <Form.Field>
    <label>State</label>
    <Form.Dropdown fluid search selection required value={state} onChange={(event, data) => { onChange(data.key) }} options={stateOptions} />
  </Form.Field>
)

export { StateInput }
