// import { gql, useQuery } from '@apollo/client'
import React, {FC, useEffect /*, useState*/ } from 'react'
// import { Plans } from '../components/Plans/Plans'
// import { SimpleBox } from '../components/SimpleBox'
// import { planSellingPointsDescription } from '../const'
import Router from '../shared/router'
// import { Plan, PlanGroup, PlanGroups } from '../type'
// import {Coupon, FetchCoupon} from '../utils/coupon'
// import {BillingPeriodSelector} from '../components/BillingPeriodSelector/BillingPeriodSelector'

const Index: FC & { authorize: string | boolean; auth: string } = () => {
  // const routeIndex = parseInt((Router.params as { index: string }).index || '2')
  const couponCode = Router.qs.coupon as string

  /**
   * Always redirect to new marketing site
   */
  useEffect(() => {
    const host = window.location.hostname
    const couponPath = couponCode ? `?coupon=${couponCode}` : ''
    if (host.includes('dev')) {
      window.location.href = `https://virtualshield.dev/pricing${couponPath}`
    } else if (host.includes('me')) {
      window.location.href = `http://localhost:3000/pricing${couponPath}`
    } else {
      window.location.href = `https://virtualshield.com/pricing${couponPath}`
    }
  }, [])

  return <></>

  /*
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined)

  const { data } = useQuery<{ plans: Plan[] }>(gql`
    {
      plans {
        id
        name
        externalName
        description
        price
        period
        periodUnit
        pricingModel
        currencyCode
        trialPeriod
        trialPeriodUnit
        billingCycles
        freeQuantity
        itemFamily {
          name
        }
        item {
          metadata
        }
        __typename
      }
    }
  `)

  /// Fetches a coupon and tests it against the first plan in the list
  useEffect(() => {
    if (couponCode && couponCode.length > 0 && data && data.plans.length > 0) {
      FetchCoupon(data.plans[0].id, couponCode).then(c => setCoupon(c))
    }
  }, [couponCode, data])

  const [groupIndex, setGroupIndex] = useState<number>(routeIndex)
  const records = data?.plans
    .filter((value: Plan) => value.itemFamily.name !== 'Apple App Store')
    .filter((value: Plan) => !value.name.includes('VPN Plus'))

  const initialPlanGroups: PlanGroups = [
    { periodUnit: 'MONTH', period: 1, months: 1, title: 'Monthly', items: [], saving: 0 },
    { periodUnit: 'YEAR', period: 1, months: 12, title: 'Annual', items: [], saving: 46 },
    { periodUnit: 'YEAR', period: 2, months: 24, title: '2 Year', items: [], saving: 55 },
  ]

  const groups: PlanGroups | undefined = records?.reduce((acc, val) => {
    const index = acc.findIndex((g: PlanGroup) => g.months === (val.period * (val.periodUnit === 'YEAR' ? 12 : 1)))
    const plan = acc[index]

    if (plan && val.externalName?.toLowerCase().includes('vpn')) {
      acc[index].items.push(val)
    }

    return acc
  }, initialPlanGroups)

  const group = groups ? groups[groupIndex] : undefined

  const renderMenu = (isvertical: boolean) => <BillingPeriodSelector isMobile={isvertical} onSelectedIndex={setGroupIndex} selectedIndex={groupIndex} groups={groups} />

  return (
    <SimpleBox mt={18} pb={12}>
      <SimpleBox maxWidth={1240} mx="auto">
        <Plans
          side={
            <SimpleBox pt={3}>
              <h1><span className="text red-text">Explore</span><br />Plans &amp; Pricing</h1>
              <div className="text" style={{ fontSize: '19px', paddingTop: '15px' }}>
                Get Started With Your <b className="text red-text">30 Day Free Trial!</b>
              </div>
            </SimpleBox>
          }
          descriptions={planSellingPointsDescription}
          coupon={coupon}
          onRemoveCoupon={() => setCoupon(undefined)}
        >
          <div>
            {renderMenu(true)}
            {renderMenu(false)}
            <SimpleBox marginTop={12}>
              {group && <Plans.Pair plans={group.items} coupon={coupon} />}
            </SimpleBox>
            <Plans.Trial />
          </div>
        </Plans>
      </SimpleBox>
    </SimpleBox>
  )*/
}

Index.authorize = false
Index.auth = '/apps'

export default Index
