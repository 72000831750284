import React, {FC, useEffect, useState} from 'react'
import {Button, Image} from 'semantic-ui-react'
import {FormHeader} from '../../Form/Header/FormHeader'
import {FormContainer} from '../../Form/Container/FormContainer'
import {useDataRemoval} from '../context'
import Notifications from '../../../shared/notifications'
import {PhoneNumberField} from '../../Form/Fields/PhoneNumber/PhoneNumberField'

const styles = require('./PersonalInformation.module.scss')

const PhoneImage = require('../../../assets/images/data-removal-phone-numbers.svg')
const FormRemove = require('../../../assets/images/form-remove.svg')

type Props = {
  onStepComplete: () => void
}

const PhoneNumbers:FC<Props> = ({ onStepComplete }) => {
  const dataRemoval = useDataRemoval()
  const [phoneNumbers, setPhoneNumbers] = React.useState<string[]>([])
  const altPhoneNumbers = phoneNumbers.slice(1)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (dataRemoval.primaryProfile?.phoneNumbers) {
      setPhoneNumbers(dataRemoval.primaryProfile.phoneNumbers)
    }
  }, [dataRemoval.primaryProfile])

  const handlePhoneNumberChange = (index: number, email: string) => {
    const newPhoneNumbers = [...phoneNumbers]
    newPhoneNumbers[index] = email
    setPhoneNumbers(newPhoneNumbers)
  }

  const addAltPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ''])
  }

  const removeAltPhoneNumber = (index: number) => {
    const newPhoneNumbers = [...phoneNumbers]
    newPhoneNumbers.splice(index, 1)
    setPhoneNumbers(newPhoneNumbers)
  }

  const saveAndContinue = async () => {
    setLoading(true)
    try {
      await dataRemoval.savePhoneNumbers(phoneNumbers)
      onStepComplete()
    } catch (e) {
      setLoading(false)
      Notifications.error(e.message || 'Something went wrong. Please try again later.')
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormHeader
          title={'Phone Numbers'}
          description={'Please enter any phone numbers (cell, home, office) you currently\nhave or have had in the past, even if you don’t use them anymore'}
          image={PhoneImage} />
        <br />
        <FormContainer>
          <PhoneNumberField label={'Primary Number'} value={phoneNumbers[0] || ''} onChange={value => {
            handlePhoneNumberChange(0, value)
          }} />
        </FormContainer>
        <br />
        {altPhoneNumbers.map((number, index) => (
          <div key={index} className={styles.altNameForm}>
            <FormContainer>
              <PhoneNumberField label={'Additional Number'} value={number} onChange={value => {
                handlePhoneNumberChange(index+1, value)
              }} />
            </FormContainer>
            <div className={styles.removeAltName} onClick={() => { removeAltPhoneNumber(index+1) }}>
              <Image src={FormRemove} />
            </div>
          </div>
        ))}
        <div className={styles.addName} onClick={addAltPhoneNumber}>+ Add additional phone number</div>
      </div>
      <Button primary={true} size="large" loading={loading} onClick={saveAndContinue}>
        Save and Continue
      </Button>
      <br />
    </div>
  )
}

export { PhoneNumbers }
