import classNames from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Badge from '../../Badge/Badge'
import ListItem from './ListItem'
const styles = require('./List.module.scss')
const ArrowDown = require('../../../assets/images/icons/arrow-head-down.svg')
const ExposedInfo = require('../../../assets/images/data-removal/exposed.svg')
const InProgress = require('../../../assets/images/data-removal/in-progress.svg')
const Removed = require('../../../assets/images/data-removal/removed.svg')
// const Protected = require('../../../assets/images/data-removal/protected.svg')

export enum DataRemovalStatus {
    EXPOSED = 'exposed',
    IN_PROGRESS = 'in_progress',
    REMOVED = 'removed',
    PROTECTED = 'protected'
}

export interface DataRemovalItem {
    status: DataRemovalStatus
    age: string
    location: string
    name: string
    phone: string
    relatives: string[]
    site: {
        name: string
        url: string
        logo: string
    }
    info: {
        exposed: {
          phone: boolean
          email: boolean
        }
        risks: string[]
        text: string
    }
}

interface Props {
    items: DataRemovalItem[]
    enrolled: boolean
}

/**
 * DataRemoval Dashboard list component
 */
const List: FC<Props> = props => {
  const [tab, setTab] = useState<DataRemovalStatus>(DataRemovalStatus.EXPOSED)
  const [items, setItems] = useState<DataRemovalItem[]>([])
  const [isMobile, setMobile] = useState<boolean>(false)

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  const detectMobile = useCallback(() => {
    setMobile(window.innerWidth <= 768)
  }, [isMobile])

  useEffect(() => {
    detectMobile()

    window.addEventListener('resize', detectMobile)
    return () => {
      window.removeEventListener('resize', detectMobile)
    }
  }, [])

  /**
     * Determine if tab is active
     */
  const isTabActive = useCallback((item: DataRemovalStatus): boolean => item === tab, [tab])

  /**
     * Return active CSS class if tab is active
     */
  const getTabActiveStyle = useCallback((item: DataRemovalStatus) => isTabActive(item) ? styles.active : styles.inactive, [tab])

  /**
     * Filtered results
     */
  const filtered = useMemo((): DataRemovalItem[] => items.filter(item => item.status === tab), [items, tab])

  /**
     * Mobile dropdown state
     */
  const [expanded, setExpanded] = useState<boolean>(false)

  /**
     * Tabs available
     */
  const tabs = useMemo(() => [
    {
      title: 'Exposed Info',
      image: ExposedInfo,
      status: DataRemovalStatus.EXPOSED,
      color: '#FB0023',
    },
    {
      title: 'In Progress',
      image: InProgress,
      status: DataRemovalStatus.IN_PROGRESS,
      color: '#DE8010',
    },
    {
      title: 'Removed',
      image: Removed,
      status: DataRemovalStatus.REMOVED,
      //color: '#698AFF',
      color: '#1AB233',
    }/*,
    {
      title: 'Protected',
      image: Protected,
      status: DataRemovalStatus.PROTECTED,
      color: '#1AB233',
    },*/
  ], [])

  /**
     * Title of the current tab
     */
  const title = useMemo(() => tabs.find(item => item.status === tab)?.title || '', [tab])

  /**
     * Image of the current tab
     */
  const image = useMemo(() => tabs.find(item => item.status === tab)?.image || '', [tab])

  /**
     * Color of the current tab
     */
  const color = useMemo(() => tabs.find(item => item.status === tab)?.color || '', [tab])

  /**
     * Count of items in the current tab
     */
  const countItems = useCallback((status: DataRemovalStatus) => items.filter(item => item.status === status).length, [items, tab])

  /**
     * Tabs displayed on mobile (< 768px)
     */
  const mobileTabs = useMemo(() => {
    const selectTab = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, tab: DataRemovalStatus) => {
      ev.stopPropagation()

      setTimeout(() => {
        setTab(tab)
        setExpanded(false)
      }, 150)
    }

    return <div className={styles.tabs} onClick={() => setExpanded(!expanded)}>
      <div className={styles.tab}>
        <img src={image} />
        <span>{title}</span>
        <Badge text={countItems(tab)} backgroundColor={color} />
      </div>
      <Badge
        height={30}
        width={30}
        backgroundColor={'#FFF'}
        text={<img
          src={ArrowDown}
          alt="Expand area"
          style={{
            transform: `rotate(${expanded ? 180 : 0}deg)`
          }}
        />}
      />
      <div className={styles.dropdown} style={{ display: expanded ? 'flex' : 'none' }}>
        {tabs.map(tab => <div
          key={`tab-${tab.status}`}
          className={styles.tab}
          onClick={event => selectTab(event, tab.status)}
        >
          <div className={styles.text}>
            <img src={tab.image} />
            <span>{tab.title}</span>
            <Badge text={countItems(tab.status)} backgroundColor={tab.color} />
          </div>
        </div>)}
      </div>
    </div>
  }, [tab, expanded, countItems])

  /**
     * Tabs displayed on desktop (> 768px)
     */
  const desktopTabs = useMemo(() => (<div className={styles.tabs}>
    {tabs.map(tab => <div
      key={`tab-${tab.status}`}
      onClick={() => setTab(tab.status)}
      className={
        classNames([
          styles.tab,
          getTabActiveStyle(tab.status)
        ])
      }
    >
      <img src={tab.image} />
      <span>{tab.title}</span>
      <Badge text={countItems(tab.status)} activeColor={tab.color} active={isTabActive(tab.status)} />
    </div>)}
  </div>), [tab, expanded, countItems])

  const listText = useMemo(() => {
    let intro = ''
    switch (tab) {
      case DataRemovalStatus.EXPOSED:
        intro = `We found ${filtered.length} pieces of exposed info.`
        break
      case DataRemovalStatus.IN_PROGRESS:
        intro = `We’re in the process of removing ${filtered.length} pieces of exposed info.`
        break
      case DataRemovalStatus.REMOVED:
        intro = `We removed ${filtered.length} pieces of exposed info.`
        break
      case DataRemovalStatus.PROTECTED:
        intro = `We protected ${filtered.length} pieces of exposed info.`
        break
    }

    return (<><b>{intro}</b> Every removal significantly reduces your risk of identity theft, hacks, spam, robo calls, and other privacy threats that could impact your life.</>)
  }, [tab, filtered])

  /**
     * List displayed on small screens (< 768px)
     */
  const mobileList = useMemo(() => (<>
    <span style={{ marginTop: 10, marginBottom: 20 }}>
      {listText}
    </span>
    {filtered.map((item, index) => <ListItem
      key={index}
      item={item}
      active={!(index % 2)}
      status={tab}
      isMobile
    />)}
  </>), [filtered])

  /**
     * List displayed on large screens
     */
  const desktopList = useMemo(() => (<div>
    <span style={{ marginTop: 10, marginBottom: 20 }}>
      {listText}
    </span>
    <table className={styles.table} cellSpacing={0} cellPadding={0}>
      <tr>
        <th className={styles.textLeft}>Site</th>
        <th className={styles.textLeft}>Name</th>
        <th className={styles.textLeft}>Location</th>
        <th className={styles.textLeft}>Relatives</th>
        <th className={styles.textLeft}>Phone</th>
        <th className={styles.textCenter} style={{ width: 97 }}>Status</th>
        <th className={styles.textCenter} style={{ width: 104 }}>More info</th>
      </tr>
      {filtered.map((item, index) => <ListItem
        key={index}
        item={item}
        status={tab}
        active={!(index % 2)}
      />)}
    </table>
  </div>), [filtered])

  /**
   * Returns the right text for when the list is empty
   */
  const emptyListText = () => {
    if (props.enrolled) {
      if (tab === DataRemovalStatus.EXPOSED) {
        return 'No exposed data found'
      } else {
        return 'No data found'
      }
    } else {
      return 'No data. To see your exposed info, please turn on your data removal protection.'
    }
  }

  /**
     * Renders an empty list with a message
     */
  const emptyList = useMemo(() => <div className={styles.noResults}>{emptyListText()}</div>, [props.enrolled, items, tab])

  /**
     * Renders tabs according to screen size
     */
  const renderedTabs = useMemo(() => isMobile ? mobileTabs : desktopTabs, [isMobile, expanded, mobileTabs, desktopTabs])

  /**
     * Renders a list according to results and screen size
     */
  const renderedList = useMemo(() => {
    if (filtered.length === 0) { return emptyList }
    return isMobile ? mobileList : desktopList
  }, [filtered, isMobile])

  return (<div className={styles.dashboardList}>
    {renderedTabs}

    <div className={styles.container}>
      {renderedList}
    </div>
  </div>)
}

export default List
