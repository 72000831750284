import React, { FC, useEffect } from 'react'
import { loadExternals } from '../../config'

const Provider: FC = ({ children }) => {
  useEffect(() => {
    loadExternals()
  }, [])
  return <>{children}</>
}
export { Provider }
