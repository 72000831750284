import React, { FC } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'
import { ProtectionStatus, ProtectionStatusIcon } from './'

interface Props {
  status: ProtectionStatus;
}

const RenderProtectionStatus: FC<Props> = ({ status }) => {
  const title =
    status === 'issue-detected' ? 'Issue Detected' : 'Protection Active'
  const message =
    status === 'issue-detected'
      ? 'Solve the issue to get full protection'
      : 'Protection Active'

  return (
    <SimpleBox display="flex" flexDirection="row" alignItems="center" gap={10}>
      <ProtectionStatusIcon status={status} />
      <SimpleBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <SimpleText size="title2">{title}</SimpleText>
        <SimpleText className="protection-area-status-text">
          {message}
        </SimpleText>
      </SimpleBox>
    </SimpleBox>
  )
}

export { RenderProtectionStatus }
