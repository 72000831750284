import React, {VFC} from 'react'
import {gql, useQuery} from '@apollo/client'
import {SimpleBox} from '../../SimpleBox'
import {SimpleText} from '../../SimpleText'

const RESULTS_QUERY = gql`query dark_web_results {
  darkWebResults
}`

export const DarkWebScanResults: VFC = () => {
  const {data, loading} = useQuery<any>(RESULTS_QUERY)

  if (loading) {
    return <SimpleText>Loading...</SimpleText>
  }

  return <SimpleBox textAlign={'center'} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="10px">
    <SimpleText>You we exposed on the following sites</SimpleText>
    <SimpleText>{data?.darkWebResults.baData.map((site: any) => site.Name).join(', ')}</SimpleText>
  </SimpleBox>
}
