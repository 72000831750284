import React, { FC, useState } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import { FormContent, FormHeader } from '.'
import { SimpleForm } from '../../SimpleForm'
import { StateInput } from './inputs/StateInput'

const HomeAddressForm: FC = () => {
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')

  return (<div className="identity-security-form">
    <FormHeader
      image={require('../../../assets/images/identity-security/home.svg')}
      title="Home address"
      text="Please fill in your home address"
    />
    <FormContent>
      <Form.Field>
        <label>Address </label>
        <SimpleForm.Input
          fluid
          value={address1}
          required
          showValidation={address1.length > 0}
          onChange={({value}) => setAddress1(value)}
          loading={false}
        />
      </Form.Field>
      <Form.Field>
        <label>Address 2</label>
        <SimpleForm.Input
          fluid
          value={address2}
          onChange={({value}) => setAddress2(value)}
          loading={false}
        />
      </Form.Field>
      <Form.Field>
        <label>City</label>
        <SimpleForm.Input
          fluid
          value={city}
          required
          showValidation={city.length > 0}
          onChange={({value}) => setCity(value)}
          loading={false}
        />
      </Form.Field>
      <Grid columns={2}>
        <Grid.Column>
          <StateInput state={state} onChange={setState} />
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <label>ZIP Code</label>
            <SimpleForm.Input
              fluid
              value={zip}
              required
              showValidation={zip.length > 0}
              onChange={({value}) => setZip(value)}
              loading={false}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    </FormContent>
  </div>)
}

HomeAddressForm.displayName = 'HomeAddressForm'

export { HomeAddressForm }
