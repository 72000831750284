import React, {useState, VFC} from 'react'
import {Button, Container, Loader, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../components/SimpleBox'
import {gql, useQuery} from '@apollo/client'
import Mutation from '../shared/mutation'
import Notifications from '../shared/notifications'
import {toMoney} from '../shared/format'
import {toPlanPeriod} from '../utils'

const chekmarkIcon = require('../assets/images/icon-charkmark-roundbg.png')

type ReactivateSubscriptionData = {
  reactivateSubscription: boolean
}

type SubscriptionViewModel = {
  id: string
  finalPrice: number
  itemPrices: { name: string, period: number, periodUnit: 'MONTH' | 'YEAR' }[]
}

/// Props for the component
type Props = {
  onReactivated: () => void
  onFinished: () => void
}

const ReactivateSubscriptionModal: VFC<Props> = ({ onReactivated, onFinished }) => {
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  /// Fetch info about current subscription
  const {data} = useQuery(
    gql`
      query {
        account {
          subscriptions {
            status
            nextBillingAt
            period
            finalPrice
            itemPrices {
              name
              period
              periodUnit
            }
          }
        }
      }
    `
  )

  /// Fetches information about payment methods
  const { data: paymentData } = useQuery(
    gql`
      query {
        account {
          customer {
            primaryPaymentSource {
              id
            }
          }
        }
      }
    `
  )

  /// The actual reactivate mutattion
  const reactivateMutation = new Mutation<ReactivateSubscriptionData>(`
   mutation reactivateSubscription { reactivateSubscription }`)

  /// Do not show the modal if the user has no subscriptions
  if (!data || !paymentData) {
    return (<Container>
      <Loader active />
    </Container>)
  }

  const handleReactivate = async () => {
    setLoading(true)
    reactivateMutation.exec().then(() => {
      if (reactivateMutation.data?.reactivateSubscription) {
        Notifications.success('Subscription reactivated')
        onReactivated()
        setCompleted(true)
      } else {
        Notifications.error(reactivateMutation.error() || 'Unable to reactivate subscription', {timing: 8000})
      }
      setLoading(false)
    })
  }

  /// Find payment methods
  const primaryMethodAvailable = paymentData?.account?.customer?.primaryPaymentSource?.id !== null

  /// When subscription is cancelled
  const dateFormatter = Intl.DateTimeFormat('en-US', ({dateStyle: 'long'} as any))
  const scheduledForCancellation = ['IN_TRIAL', 'NON_RENEWING'].includes(data?.account?.subscriptions[0]?.status)
    && data?.account?.subscriptions[0]?.period.finish !== null

  const reactivateDescription = scheduledForCancellation ?
    `This subscription is scheduled to be canceled on ${dateFormatter.format(new Date(data?.account?.subscriptions[0]?.period.finish))}.` :
    'Your subscription is cancelled and can be reactivated as of today.'

  const subscriptionMarkup = (subscription: SubscriptionViewModel) => <SimpleBox
    key={subscription.id}
    display={'flex'}
    flexDirection={'row'}
    backgroundColor={'#ffffff'}
    borderRadius={18}
    marginBottom={4}
    padding={20}
    justifyContent={'space-between'}
    alignItems={'center'}>
    <SimpleBox flexDirection={'column'} gap={5}>
      {subscription.itemPrices.map(({name}) => <SimpleBox key={name} as={'p'} display={'block'} fontSize={'16px'} color={'#787E8D'} fontWeight={400}>{name}</SimpleBox>)}
    </SimpleBox>
    <SimpleBox as={'p'} fontSize={'16px'} color={'#787E8D'} fontWeight={400}>
      {toMoney(subscription.finalPrice)}/{toPlanPeriod(subscription.itemPrices[0])}</SimpleBox>
  </SimpleBox>

  const completedMarkup = <><SimpleBox display="flex" justifyContent="center" alignItems="center" flexDirection={'column'} padding={55}>
    <img src={chekmarkIcon} />
    <SimpleBox as={'p'} color={'#000000'} fontWeight={700} fontSize={30} textAlign={'center'} marginTop={20} maxWidth={343}>Subscription activated</SimpleBox>
    <SimpleBox as={'p'}
      color={'#787E8D'}
      fontWeight={400}
      fontSize={14}
      textAlign={'center'}
      maxWidth={343}
      marginTop={10}>Your subscription has been activated.</SimpleBox>
  </SimpleBox>
  <SimpleBox paddingTop="20px" display="flex" justifyContent="center">
    <Button
      onClick={onFinished}
      content="Back to Overview"
      size="big"
      primary
    />
  </SimpleBox></>

  const inCompletedMarkup = <><SimpleBox as={'p'} color={'##787E8D'} fontWeight={400} fontSize={16} textAlign={'center'}>{reactivateDescription}</SimpleBox>
    <SimpleBox marginTop={20} />
    <SimpleBox as={'p'} color={'##787E8D'} fontWeight={700} fontSize={16} textAlign={'center'}>Items in Subscription</SimpleBox>
    <SimpleBox marginTop={20} />
    {data?.account?.subscriptions?.length > 0 && data.account?.subscriptions?.map((subscription: SubscriptionViewModel) => subscriptionMarkup(subscription))}
    <SimpleBox paddingTop="40px" display="flex" justifyContent="center">
      {!primaryMethodAvailable && (<p>You must add a primary payment source before you can reactivate your subscription.</p>)}
      {primaryMethodAvailable && (
        <Button
          onClick={handleReactivate}
          loading={loading}
          content={scheduledForCancellation ? 'Remove Scheduled Cancellation' : 'Reactivate Subscription'}
          size="big"
          secondary
        />)}
    </SimpleBox></>

  return (<>
    {!completed && (<Modal.Header>
      <SimpleBox as={'p'} color={'#000000'} fontWeight={600} fontSize={40} textAlign={'center'}>Reactivate Subscription</SimpleBox>
    </Modal.Header>)}
    <Modal.Content>
      {completed ? completedMarkup : inCompletedMarkup}
    </Modal.Content>
  </>
  )
}

export { ReactivateSubscriptionModal }
