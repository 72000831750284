import React, { FC, useState } from 'react'
import { Password } from './change-password/Password'
import { Success } from './change-password/Success'
import { Success as EmailSuccess } from './reset-password/Success'
import Session from '../../shared/storages/session'

type StepsSimple = 'password' | 'success' | 'email'
type StepsTwoFA = 'password' | '2fa' | 'success'
type Steps = StepsSimple | StepsTwoFA
type Props = { onFinish?: (type?: 'reset') => void }
const ChangePassword: FC<Props> = ({ onFinish }) => {
  const [step, setStep] = useState<Steps>('password')
  const profile = Session.profile

  switch (step) {
    case 'success':
      return <Success onContinue={onFinish} />
    case 'email':
      return <EmailSuccess payload={{ email: profile?.email }} onContinue={onFinish} />
    case 'password':
      return <Password onContinue={ev => setStep(ev)} />
    default:
      return null
  }
}
export { ChangePassword }
