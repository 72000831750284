import React, { FC, useEffect, useState } from 'react'
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react'
import { SimpleForm } from '../../../SimpleForm'

const phonePrefixes = require('../../../../assets/phoneprefix.json')

type PhoneNumber = {
  prefix: string
  number: string
}

type Props = {
  phoneNumber: PhoneNumber
  onChange: (phoneNumber: PhoneNumber) => void
}

const PhoneWithPrefixInput: FC<Props> = ({phoneNumber, onChange}) => {
  const [prefix, setPrefix] = useState<string>(phoneNumber.prefix)
  const [number, setNumber] = useState<string>(phoneNumber.number)

  const uniquePrefixes = Array.from(new Set<string>(Object.values<string>(phonePrefixes).filter((key: string) => key.length >0 )))
  const sortedPrefixes = uniquePrefixes.sort((a: string, b: string) => a.localeCompare(b))
  const prefixOptions = sortedPrefixes.map((key: string) => ({key: key, value: key, text: `${key}`}))

  useEffect(() => {
    onChange({prefix, number})
  }, [prefix, number])

  return (
    <Grid>
      <GridRow columns={2}>
        <GridColumn width={'6'}>
          <Form.Field>
            <label>Prefix</label>
            <Form.Dropdown
              fluid
              search
              selection
              required
              value={prefix}
              onChange={(event, data) => { setPrefix(String(data.value)) }}
              options={prefixOptions}
            />
          </Form.Field>
        </GridColumn>
        <GridColumn width={10}>
          <Form.Field>
            <label>Phone Number</label>
            <SimpleForm.Input
              fluid
              value={number}
              required
              showValidation={prefix.length > 0 && number.length > 0}
              onChange={({value}) => setNumber(value)}
              loading={false}
            />
          </Form.Field>
        </GridColumn>
      </GridRow>
    </Grid>
  )
}

export { PhoneWithPrefixInput, PhoneNumber }
