
export const CARD_TYPES = ['visa', 'mastercard', 'amex', 'discover']

export const cardNumberValidator = ({ cardType }: { cardType: { type: string } }) => {
  if (CARD_TYPES.indexOf(cardType.type) === -1) {
    return 'Card must be Visa, Mastercard, American Express or Discover'
  } else {
    return ''
  }
}
