import React, { FC, useCallback } from 'react'
import { DataRemovalItem, DataRemovalStatus } from './List'
const Shield = require('../../../assets/images/icons/shield-white.svg')
const Pointer = require('../../../assets/images/pointer.svg')

const styles = require('./ExposedInfo.module.scss')

interface Props {
  items: DataRemovalItem[]
  riskLevel: number | null
  lastScan: Date
  nextScan: Date
}

/**
 * Exposed Info component
 */
const ExposedInfo: FC<Props> = ({ items, riskLevel, lastScan, nextScan }) => {
  const getBackground = useCallback((id: number) => {
    switch (id) {
      case 1:
        return 'linear-gradient(90deg, #AAEFB6 0%, #ECE76D 100%)'
      case 2:
        return 'linear-gradient(90deg, #ECE76D 1.63%, #FBCA4E 99.24%)'
      case 3:
        return 'linear-gradient(90deg, #FBCA4E 0%, #F5AC83 100%)'
      case 4:
        return 'linear-gradient(90deg, #F6AD82 0%, #F56E51 100%)'
      case 5:
        return 'linear-gradient(90deg, #F56E51 3.23%, #FB0023 97.83%)'
      default:
        return ''
    }
  }, [])

  const formatDate = useCallback((data: Date) => {
    const now = new Date()
    const date = new Date(data)

    if (now < date) {
      const seconds = Math.round((date.getTime() - now.getTime()) / 1000)

      // more than 1 day in the future
      const days = Math.round(seconds / 86400)
      if (days > 1) { return `in ${Math.round(seconds / 86400)} days` }

      // more than 1 hour in the future
      const hours = Math.round(seconds / 3600)
      if (hours > 1) { return `in ${Math.round(hours)} hours` }

      // minutes in the future
      const minutes = Math.round(seconds / 60)
      return `in ${minutes} minute${minutes > 1 ? 's' : ''}`
    } else {
      const seconds = Math.round((now.getTime() - date.getTime()) / 1000)

      // more than 1 day in the past
      const days = Math.round(seconds / 86400)
      if (days > 1) { return `${days} days ago` }

      // more than 1 hour in the past
      const hours = Math.round(seconds / 3600)
      if (hours > 1) { return `${hours} hours ago` }

      // minutes in the past
      const minutes = Math.round(seconds / 60)
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
    }
  }, [])

  /**
   * Count the number of items with the given status
   */
  const countItems = useCallback((status: DataRemovalStatus) => items.filter(item => item.status === status).length, [items])

  return <div className={styles.exposedInfo}>
    <div className={styles.shield}>
      <div className={styles.icon}>
        <img src={Shield} />
      </div>
      <h4>{countItems(DataRemovalStatus.EXPOSED)} Exposed private info.</h4>
    </div>
    <div className={styles.bar}>
      {/* health bar */}
      <div className={styles.item} style={{ background: getBackground(1) }}></div>
      <div className={styles.item} style={{ background: getBackground(2) }}></div>
      <div className={styles.item} style={{ background: getBackground(3) }}></div>
      <div className={styles.item} style={{ background: getBackground(4) }}></div>
      <div className={styles.item} style={{ background: getBackground(5) }}></div>
      <img src={Pointer} className={styles.pointer}
        style={{
          left: `calc(${riskLevel}% - 12.5px)`,
          opacity: typeof riskLevel === 'number' ? 1 : 0
        }}
      />
    </div>
    <div className={styles.risk}>
      <span className={styles.label}>Low risk</span>
      <span className={styles.label}>High risk</span>
    </div>

    <table className={styles.table}>
      <tr>
        <td className={styles.label}>Exposed info:</td>
        <td className={styles.value}>{countItems(DataRemovalStatus.EXPOSED)}</td>
      </tr>
      <tr>
        <td className={styles.label}>In progress:</td>
        <td className={styles.value}>{countItems(DataRemovalStatus.IN_PROGRESS)}</td>
      </tr>
      <tr>
        <td className={styles.label}>Removed:</td>
        <td className={styles.value}>{countItems(DataRemovalStatus.REMOVED)}</td>
      </tr>
      <tr>
        <td className={styles.label}>Last scan:</td>
        <td className={styles.value}>{formatDate(lastScan)}</td>
      </tr>
      <tr>
        <td className={styles.label}>Next scan:</td>
        <td className={styles.value}>{formatDate(nextScan)}</td>
      </tr>
    </table>
  </div>
}

export default ExposedInfo
