import React, {VFC} from 'react'
import {SimpleForm} from '../../../SimpleForm'

const styles = require('./FormDateField.module.scss')

type Props = {
  label: string
  value: string | null
  onChange: (value: string) => void
}

const FormDateField:VFC<Props> = ({label, value, onChange}) => (
  <div className={styles.container}>
    <div className={styles.label}>{label}</div>
    <div className={styles.input}>
      <SimpleForm.Input
        pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
        value={value}
        onChange={(d: any) => onChange(d.value)}
        size="small"
        required={true}
        fluid
        showValidation={true}
      />
    </div>
  </div>
)

export { FormDateField }
