import React, {FC} from 'react'

const styles = require('./FormHeader.module.scss')

type Props = {
  title: string
  description?: string
  image?: string
}

const FormHeader:FC<Props> = ({ title, description, image }) => (
  <div className={styles.container}>
    <img src={image} />
    <div className={styles.content}>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.description}>{description}</p>
    </div>
  </div>
)

export { FormHeader }
