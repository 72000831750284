import React, { FC } from 'react'
import { Modal, Header, Button, Image } from 'semantic-ui-react'
import Router from '../../../shared/router'
import { SimpleBox } from '../../SimpleBox'

const failedIcon = require('../../../assets/images/icons/verification-failed.svg')

const VerificationFailed: FC = () => (
  <>
    <Modal.Content>
      <SimpleBox marginTop="20px" display="flex" justifyContent="center" alignItems="center" flexDirection="column" maxWidth={300} mx="auto">
        <Image src={failedIcon} className="verification-icon" />
        <Header as="h5" textAlign="center">
          Verification Failed
        </Header>
        <SimpleBox textAlign="center" marginTop="15px" lineHeight="22px">
            Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
        </SimpleBox>
      </SimpleBox>
      <SimpleBox mt={7} mx="auto">
        <Button size="large" fluid className="contact-support" onClick={() => { Router.redirect('/freshdesk') }}>
            Contact support
        </Button>
      </SimpleBox>
    </Modal.Content>
  </>
)

export { VerificationFailed }
