import { action } from 'mobx'
import React, { useState, VFC } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { BackButton } from '../../../components/BackButton/BackButton'
import { SimpleBox } from '../../../components/SimpleBox'
import { SimpleText } from '../../../components/SimpleText'
import Mutation from '../../../shared/mutation'
import Notifications from '../../../shared/notifications'
import { PaymentSource } from '../../../type'
import { PaymentSourceCell } from '../../PaymentSourceCell'

type Props = {
  paymentSource: PaymentSource
  onCancel: () => void
  onDeleted: () => void
}

type RemovePaymentSourceResult = {
  removePaymentSource: boolean
}

const PaymentMethodsDelete: VFC<Props> = ({paymentSource, onCancel, onDeleted}) => {
  const removePaymentSourceMutation = new Mutation<RemovePaymentSourceResult>(`
    mutation removePaymentSource($input: RemovePaymentSourceInput!) {
      removePaymentSource(input: $input)
    }
  `)

  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = () => {
    setLoading(true)

    removePaymentSourceMutation.exec({ input: { id: paymentSource.id }}).then(
      action(() => {
        setLoading(false)

        const result = removePaymentSourceMutation.data?.removePaymentSource
        if (result) {
          Notifications.success('Payment method was removed')
          onDeleted()
        } else {
          Notifications.error(removePaymentSourceMutation.error(), { timing: 8000 })
        }
      }))
  }

  return <><Modal.Header className="payment-methods-header">
    <BackButton onBackPressed={onCancel} />
    <SimpleText size="large" weight="bold">Remove payment method</SimpleText>
  </Modal.Header>
  <Modal.Content className="white">
    <SimpleBox textAlign="center">
      <SimpleText size="small" className="subdued">Are you sure you want to remove this payment method?</SimpleText>
    </SimpleBox>
    <SimpleBox paddingTop="20px" paddingBottom="20px">
      <PaymentSourceCell paymentSource={paymentSource} />
    </SimpleBox>
    <SimpleBox paddingTop="20px" display="flex" justifyContent="center">
      <Button
        onClick={handleSubmit}
        content="Remove payment method"
        color="red"
        size="huge"
        primary
        loading={loading}
      />
    </SimpleBox>
  </Modal.Content></>
}

export { PaymentMethodsDelete }
