import React, { FC, useEffect, useRef, useState } from 'react'
import { Loader, Form, Header, Image } from 'semantic-ui-react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { set } from 'dot-prop'

import { isEnv } from '../config'

import Notifications from '../shared/notifications'
import { CardComponent } from '../fragments/payment/CardComponent'
import Router, { Authorizeable } from '../shared/router'
import { useNavigation } from '../hooks/useNavigation'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { SimpleLayout } from '../components/SimpleLayout'
import { toMoneyWithoutCalculation } from '../shared/format'
import { CardToken } from '../type'

const TO_CHECKOUT_PATH = isEnv('production') ? '/checkout/' : '/checkout/te6rhj1z'

const AuthCover = require('~assets/images/auth-cover.png')
const Logo = require('~assets/images/logo-black.svg')

type CheckPromo = { checkPromo: { user: { name: string }, price: number, title: string, description: string } }
const PromoQuery = gql`
  query Promo($code: ID!, $email: String!) {
    checkPromo(code: $code, email: $email) {
      user { name: shortFullName }
      price
      title
      description
    }
  }
`

type ApplyPromoVariables = { code: string, email: string, token?: string }
const ApplyPromoMutation = gql`
  mutation ApplyPromo($code: ID!, $email: String!, $token: String) {
    applyPromo(code: $code, email: $email, cardToken: $token)
  }
`

const Unavailable: FC = () => (
  <SimpleLayout>
    <SimpleLayout.Section oneThird>
      <SimpleBox height="100%" backgroundColor="#fff" className="form">
        <SimpleBox mb={5} mt={5}>
          <Image src={Logo} height={38} id="logo" />
        </SimpleBox>
        <SimpleBox mb={5}>
          <Header as="h3">Promotion Unavailable</Header>
        </SimpleBox>
        <SimpleBox mb={5}>
          <p>Sorry this promotion isn&apos;t applicable to your account. Please contact us for more.</p>
          <p><a href="mailto:promotions@virtualshield.com?subject=Error with Promo">promotions@virtualshield.com</a></p>
        </SimpleBox>
      </SimpleBox>
    </SimpleLayout.Section>
    <SimpleLayout.Section>
      <SimpleBox
        width="100%"
        height="100%"
        backgroundImage={`url('${AuthCover}')`}
        backgroundSize="846px 900px"
        backgroundPosition="right center"
        backgroundRepeat= "no-repeat"
      />
    </SimpleLayout.Section>
  </SimpleLayout>
)

const Promo: FC & Authorizeable = () => {
  const code = (Router.qs.code || '') as string
  const email = (Router.qs.email || '') as string

  const cardRef = useRef<any>()
  const { setNav, resetNav, setSide, resetSide } = useNavigation()
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [showCard, setShowCard] = useState<boolean>(false)
  const [applyLoading, setApplyLoading] = useState<boolean>(false)
  const [cardValid, setCardValid] = useState<boolean>(false)

  const [applyPromo] = useMutation<boolean, ApplyPromoVariables>(ApplyPromoMutation)
  const {data, error, loading} = useQuery<CheckPromo>(PromoQuery, {
    variables: { code, email },
    fetchPolicy: 'network-only',
  })

  // Layout settings
  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  // Apply check promo remediation
  if (error) {
    const type = error.graphQLErrors[0]?.extensions?.type
    const message = error.graphQLErrors[0]?.message

    if (type === 'not_found') {
      return (<Unavailable />)
    } else {
      Notifications.error(message)
      Router.redirect('/sign-in')
      return (<></>)
    }
  }

  // Still loading the promo data
  if (loading || !data) {
    return (<Loader active size="big" />)
  }

  // Submit actions
  const submit = (token?: string) => {
    setApplyLoading(true)
    applyPromo({ variables: { code, email, token } }).then(() => {
      Notifications.success('Promotion successfully applied!')
      Router.redirect('/sign-in?promo=success')
    }).catch(error => {
      const type = error.graphQLErrors[0]?.extensions?.type
      const message = error.graphQLErrors[0]?.message

      if (type === 'payment_failed') {
        setShowCard(true)
        setConfirmed(false)
      } else if(type === 'duplicated') {
        Notifications.success(message)
        Router.redirect('/sign-in?promo=duplicated')
      } else {
        Notifications.error(message)
      }
    }).finally(() => {
      setApplyLoading(false)
    })
  }

  const cardTokenizer = () => {
    setApplyLoading(true)
    cardRef.current.tokenize().then((cardToken: CardToken) => {
      submit(cardToken.token)
    }).catch(() => {
      Notifications.error('The card information is invalid')
      setApplyLoading(false)
    })
  }

  const buttonSubmit = showCard ? cardTokenizer : submit
  const canSubmit = confirmed && !cardErrors.length && (!showCard || cardValid)

  // Default markup
  return (
    <SimpleLayout>
      <SimpleLayout.Section oneThird>
        <SimpleBox height="100%" backgroundColor="#fff" className="form">
          <SimpleBox mb={5} mt={5}>
            <Image src={Logo} height={38} id="logo" />
          </SimpleBox>
          <SimpleBox mb={5}>
            <Header as="h3">Hi {data.checkPromo.user.name}</Header>
          </SimpleBox>
          <SimpleBox mb={5}>
            <p>We have a great opportunity for you!</p>
            <Header as="h4">{data.checkPromo.title}</Header>
            <p>{data.checkPromo.description}</p>
          </SimpleBox>
          {showCard && (
            <SimpleBox mb={5}>
              <SimpleBox mb={4} color="black">
                <SimpleCard backgroundColor="#F6DDE3" rounded="subtile">
                  We were not able to place the charge successfully.
                  Please provide a credit card to finish the purchase.
                </SimpleCard>
              </SimpleBox>
              <CardComponent
                forwardRef={cardRef}
                onErrors={setCardErrors}
                onValidChanged={(valid: boolean) => setCardValid(valid)}
                nameDisabled
              />
            </SimpleBox>
          )}
          <SimpleBox mb={8}>
            <Form.Checkbox
              name="confirm"
              size="small"
              label={`
                By checking here you agree with the promotion described above and
                agree with the immediate charge of ${toMoneyWithoutCalculation(data.checkPromo.price)}.
              `}
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
          </SimpleBox>
          <SimpleBox>
            <Form.Button
              color="red"
              loading={applyLoading}
              disabled={!canSubmit}
              size="huge"
              content="Accept"
              onClick={() => buttonSubmit()}
              fluid primary
            />
          </SimpleBox>
        </SimpleBox>
      </SimpleLayout.Section>
      <SimpleLayout.Section>
        <SimpleBox position="absolute" left={56} bottom={0} zIndex={6} p={'112px 0'}>
          <Header as="h1">Enjoy this opportunity!</Header>
        </SimpleBox>
        <SimpleBox
          width="100%"
          height="100%"
          backgroundImage={`url('${AuthCover}')`}
          backgroundSize="846px 900px"
          backgroundPosition="right center"
          backgroundRepeat= "no-repeat"
        />
      </SimpleLayout.Section>
    </SimpleLayout>
  )
}

Promo.authorize = false
export default Promo
