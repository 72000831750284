import React from 'react'
import { toMoney, toMoneyWithoutCalculation } from '../../../shared/format'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { calculatePriceAfterCoupon } from '../../../graphql/checkout'

interface SummaryCardHeaderProps {
    userCount: number
    coupon?: ChargebeeCoupon;
    price: number
    planPeriod: number
    activateNow: boolean
    bienniallyFreeMonths?: number
}

const SummaryCardHeader: React.FC<SummaryCardHeaderProps> = ({
  userCount,
  coupon,
  price,
  planPeriod,
  activateNow,
  bienniallyFreeMonths = 0
}) => {
  const planPriceWithCoupon = calculatePriceAfterCoupon(price, coupon)
  const totalPricePerMonth = (planPriceWithCoupon / (planPeriod + ((activateNow && bienniallyFreeMonths) || 0)))

  return(
    <>
      <SimpleBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <SimpleText size="title3">
          <span className="checkout-order-title">
            {userCount} {userCount > 1 ? 'users' : 'user'}
          </span>
        </SimpleText>
        <SimpleBox
          gap="8px">
          {coupon && <SimpleText size="title3" color="grey" textDecoration="lineThrough">
            <span className="checkout-order-title">
              {toMoneyWithoutCalculation(price)}
            </span>
          </SimpleText>}
          <SimpleText size="title3">
            <span className="checkout-order-title">
              {toMoney(planPriceWithCoupon)}
            </span>
          </SimpleText>
        </SimpleBox>
      </SimpleBox>
      {planPeriod >= 12 && (
        <SimpleBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          marginTop="6px"
          width="100%"
        >
          <div>
            {activateNow && bienniallyFreeMonths > 0 && (
              <SimpleBox
                className="checkout-free-text"
                background="#E8F7EB"
                borderRadius={10}
                padding="4px 10px"
              >
                +{bienniallyFreeMonths} months free
              </SimpleBox>
            )}
          </div>
          <p className="checkout-gray-text green-text">
            Only {toMoney(totalPricePerMonth)} / mo
          </p>
        </SimpleBox>
      )}
    </>
  )
}

export default SummaryCardHeader
