import React, { FC, ReactNode } from 'react'
import { Card, Container, Image } from 'semantic-ui-react'
const styles = require('./SubOverview.module.scss')

const tickGreenRound = require('~assets/images/tick-green-round.svg')
const crossRedRound = require('~assets/images/cross-red-round.svg')

export type SubCard = { title: string; content?: ReactNode; action?: { label: string } }

const features = [
  '60 Days Risk Free',
  'Security Management Dashboard',
  'Unlimited Devices',
  '24/7 Support',
  'Unlimited Access to VPN',
]

/**
 * Props for the component
 */
type Props = {
  /**
   * An array of 'cards' to show in the component
   */
  cards: SubCard[];

  /**
   * Determines if the subscription features are striked through, as in the subscription is not active
   */
  strikeFeatures: boolean;

  /**
   * Layout determines if the grid is made up of thirds or in a 50/50 split
   */
  layout: 'thirds' | 'split';
}

const SubOverview: FC<Props> = ({ cards, strikeFeatures, layout }) => (
  <Card fluid className={styles.Main}>
    <Card.Content className={styles.Content}>
      {/* cards */}
      <div className={`${styles.Grid} ${layout === 'thirds' ? styles.Grid__Thirds : styles.Grid__Split}`}>
        {cards.map(c => (
          <div className={styles.Column} key={c.title}>
            <Card className={styles.Card}>
              <Card.Content>
                <p className={styles.Card__Title}>{c.title}</p>
                <div className={styles.Card__Content}>{c.content && c.content}</div>
              </Card.Content>
            </Card>
          </div>
        ))}
      </div>
      {/* info */}
      <Container fluid className={styles.Features}>
        <h5 className={styles.Features__Title}>Access to features</h5>
        <ul className={styles.List}>
          {features.map(i => (
            <li key={i} className={styles.List__Item}>
              <Image src={strikeFeatures ? crossRedRound : tickGreenRound} height={24} width={24} />
              <h6 className={strikeFeatures ? styles.List__Striked : null}>{i}</h6>
            </li>
          ))}
        </ul>
      </Container>
    </Card.Content>
  </Card>
)

export { SubOverview }
