import { classNames } from '@shopify/css-utilities'
import React, { FC } from 'react'
const styles = require('./Section.module.scss')

type Props = { oneThird?: boolean }
const Section: FC<Props> = ({ children, oneThird }) => {
  const stylesMain = classNames(styles.Main, oneThird ? styles.OneThird : undefined)
  return <div className={stylesMain}>{children}</div>
}
export { Section }
