import { classNames } from '@shopify/css-utilities'
import React, { FC, useEffect, useState } from 'react'
import { Input, InputProps, Icon } from 'semantic-ui-react'
import { strengthForValue } from '../../utils/password'
const styles = require('./SimplePasswordInput.module.scss')

const strengthLevels = ['null', 'weak', 'low', 'high', 'best']
type Props = {
  value: string | undefined
  size?: InputProps['size']
  fluid?: boolean
  onChange?: (value: string | undefined, strength: number) => void
}
const SimplePasswordInput: FC<Props> = ({ size, fluid, value, onChange }) => {
  const [input, setInput] = useState<string>(value || '')
  const [peek, setPeek] = useState<boolean>(false)
  const strength = strengthForValue(value)

  const strengthLevel = strengthLevels[strength]
  const styleStrength = classNames(styles.Strength)
  const styleStrengthValue = classNames(styles.StrengthValue, styles[`StrengthValue--${strengthLevel}`])
  const styleStrengthLabel = classNames(styles.StrengthLabel)
  const styleStrengthIndicator = classNames(styles.StrengthIndicator, styles[`StrengthIndicator--${strengthLevel}`])

  useEffect(() => {
    if (onChange) { onChange!(input, strength) }
  }, [strength, input])

  return (
    <>
      {/* input */}
      <Input
        size={size}
        value={value}
        onChange={e => setInput!(e.target.value)}
        fluid={fluid}
        type={peek ? 'text' : 'password'}
        icon={<Icon name="eye" link onClick={() => setPeek(!peek)} />}
      />
      {/* strength */}
      <div className={styleStrength}>
        <label className={styleStrengthLabel}>
          Strength
          <span className={styleStrengthValue}>{strengthLevel}</span>
        </label>
        <div className={styleStrengthIndicator}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </>
  )
}
export { SimplePasswordInput }
