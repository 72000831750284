import React, { FC, useEffect, useState } from 'react'
import { Grid, GridColumn, GridRow } from 'semantic-ui-react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'
import { ProtectedItem } from './ProtectedItem'
import { ProtectionStatus } from './ProtectionStatus'
import { RenderProtectedArea } from './RenderProtectedArea'
import { RenderProtectionStatus } from './RenderProtectionStatus'
import { IdentisafeInformation } from '../../../type'
import { LaunchIdentisafe } from '../LaunchIdentisafe'

type Props = {
  data: IdentisafeInformation
  hasDarkWebMonitoring: boolean
}

const ProtectionArea: FC<Props> = ({ hasDarkWebMonitoring, data }) => {
  const [personalInformation, setPersonalInformation] = useState<ProtectedItem[]>([])
  const [darkWebMonitoring, setDarkWebMonitoring] = useState<ProtectedItem[]>([])
  const [protectionStatus, setProtectionStatus] = useState<ProtectionStatus>('secured')

  useEffect(() => {
    setProtectionStatus(data.issueDetected ? 'issue-detected' : 'secured')

    setPersonalInformation([
      {title: 'Full Name', active: data.personalInformation.full_name},
      {title: 'Home Address', active: data.personalInformation.home_address},
      {title: 'Social Security Number', active: data.personalInformation.ssn}])

    setDarkWebMonitoring([
      {title: 'Contact Information', active: data.darkWebMonitoring.contact_information},
      {title: 'Credit & Debit Cards', active: data.darkWebMonitoring.credit_cards},
      {title: 'Bank Accounts', active: data.darkWebMonitoring.bank_accounts},
      {title: 'Medical ID Number', active: data.darkWebMonitoring.medical},
      {title: 'Driver License', active: data.darkWebMonitoring.driver_license},
      {title: 'Passport Number', active: data.darkWebMonitoring.passport}])
  }, [])

  const openChat = () => {
    // @ts-ignore
    window.fcWidget.open()
  }

  return (<SimpleCard className={`mt-2 protection-card ${protectionStatus}`}>
    <Grid stackable>
      <GridRow columns={2}>
        <GridColumn stretched>
          <RenderProtectionStatus status={protectionStatus} />
        </GridColumn>
        <GridColumn>
          <LaunchIdentisafe />
        </GridColumn>
      </GridRow>
      {hasDarkWebMonitoring && (
        <GridRow>
          <GridColumn textAlign="right" className="upgrade-to-virtual-one">
            <SimpleBox display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
              <SimpleText className="text">Available at VirtualShield ONE</SimpleText>
              <a onClick={openChat} className="upgrade-btn">Upgrade</a>
            </SimpleBox>
          </GridColumn>
        </GridRow>
      )}
      <GridRow columns={2}>
        <GridColumn>
          <SimpleCard color="subdued">
            <SimpleBox display="flex" justifyContent="space-between">
              <SimpleText size="title3">Personal Information</SimpleText>
            </SimpleBox>
            <Grid columns={1} className="protection-area-grid">
              <RenderProtectedArea items={personalInformation} locked={false} />
            </Grid>
          </SimpleCard>
        </GridColumn>
        <GridColumn>
          <SimpleCard color="subdued" className={`${hasDarkWebMonitoring ? 'protection-area-locked' : ''}`}>
            <SimpleBox display="flex" justifyContent="space-between">
              <SimpleText size="title3">Dark Web Monitoring</SimpleText>
            </SimpleBox>
            <Grid stackable columns={2} className="protection-area-grid">
              <RenderProtectedArea items={darkWebMonitoring} locked={hasDarkWebMonitoring} />
            </Grid>
          </SimpleCard>
        </GridColumn>
      </GridRow>
    </Grid>
  </SimpleCard>)
}

ProtectionArea.displayName = 'ProtectionArea'

export { ProtectionArea }
