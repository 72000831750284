import React, { VFC, useMemo } from 'react'
import { Image, Button } from 'semantic-ui-react'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { SimpleText } from '../components/SimpleText'
import { PaymentSource } from '../type'

/// Icon for each card
const CardOther = require('~assets/images/card-other.svg')
const CardAmex = require('~assets/images/card-amex.svg')
const CardMC = require('~assets/images/card-mastercard.svg')
const CardVisa = require('~assets/images/card-visa.svg')
const GoogleLogo = require('~assets/images/google.svg')
const AppleLogo = require('~assets/images/apple.svg')
const PaymentPayPall = require('~assets/images/payment-paypal.svg')
const LogoPayPall = require('~assets/images/logo-paypal.svg')

// Helper indexes
const PAYMENT_TITLES: Record<string, string> = { 'google_pay': 'Google Pay', 'apple_pay': 'Apple Pay' }
const LOGO_INDEX: Record<string, any> = {
  'mastercard': CardMC,
  'visa': CardVisa,
  'american_express': CardAmex,
  'google_pay': GoogleLogo,
  'apple_pay': AppleLogo,
}

type Props = {
  onEdit?: (source: PaymentSource) => void
  onSelected?: (source: PaymentSource) => void
  onDelete?: (source: PaymentSource) => void
  selected?: boolean
  paymentSource: PaymentSource
}

/**
 * Payment source cell. Renders a card or paypal cell
 */
const PaymentSourceCell: VFC<Props> = ({onEdit, onSelected, onDelete, selected, paymentSource}) => {
  const {type, card, paypal} = paymentSource

  const onClick = () => {
    onSelected?.(paymentSource)
  }

  const cardContents = useMemo(() => {
    if(!card) { return null }

    return (<SimpleBox display="flex" alignItems="center">
      <Image src={LOGO_INDEX[type] || LOGO_INDEX[card.brand] || CardOther} height={30} className="mr-1" />
      <div className="text--small mr-auto">
        <SimpleText weight="extraBold">
          <SimpleBox as="span" display="flex" gap={6}>
            <span>••••</span>
            <span>••••</span>
            <span>••••</span>
            <span>{card.last4}</span>
          </SimpleBox>
        </SimpleText>
        <p className="text--subdued" style={{ textTransform: 'capitalize' }}>
          {PAYMENT_TITLES[type] || card.brand}
        </p>
      </div>
      {onDelete && (<Button className="subdued" negative onClick={() => { onDelete(paymentSource) }}>Remove</Button>)}
      {onEdit && (<Button className="subdued" primary onClick={() => { onEdit(paymentSource) }}>Edit</Button>)}
      {onSelected && <div className={selected ? 'payment-method-selected' : 'payment-method-deselected'}></div>}
    </SimpleBox>)
  }, [paymentSource, onDelete, onEdit, onSelected, selected])

  const paypalContents = useMemo(() => {
    if(!paypal) { return null }

    return (<SimpleBox display="flex" alignItems="center">
      <Image src={PaymentPayPall} height={30} className="mr-1" />
      <SimpleBox className="text--small mr-auto" overflow="hidden" flexDirection="column">
        <h5 className="text--medium leading--none" style={{ margin: 0 }}>
          <Image src={LogoPayPall} height={15} className="mr-1" />
        </h5>
        <SimpleBox
          className="text--subdued"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          title={paypal.email}
        >
          {paypal.email}
        </SimpleBox>
      </SimpleBox>
      {onDelete && (<Button className="subdued" negative onClick={() => { onDelete(paymentSource) }}>Remove</Button>)}
      {onEdit && (<Button className="subdued" primary onClick={() => { onEdit(paymentSource) }}>Edit</Button>)}
      {onSelected && <div className={selected ? 'payment-method-selected' : 'payment-method-deselected'}></div>}
    </SimpleBox>)
  }, [paymentSource, onDelete, onEdit, onSelected, selected])

  const content = useMemo(() => {
    const content = type === 'paypal' ? paypalContents : cardContents

    return onSelected ? <a onClick={onClick}>{content}</a> : content
  }, [paymentSource, onDelete, onEdit, onSelected, cardContents, paypalContents])

  return <SimpleCard rounded="heavy" padding="subtile" fluid color={selected ? 'florestlight' : 'subdued'}>
    {content}
  </SimpleCard>
}

export { PaymentSourceCell }
