import { gql, useQuery } from '@apollo/client'
import React, {FC, useEffect, useState} from 'react'
import {Button, Container, Header, Image, Loader, Modal} from 'semantic-ui-react'
import { SubCard, SubOverview } from '../components/SubOverview'
import { SubUsers } from '../components/SubUsers'
import { PaymentMethod } from '../fragments/PaymentMethod'
import { CancelSubscription } from '../fragments/CancelSubscription'
import { useNavigation } from '../hooks/useNavigation'
import { Subscription } from '../type'
import { ReactivateSubscription } from '../fragments/ReactivateSubscription'
import Session from '../shared/storages/session'
import {CancelConfirmationModal} from '../fragments/CancelSubscriptionModal'
import {ReactivateSubscriptionModal} from '../fragments/ReactivateSubscriptionModal'
import {toDateString, toPlanPeriod} from '../utils'
import {Invoices} from '../fragments/Invoices'
import {toMoney} from '../shared/format'

const cancelModalStyles = require('../fragments/CancelSubscriptionModal.module.scss')


const SubWarningLogoSVN = require('~assets/images/icons/warning-white.svg')

const Dashboard: FC = () => {
  const [modal, setModal] = useState<'cancel' | 'reactivate' | null>(null)
  const { setNav, resetNav } = useNavigation()
  const { data, loading, refetch } = useQuery(
    gql`
      query {
        account {
          id
          ownerId
          addons
          customer {
            primaryPaymentSource {
              type
              card
              paypal
            }
          }
          subscriptions {
            status
            finalPrice
            period
            nextBillingAt
            plan {
              period
              periodUnit
              item {
                name
              }
            }
          }
          usage {
            users {
              current
              limit
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    setNav('hidden')
    return () => {
      resetNav()
    }
  }, [])

  if(loading || data === undefined || data.account === undefined) {
    return (
      <Container>
        <Loader active />
      </Container>
    )
  }

  const account = data.account
  const subscription = account.subscriptions?.[0]
  const status = subscription?.status || 'CANCELLED'

  const limitUsers = account.usage?.users?.limit

  const isOwner = data?.account?.ownerId === Session.profile?.id
  const terminated = subscription?.period?.finish || status === 'CANCELLED'

  const openChangePlan = () => {
    if (window.location.hostname.includes('dev')) {
      window.location.href = 'https://virtualshield.dev/pricing'
    } else if (window.location.hostname.includes('me')) {
      window.location.href = 'http://virtualshield.me:3001/pricing'
    } else {
      window.location.href = 'https://virtualshield.com/pricing'
    }
  }

  const cards: SubCard[] = status === 'CANCELLED' ? [
    {
      title: 'Subscription',
      content: (
        <div className="center aligned">
          <Image src={SubWarningLogoSVN} width="85%" />
          <h6>No Active Subscription</h6>
        </div>
      ),
    },
  ] : [
    {
      title: 'Plan',
      action: { label: 'Change plan' },
      content: (
        <div className="center aligned">
          <h5 className="ui grey inverted header">
            <b>{subscription?.plan?.item?.name}</b>
          </h5>
          <div className={'changePlanButton'}>
            <Button size={'tiny'} className={'changePlanButton'} onClick={openChangePlan}>
              Change Plan
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Users',
      action: { label: 'Add users' },
      content: (
        <div className="center aligned">
          <h5 className="ui grey inverted header">{(limitUsers === 1) ? 'Individual' : 'Family'}</h5>
          <div className="">{(limitUsers === 1) ? '1 user' : `${limitUsers} users`}</div>
        </div>
      ),
    },
    {
      // title: 'Next Billing Amount',
      title: 'Next Billing',
      content: (
        <div className="center aligned" style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
          {subscription && (
            <div>
              <h5 className="ui grey inverted header">{toMoney(subscription.finalPrice || 0)}</h5>
              <div>{toPlanPeriod(subscription.plan)}</div>
              {subscription.nextBillingAt && <div>{toDateString(subscription.nextBillingAt)}</div>}
            </div>
          )}
        </div>
      ),
    },
  ]


  return (
    <>
      <Modal className={cancelModalStyles.Modal} size={'small'} closeIcon open={modal === 'cancel'} onClose={() => setModal(null)}>
        <CancelConfirmationModal onCancelled={refetch} onFinished={() => setModal(null)} />
      </Modal>
      <Modal size={'small'} closeIcon open={modal === 'reactivate'} onClose={() => setModal(null)}>
        <ReactivateSubscriptionModal onReactivated={refetch} onFinished={() => setModal(null)} />
      </Modal>
      <div className="dashboard">
        <Header as="h4">My Subscription</Header>
        <div style={{ marginTop: 24 }}>
          <Container fluid>
            <div className="grid grid-overview">
              <div>
                {/* cards */}
                <SubOverview cards={cards} strikeFeatures={status === 'CANCELLED'} layout={status === 'CANCELLED' ? 'split' : 'thirds'} />
                {/* users */}
                {(account?.usage?.users?.limit && account.usage.users.limit > 1) && (
                  <SubUsers ownerId={account?.ownerId} usage={account.usage.users} />
                )}
                <Invoices />
              </div>
              <div>
                {/* payment methods */}
                <PaymentMethod />
                {/* cancel/re-activate sub */}
                {isOwner && (terminated ? <ReactivateSubscription onReactivateClicked={() => setModal('reactivate')} /> :
                  <CancelSubscription onCancelClicked={() => setModal('cancel')} />)}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Dashboard
