import React, {createContext, FC, useContext, useEffect, useState} from 'react'
import {gql, useLazyQuery, useMutation} from '@apollo/client'
import moment from 'moment'

export type PrivacyName = {
  prefix: string | null
  first: string
  middle: string | null
  last: string
  suffix: string | null
}

export type PrivacyAddress = {
  city: string
  state: string | null
  country: string | null
  address1: string | null
  address2: string | null
  postalCode: string | null
}

export type PrivacyProfile = {
  id: string | null
  names: PrivacyName[]
  otherNames: PrivacyName[]
  addresses: PrivacyAddress[]
  dateOfBirth: Date | null
  emailAddresses: string[]
  phoneNumbers: string[]
}

interface IDataRemovalContext {
  privacyProfiles: PrivacyProfile[]
  primaryProfile: PrivacyProfile | null
  saveNames: (names: PrivacyName[]) => Promise<void>
  saveBirthday: (date: Date) => Promise<void>
  saveEmailAddresses: (emails: string[]) => Promise<void>
  savePhoneNumbers: (phones: string[]) => Promise<void>
  saveAddresses: (addresses: PrivacyAddress[]) => Promise<void>
}

const ProfilesQuery = gql`query privacy_profiles {
    me {
        privacyProfiles {
          id
          dateOfBirth
          emailAddresses
          phoneNumbers
          names {
                first
                middle
                last
            }
            otherNames {
                first
                middle
                last
            }
            addresses {
                city
                state
                country
                address1
                address2
                postalCode
            }
        }
    }
}`

const UpdateNamesMutation = gql`mutation updatePrivacyNames($input: UpdateNamesInput!) {
    updatePrivacyNames(input: $input) {
        category
        first
        middle
        last
    }
}`

const UpdatePrivacyProfileMutation = gql`mutation updatePrivacyProfile($input: PrivacyProfileInput!) {
    updatePrivacyProfile(input: $input) {
        id
    }
}`

const UpdatePrivacyAdressesMutation = gql`mutation updatePrivacyAddresses($input: UpdateAddressesInput!) {
    updatePrivacyAddresses(input: $input) {
        city
    }
}`

// @ts-ignore
const DataRemovalContext = createContext<IDataRemovalContext>(null)

export const DataRemovalContextProvider:FC = ({children}) => {
  const [loadPrivacyProfiles, { data: profilesData }] = useLazyQuery(ProfilesQuery, { fetchPolicy: 'network-only' })
  const [updateNamesMutation] = useMutation(UpdateNamesMutation)
  const [updatePrivacyProfile] = useMutation(UpdatePrivacyProfileMutation)
  const [updatePrivacyAddresses] = useMutation(UpdatePrivacyAdressesMutation)
  const [privacyProfiles, setPrivacyProfiles] = useState<PrivacyProfile[]>([])
  const primaryProfile = privacyProfiles[0]

  useEffect(() => {
    if (profilesData) {
      setPrivacyProfiles(profilesData.me.privacyProfiles)
    }
  }, [profilesData])
  useEffect(() => { loadPrivacyProfiles() }, [])

  // eslint-disable-next-line no-unused-vars
  const saveNames = async (names: PrivacyName[]) => new Promise<void>((resolve, reject) => {
    updateNamesMutation({
      variables: {
        'input': {
          'names': names.map(name => ({first: name.first, middle: name.middle, last: name.last}))
        }}
    }).then(() => {
      loadPrivacyProfiles()
      resolve()
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
      reject(error)
    })
  })
  // eslint-disable-next-line no-unused-vars
  const saveBirthday = async (date: Date) => new Promise<void>((resolve, reject) => {
    updatePrivacyProfile({
      variables: {
        'input': {
          'dateOfBirth': moment(date).format('YYYY-MM-DD')
        }
      }
    }).then(() => {
      loadPrivacyProfiles()
      resolve()
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
      reject(error)
    })
  })

  // eslint-disable-next-line no-unused-vars
  const saveEmailAddresses = async (emails: string[]) => new Promise<void>((resolve, reject) => {
    updatePrivacyProfile({
      variables: {
        'input': {
          'emailAddresses': emails,
        }}
    }).then(() => {
      loadPrivacyProfiles()
      resolve()
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
      reject(error)
    })
  })
  // eslint-disable-next-line no-unused-vars
  const savePhoneNumbers = async (phones: string[]) => new Promise<void>((resolve, reject) => {
    updatePrivacyProfile({
      variables: {
        'input': {
          'phoneNumbers': phones,
        }}
    }).then(() => {
      loadPrivacyProfiles()
      resolve()
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
      reject(error)
    })
  })
  // eslint-disable-next-line no-unused-vars
  const saveAddresses = async (addresses: PrivacyAddress[]) => new Promise<void>((resolve, reject) => {
    updatePrivacyAddresses({
      variables: {
        'input': {
          'addresses': addresses.map(address => ({
            city: address.city,
            state: address.state,
            country: address.country,
            address1: address.address1,
            address2: address.address2,
            postalCode: address.postalCode
          }))
        }}
    }).then(() => {
      loadPrivacyProfiles()
      resolve()
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
      reject(error)
    })
  })

  return (<DataRemovalContext.Provider value={{
    privacyProfiles,
    primaryProfile,
    saveNames,
    saveBirthday,
    saveEmailAddresses,
    savePhoneNumbers,
    saveAddresses}}>{children}</DataRemovalContext.Provider>)
}

export const useDataRemoval = (): IDataRemovalContext => useContext(DataRemovalContext)
