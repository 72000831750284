import Mutation from '../../shared/mutation'
import React, {FC, useState} from 'react'
import Notifications from '../../shared/notifications'
import {Button, GridColumn, GridRow, Icon} from 'semantic-ui-react'
import {SimpleBox} from '../SimpleBox'
import {IDENTISAFE_SSO_URL} from '../../const'

const JWT_MUTATION = `mutation identisafeJwt {
  identisafeJwt
}`

const LaunchIdentisafe: FC = () => {
  const mutation = new Mutation<{ identisafeJwt: string }>(JWT_MUTATION)
  const [loading, seLoading] = useState(false)

  const openIdentisafe = () => {
    seLoading(true)
    mutation.exec().then(() => {
      if (mutation.hasErrors) {
        Notifications.error(mutation.error())
      } else if (mutation.data?.identisafeJwt) {
        window.open(`${IDENTISAFE_SSO_URL}?jwt=${mutation.data.identisafeJwt}`, '_blank')
      }
      seLoading(false)
    })
  }

  return (
    <SimpleBox className="launch-identisafe">
      <GridRow>
        <GridColumn>
          <Button primary={true} size="small" icon loading={loading} onClick={openIdentisafe}>
            Manage IdentiSafe
            <Icon name={'external alternate'} className={'launch-identisafe-icon'} />
          </Button>
        </GridColumn>
      </GridRow>
    </SimpleBox>
  )
}

export { LaunchIdentisafe }
