import React, { FC, useState } from 'react'
import { FormContent, FormHeader, MaskedNumberInput } from '.'

const BankAccountsForm: FC = () => {
  const [bankAccounts, setBankAccounts] = useState<string[]>([''])

  const handleAddNewBankAccount = () => {
    setBankAccounts([...bankAccounts, ''])
  }

  const handleOnChangeBankAccount = (index: number, accountNumber: string) => {
    setBankAccounts([
      ...bankAccounts.slice(0, index),
      accountNumber,
      ...bankAccounts.slice(index + 1)
    ])
  }

  return (<div className="identity-security-form">
    <FormHeader
      image={require('../../../assets/images/identity-security/bankaccounts.svg')}
      title="Bank Accounts"
      text="Please fill in your bank accounts"
    />
    <FormContent>
      {bankAccounts.map((accountNumber, index) => <MaskedNumberInput
        key={index} value={accountNumber}
        title="Bank Account Number"
        onChange={(changedBankAccountNumber: string) => {
          handleOnChangeBankAccount(index, changedBankAccountNumber)
        }} />)}
      <a className="add-new-link" onClick={handleAddNewBankAccount}>+ Add more</a>
    </FormContent>
  </div>)
}

BankAccountsForm.displayName = 'BankAccountsForm'

export { BankAccountsForm }
