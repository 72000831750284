import React, {FC, useEffect, useState} from 'react'
import {Button, Image} from 'semantic-ui-react'
import {FormHeader} from '../../Form/Header/FormHeader'
import {FormContainer, FormContainerColumn} from '../../Form/Container/FormContainer'
import {FormTextField} from '../../Form/Fields/Text/FormTextField'
import {FormSelectField, Option} from '../../Form/Fields/Select/Select'
import {PrivacyAddress, useDataRemoval} from '../context'
import Notifications from '../../../shared/notifications'

const styles = require('./PersonalInformation.module.scss')

const AddressesImage = require('../../../assets/images/data-removal-addresses.svg')
const FormRemove = require('../../../assets/images/form-remove.svg')

type Props = {
  onStepComplete: () => void
}

const StateData = require('../../../assets/states.json')
const States: Option[] = Object.keys(StateData).map(key => ({label: StateData[key], value: key.toLowerCase()}))
const FindStateOption = (key: string): Option | undefined => States.find((state: Option) => state.value === key)
const EMPTY_ADDRESS = {city: '', state: null, postalCode: null, address1: null, address2: null, country: null}

const Addresses:FC<Props> = ({ onStepComplete }) => {
  const dataRemoval = useDataRemoval()
  const [addresses, setAddresses] = React.useState<PrivacyAddress[]>([EMPTY_ADDRESS])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (dataRemoval.primaryProfile) {
      if (dataRemoval.primaryProfile.addresses.length > 0) {
        setAddresses(dataRemoval.primaryProfile.addresses)
      } else {
        setAddresses([EMPTY_ADDRESS])
      }
    }
  }, [dataRemoval.primaryProfile])

  const handleAddressChange = (index: number, item: PrivacyAddress) => {
    const newAddress = [...addresses]
    newAddress[index] = item
    setAddresses(newAddress)
  }
  const addAddress = () => {
    setAddresses([...addresses, EMPTY_ADDRESS])
  }

  const removeAddress = (index: number) => {
    const newAddress = [...addresses]
    newAddress.splice(index, 1)
    setAddresses(newAddress)
  }

  const saveAndContinue = async () => {
    setLoading(true)
    try {
      await dataRemoval.saveAddresses(addresses)
      onStepComplete()
    } catch (e) {
      setLoading(false)
      Notifications.error(e.message || 'Something went wrong. Please try again later.')
    }
  }

  const addressForm = (item: PrivacyAddress, index: number) => <div key={index} className={styles.altNameForm}>
    {index > 0 && <div className={styles.altLabel}>Additional Address #{index}</div>}
    <FormContainerColumn>
      <FormTextField required={true} pattern={'^[^\x00-\x1F!"$%*-+<-@[\\]-_\x7B-\x7F]{1,100}$'} label={'Address'} value={item.address1} onChange={value => {
        handleAddressChange(index, {...item, address1: value})
      }} />
      <FormTextField pattern={'^[^\x00-\x1F!"$%*-+<-@[\\]-_\x7B-\x7F]{1,100}$'} label={'Address 2'} value={item.address2} onChange={value => {
        handleAddressChange(index, {...item, address2: value})
      }} />
      <FormTextField required={true} pattern={'^[^\x00-\x1F!-&(-+\\/:-@[-_\x7B-\x7F]{1,100}$'} label={'City'} value={item.city} onChange={value => {
        handleAddressChange(index, {...item, city: value})
      }} />
    </FormContainerColumn>
    <br/>
    <FormContainer>
      <FormSelectField label={'State'} value={item.state ? (FindStateOption(item.state) || null) : null} options={States} onChange={value => {
        handleAddressChange(index, {...item, state: value.value})
      }} />
      <FormTextField required={true} pattern={'^[^\x00-\x1F!-&(-+\\/:-@[-_\x7B-\x7F]{1,100}$'} label={'ZIP Code'} value={item.postalCode} onChange={value => {
        handleAddressChange(index, {...item, postalCode: value})
      }} />
    </FormContainer>
    {index > 0 && (
      <div className={styles.removeAltName} onClick={() => { removeAddress(index) }}>
        <Image src={FormRemove} />
      </div>)}
  </div>

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormHeader
          title={'Addresses'}
          description={'Enter all addresses you would like removed. We cannot remove a profile with an address\nthat is ' +
            'not included here. If you can’t remember the full address, simply provide the city and state.'}
          image={AddressesImage} />
        <br />
        {addresses.map((address, index) => addressForm(address, index))}
        <div className={styles.addName} onClick={addAddress}>+ Add another address</div>
      </div>
      <Button primary={true} size="large" loading={loading} onClick={saveAndContinue}>
        Save and Continue
      </Button>
      <br />
    </div>
  )
}

export { Addresses }
