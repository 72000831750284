import React, { FC } from 'react'
import { Button, Modal, Image, Header } from 'semantic-ui-react'
import Router from '../../../shared/router'
import { SimpleBox } from '../../SimpleBox'

const successIcon = require('../../../assets/images/icons/verification-success.svg')

const VerificationSucccess: FC = () => (
  <>
    <Modal.Content>
      <SimpleBox marginTop="20px" display="flex" justifyContent="center" alignItems="center" flexDirection="column" maxWidth={300} mx="auto">
        <Image src={successIcon} className="verification-icon" />
        <Header as="h5" textAlign="center">
          Success
        </Header>
        <SimpleBox textAlign="center" marginTop="15px" lineHeight="22px">
          Your identity has been verified. ID Monitoring is now activated. Lorem upsum dolor sit amet.
        </SimpleBox>
      </SimpleBox>
      <SimpleBox mt={7} mx="auto">
        <Button size="large" fluid primary onClick={() => { Router.redirect('/identity-security') }}>
            Back to Overview
        </Button>
      </SimpleBox>
    </Modal.Content>
  </>
)

export { VerificationSucccess }
