import React, {VFC} from 'react'
import {Image, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../../components/SimpleBox'


type Props = {
  newEmail: string
}

const Success: VFC<Props> = ({ newEmail }) => <>
  <Modal.Header>
    <h6 className="title">Change Email</h6>
  </Modal.Header>
  <Modal.Content>
    <SimpleBox textAlign="center" maxWidth={440} mx="auto">
      <SimpleBox display="inline-flex">
        <Image src={require('~assets/images/success.png')} height={200} />
      </SimpleBox>
      <h4 style={{ display: 'inline-flex', maxWidth: 360, margin: '0 auto' }}>
          We’ve sent a request at your email address.
      </h4>
      <br />
      <br />
      <p>
          Open the email - <span className="text">{newEmail}</span> to confirm you’re really you. Can’t find it?
          Try spam folder or another tabs.
      </p>
    </SimpleBox>
    <br />
  </Modal.Content>
</>

export { Success }
