import { gql, useMutation } from '@apollo/client'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Form, Header, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { ChangePassword } from '../fragments/modal/ChangePassword'
import { ResetPassword } from '../fragments/modal/ResetPassword'
import { useNavigation } from '../hooks/useNavigation'
import Notifications from '../shared/notifications'
import Session from '../shared/storages/session'
import {ChangeEmail} from '../fragments/modal/ChangeEmail'

const Settings: FC = () => {
  const { setNav, resetNav } = useNavigation()

  const [updateUserInfo, { loading: mutateUserInfoLoading }] = useMutation(
    gql`
      mutation ($input: ChangeUserInfoInput!) {
        changeInfo(input: $input)
      }
    `
  )
  const profile = Session.profile

  const [firstName, setFirstName] = useState(profile?.firstName)
  const [lastName, setLastName] = useState(profile?.lastName)

  const [modal, setModal] = useState<'change-password' | '2fa' | 'reset-password' | 'change-email' | undefined>(undefined)

  const save = async () => {
    if(!firstName || !lastName) {
      return
    }

    if (firstName === profile?.firstName && lastName === profile?.lastName) {
      return
    }

    try {
      await updateUserInfo({ variables: { input: { firstName, lastName } } })
      Notifications.success('Personal information updated')
    } catch (e) {
      Notifications.error('Could not update personal information')
    }
  }

  useEffect(() => {
    setNav('hidden')
    return () => {
      resetNav()
    }
  }, [])

  return (
    <div className="settings">
      <Modal closeIcon size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)}>
        {modal === 'change-password' && (
          <ChangePassword
            onFinish={type => {
              if (type && type === 'reset') {
                setModal('reset-password')
              } else {
                setModal(undefined)
              }
            }}
          />
        )}
        {modal === 'reset-password' && (
          <ResetPassword
            onFinish={() => {
              setModal(undefined)
            }}
          />
        )}
        {modal === 'change-email' && (<ChangeEmail />)}
      </Modal>
      <div className="display-flex align-baseline">
        <Header as="h4" className="mr-2">
          Account Settings
        </Header>
      </div>
      {/* personal info */}
      <div style={{ marginTop: 24 }}>
        <Card fluid>
          <Card.Content>
            <div className="grid grid-segmented">
              {/* info */}
              <div>
                <h6>Name</h6>
                <p className="text--small">
                  To change your first and last name, please update the fields and press the save button.
                </p>
              </div>
              {/* form */}
              <div className="grid grid-rows">
                <div className="grid grid-halfs">
                  <Form.Field>
                    <Form.Input
                      fluid
                      label="First name"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      loading={mutateUserInfoLoading}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      fluid
                      label="Last name"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      loading={mutateUserInfoLoading}
                    />
                  </Form.Field>
                </div>
                <div>
                  <Button className="subdued" floated={'right'} disabled={mutateUserInfoLoading} primary onClick={save}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
      <div style={{ marginTop: 24 }}>
        <Card fluid>
          <Card.Content>
            <div className="grid grid-segmented">
              {/* info */}
              <div>
                <h6>Email</h6>
                <p className="text--small">
                  To change your email, press &lsquo;Change Email&lsquo; button and complete the process. You will be asked to verify your new email before the change takes place.
                </p>
              </div>
              {/* form */}
              <div className="grid grid-rows">
                <SimpleCard color="subdued" fluid style={{borderRadius: '6px'}}>
                  <SimpleBox display="flex" alignItems="center">
                    <div className="text mr-auto">{profile?.email}</div>
                    <Button className="subdued" primary onClick={() => setModal('change-email')}>
                      Change Email
                    </Button>
                  </SimpleBox>
                </SimpleCard>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
      {/* security */}
      <div style={{ marginTop: 24 }}>
        <Card fluid>
          <Card.Content>
            <div className="grid grid-segmented">
              {/* info */}
              <div>
                <h6>Security</h6>
                <p className="text--small">
                  You can change your password here.
                </p>
              </div>
              {/* options */}
              <div>
                {/* change password */}
                <SimpleCard color="subdued" fluid style={{borderRadius: '6px'}}>
                  <SimpleBox display="flex" alignItems="center">
                    <div className="text mr-auto" style={{ letterSpacing: 1 }}>••••••••••</div>
                    <Button className="subdued" primary onClick={() => setModal('change-password')}>
                      Change Password
                    </Button>
                  </SimpleBox>
                </SimpleCard>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

export default observer(Settings)
