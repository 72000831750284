import React, { useMemo } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { toMoneyWithoutCalculation as toMoney } from '../../../shared/format'
import { calculateCouponDiscount as couponDiscount } from '../../../graphql/checkout'
import Decimal from 'decimal.js'

interface AddonCardProps {
  title: string
  description: string
  price: number
  icon: string
  isSelected: boolean
  coupon?: ChargebeeCoupon
  planPeriod: number
  freeMonths: number
  handleClickOnAdd?: (event: React.MouseEvent<HTMLElement>) => void
}

const AddonCard: React.FC<AddonCardProps> = ({
  title,
  description,
  icon,
  price,
  isSelected,
  coupon,
  planPeriod,
  freeMonths,
  handleClickOnAdd,
}) => {
  const [originalPrice, priceWithDiscount] = useMemo(() => {
    const basePrice = new Decimal(price)
    const discountPrice = basePrice.minus(couponDiscount(basePrice, coupon))
    return [basePrice.div(planPeriod), discountPrice.div(planPeriod + freeMonths)].map(v => toMoney(v.toNumber()))
  }, [coupon, price, freeMonths])

  return (
    <div className={`addon-card${isSelected ? ' addon-card-selected' : ''}`} onClick={handleClickOnAdd}>
      <div className="detail">
        <div className="title">{title}</div>
        <div className="subTitle">{description}</div>
        {(originalPrice !== priceWithDiscount && (
          <div className="price-box price-discount-box">
            <div className="price">
              for <span>{priceWithDiscount}</span>/mo
            </div>
            <div className="original-price-row">
              <div className="original-price">{originalPrice}/mo</div>
            </div>
          </div>
        )) || (
          <div className="price-box price">
            for <span>{originalPrice}</span>/month
          </div>
        )}
        <div className="logo">
          <Image as="img" src={icon} />
        </div>
      </div>
      <Button className={`addon-button${isSelected ? '-selected' : ''}`}>
        {isSelected ? 'Added' : 'Add'}
      </Button>
    </div>
  )
}

export default AddonCard
