import { gql, useLazyQuery } from '@apollo/client'
import { action } from 'mobx'
import React, { FC, useEffect, useState } from 'react'
import { Button, Form, FormField, Header, Image } from 'semantic-ui-react'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { SimpleLayout } from '../components/SimpleLayout'
import { SimplePasswordInput } from '../components/SimplePasswordInput/SimplePasswordInput'
import { observer } from '../decorators'
import { useNavigation } from '../hooks/useNavigation'
import Mutation from '../shared/mutation'
import Notifications from '../shared/notifications'
import Router, { Authorizeable } from '../shared/router'
import Session from '../shared/storages/session'
import { ERROR_TYPES } from './index'

const LogoSymbol = require('~assets/images/logo-symbol.svg')

type Result = { resetPassword?: string }

const ResetPassword: FC & Authorizeable = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [strength, setStrength] = useState(0)
  const [completed, setCompleted] = useState(false)

  const [getUserInfo, { called, loading: queryLoad, data }] = useLazyQuery(gql`
    query user($token: String!) {
      userByResetToken(token: $token) {
        fullName
        email
      }
    }
  `)

  const resetPassword = new Mutation<Result>('mutation($input: PasswordResetInput!) { resetPassword(input: $input) }')

  const token = Router.qs.token
  const user = data?.userByResetToken

  const handleSubmit = async () => {
    if(loading) {
      return
    }

    setLoading(true)
    const input = { password, passwordConfirmation: password, resetPasswordToken: token }
    return resetPassword.exec({ input }).then(
      action(() => {
        setLoading(false)
        if (resetPassword.data?.resetPassword) {
          Session.authenticated = true
          Session.accessToken = resetPassword.data.resetPassword
          Session.query.load()
          setCompleted(true)
        } else {
          const type = resetPassword.errors?.[0]?.extensions?.error_type
          const extra = ERROR_TYPES[type as keyof typeof ERROR_TYPES] || ''
          const message = resetPassword.error() || 'Unable to reset password'
          Notifications.error(message + (extra ? ': ' : '') + extra)
        }
      })
    )
  }

  const handleRedirect = () => {
    Router.redirect('/apps')
  }

  const handlePasswordChange = (v: string, s: number) => {
    setPassword(v)
    setStrength(s)
  }

  if(called && !queryLoad && !user) {
    Router.redirect('/forgot-password?error=invalid_token')
  }

  useEffect(() => {
    if (!token) {
      Router.redirect('/forgot-password?error=invalid_token')
    } else {
      getUserInfo({ variables: { token } })
    }
  }, [])

  useEffect(() => {
    setNav('simple', 'reset-password-header')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  const userMarkup = user && (
    <SimpleBox mt={4}>
      <SimpleCard outline fluid>
        <SimpleBox display="flex" alignItems="center">
          <Image src={require('~assets/images/icons/user.svg')} height={38} />
          <SimpleBox display="block" ml={1.5}>
            <Header as="h5">{user.fullName}</Header>
            <div>{user.email}</div>
          </SimpleBox>
        </SimpleBox>
      </SimpleCard>
    </SimpleBox>
  )

  const passMarkup = (
    <>
      <SimpleBox mt={4} textAlign="center">
        <Header as="h4">Create new password</Header>
      </SimpleBox>
      {/* user */}
      {userMarkup}
      {/* password */}
      <Form id="reset-password" onSubmit={handleSubmit}>
        <SimpleBox mt={2} mb={8}>
          <FormField>
            <label htmlFor="password">Password</label>
            <SimplePasswordInput size="large" value={password} onChange={handlePasswordChange!} fluid />
          </FormField>
        </SimpleBox>
        <Form.Button loading={loading} color="red" disabled={strength < 2} size="huge" content="Continue" fluid />
      </Form>
    </>
  )

  const completeMarkup = (
    <>
      <SimpleBox mt={4} textAlign="center">
        <Header as="h4">You’re all set!</Header>
      </SimpleBox>
      {/* user */}
      {userMarkup}
      {/* password */}
      <SimpleBox mt={4}>
        <Button color="red" size="huge" fluid onClick={handleRedirect}>
          Go to Dashboard
        </Button>
      </SimpleBox>
    </>
  )

  return (
    <SimpleLayout>
      <SimpleBox display="grid" alignItems="center" width="100%">
        <SimpleBox maxWidth={424} mx="auto">
          <SimpleCard fluid>
            <SimpleBox px={1} py={2}>
              <SimpleBox mt={1} textAlign="center" className="logo-symbol">
                <Image src={LogoSymbol} height={74} />
              </SimpleBox>
              {!completed ? passMarkup : completeMarkup}
            </SimpleBox>
          </SimpleCard>
          {/* back */}
          {!completed && (
            <SimpleBox display="flex" justifyContent="center" mt={6}>
              <Button as="a" href="/" secondary>
                Back to Login
              </Button>
            </SimpleBox>
          )}
        </SimpleBox>
      </SimpleBox>
    </SimpleLayout>
  )
}

ResetPassword.authorize = false
export default observer(ResetPassword)
