import { gql, useQuery } from '@apollo/client'
import React, { useState, VFC } from 'react'
import { Modal } from 'semantic-ui-react'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import Router from '../shared/router'
import { PaymentMethodsModal } from './modal/payment-methods/PaymentMethodsModal'
import { PaymentSourceCell } from './PaymentSourceCell'
import { PaymentStatus } from './PaymentStatus'

const PaymentMethod: VFC = () => {
  const [modal, setModal] = useState<'payment-methods' | undefined>((Router.qs.paypal || Router.qs.payments) ? 'payment-methods' : undefined)

  const { data } = useQuery(
    gql`
      query {
        account {
          id
          customer {
            primaryPaymentSource {
              type
              card
              paypal
            }
          }
          subscriptions {
            nextBillingAt
            status
          }
        }
      }
    `
  )

  const account = data?.account
  const customer = account?.customer
  const primaryPaymentSource = customer?.primaryPaymentSource

  return (
    <>
      <Modal closeIcon size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)}>
        <PaymentMethodsModal />
      </Modal>
      <SimpleCard rounded="heavy" fluid bottomGutter>
        <div className="card__title display-flex mb-2">
          <div className="mr-auto">Payment method</div>
        </div>
        <SimpleBox mt={1}>
          {primaryPaymentSource && <PaymentSourceCell paymentSource={primaryPaymentSource} onEdit={() => {
            setModal('payment-methods')
          }} />}
          <PaymentStatus />
        </SimpleBox>
      </SimpleCard>
    </>
  )
}

export { PaymentMethod }
