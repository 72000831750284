import React, { FC } from 'react'

type Props = {
    title: string
    step: string
    totalSteps: string
    active: boolean
}

const IdentitySecurityHeaderStep: FC<Props> = ({title, step, totalSteps, active}) => (
  <li className={`segment ${active ? 'active' : ''}`}>
    <span className="badge">
      {step}
    </span>
    <span className="text">
      {title}
      <span className="text-step-number">Step {step}/{totalSteps}</span>
    </span>
  </li>
)

export { IdentitySecurityHeaderStep }
