import { classNames } from '@shopify/css-utilities'
import React, { FC } from 'react'

const styles = require('./Content.module.scss')

const Content: FC = ({ children }) => {
  const styleMain = classNames(styles.Main)
  return <div className={styleMain}>{children}</div>
}
export { Content }
