import React, { useEffect, useMemo, useState, VFC } from 'react'
import { action, observer } from '@decorators'
import { useToggle } from '@shopify/react-hooks'
import {
  Form,
  FormField,
  Header,
  Image,
  Loader,
  Container,
} from 'semantic-ui-react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import Mutation from '~shared/mutation'
import Notifications from '~shared/notifications'
import Router from '~shared/router'
import Session from '~shared/storages/session'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { SimpleLayout } from '../components/SimpleLayout'
import { SimplePasswordInput } from '../components/SimplePasswordInput/SimplePasswordInput'
import { useNavigation } from '../hooks/useNavigation'
import { ChargebeeItemPrice } from '../shared/types'
import { queryPostCheckoutUpsells } from '../graphql/checkout'
import Upsells from '../components/Checkout/Upsells'

const REQUIRED_PARAMS: Hash = {
  checkoutSubscriptionId: 'checkoutSubscriptionId',
  checkoutId: 'checkoutConfigId',
  planId: 'planId',
  periodUnit: 'periodUnit',
  period: 'period',
}

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

const preferableRedirectQuery = gql`
  { account { subscriptions { plan { item { welcomeRedirect } } } } }
`

type Redirect = { account: { subscriptions?: { plan?: { item: { welcomeRedirect?: string } } }[] } }
type Result = { createPassword: boolean }
type UpsellsData = {
  addons: Array<Pick<ChargebeeItemPrice, 'id' | 'name' | 'itemId' | 'period' | 'periodUnit' | 'price'> & {
    item: { featuresList?: string[] }
  }>
  availableUpsells: string[]
  checkoutConfiguration: {
    cartExtraFeatures: {
      title: string
      description?: string
      headline?: string[]
      imageUrl?: string
      chargebeeId: string
      fakePrice?: string
      couponApplicable: boolean
      theme: 'dark' | 'light'
    }[]
  }
}

export type PCOffer = UpsellsData['addons'][0] & {
  feature?: UpsellsData['checkoutConfiguration']['cartExtraFeatures'][0]
}

const Welcome: VFC = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()

  const loading = useToggle(false)
  const [password, setPassword] = useState('')
  const [strength, setStrength] = useState(0)
  const [PCOfferUpsells, setPCOfferUpsells] = useState<PCOffer[] | undefined>()
  const [withAddon, setWithAddon] = useState<'loading' | 'visible' | 'hidden'>('loading')

  const mutation = new Mutation<Result>('mutation($password: String!) { createPassword(password: $password) }')
  const [loadUpsells] = useLazyQuery<UpsellsData>(queryPostCheckoutUpsells)
  const welcomeRedirect = useQuery<Redirect>(preferableRedirectQuery)

  useEffect(() => {
    const params = Router.qs
    const variables = Object.keys(REQUIRED_PARAMS).reduce((acc, key) => {
      const value = params[REQUIRED_PARAMS[key]]
      if (acc && typeof value !== 'undefined') {
        return { ...acc, [key]: value }
      } else {
        return null
      }
    }, {})

    if (!variables) {
      setWithAddon('hidden')
      return
    }

    loadUpsells({ variables: { ...variables, currency: Session.currency } }).then(({ data }) => {
      if (!data) {
        setWithAddon('hidden')
        return
      }

      const { checkoutConfiguration: { cartExtraFeatures }, availableUpsells, addons } = data
      const index: Record<string, UpsellsData['addons'][0]> = addons.reduce((acc, addon) => (
        { ...acc, [addon.itemId]: addon }
      ), {})

      const result = cartExtraFeatures.reduce((acc, feature) => {
        if (!availableUpsells.includes(feature.chargebeeId) || !index[feature.chargebeeId]) {
          return acc
        }

        return [...acc, { ...index[feature.chargebeeId], feature }]
      }, [])

      if (result.length) {
        setWithAddon('visible')
        setPCOfferUpsells(result)
      } else {
        setWithAddon('hidden')
      }
    })
  }, [])

  const handleSubmit = () => {
    loading.setTrue()
    mutation.exec({ password }).then(action(() => {
      loading.setFalse()
      if (mutation.data?.createPassword) {
        const path = welcomeRedirect.data?.account?.subscriptions?.[0]?.plan?.item?.welcomeRedirect || '/apps'
        Session.update({ hasPassword: true })
        Router.redirect(path)
      } else {
        Notifications.error(mutation.error())
      }
    }))
  }

  const handlePasswordChange = (value: string, strength: number) => {
    setPassword(value)
    setStrength(strength)
  }

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  if (withAddon === 'loading') {
    return (
      <SimpleLayout>
        <Container>
          <Loader active />
        </Container>
      </SimpleLayout>
    )
  } else if (withAddon === 'visible' && PCOfferUpsells) {
    return (
      <Upsells
        upsells={PCOfferUpsells}
        handleCloseUpsells={() => setWithAddon('hidden')}
        activateNow={Router.qs.activateNow?.toString() === '1'}
      />
    )
  }

  return (
    <SimpleLayout>
      <SimpleBox display="grid" alignItems="center" width="100%">
        <SimpleBox width={590} mx="auto" className="form set-password-form" padding={0}>
          <SimpleBox textAlign="center">
            <Image src={PlanCheckMarkIcon} height={58} width={58} style={{ margin: '0 auto' }} />
            <SimpleBox my={4}>
              <Header as="h4">Payment Successful. Thank You!</Header>
            </SimpleBox>
            <SimpleBox mb={8}>
              <div className="text text--subdued text--large">
                Create your password to get started with your new protection.
                <br />
                <span className="text">For {Session.profile?.email}</span>
              </div>
            </SimpleBox>
          </SimpleBox>
          <SimpleCard rounded="heavy" padding="heavy" fluid>
            <SimpleBox>
              <Form id="reset-password" onSubmit={handleSubmit}>
                <SimpleBox mt={2} mb={6}>
                  <FormField>
                    <label htmlFor="password">Password</label>
                    <SimplePasswordInput size="large" value={password} onChange={handlePasswordChange!} fluid />
                  </FormField>
                  <SimpleBox mt={2}>
                    <div>
                      A strong password has more than 8 characters and includes a combination
                      of uppercase and lowercase letters, numbers, and symbols.
                    </div>
                  </SimpleBox>
                </SimpleBox>
                <SimpleBox maxWidth={276} mx="auto">
                  <Form.Button
                    color="red"
                    primary
                    disabled={strength < 2}
                    size="huge"
                    content="Get Started"
                    fluid
                    loading={loading.value}
                  />
                </SimpleBox>
              </Form>
            </SimpleBox>
          </SimpleCard>
        </SimpleBox>
      </SimpleBox>
    </SimpleLayout>
  )
}

export default observer(Welcome)
