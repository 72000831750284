import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { Context, NavigationDisplay, SideBarDisplay } from '.'
import { Navigation } from '../../components/Navigation/Navigation'
import { Sidebar } from '../../components/Sidebar'
import Session from '../../shared/storages/session'

const Provider: FC = observer(({ children }) => {
  const [navigation, setNavigation] = useState<NavigationDisplay>('full')
  const [navigationClassNames, setNavigationClassNames] = useState<string|undefined>()
  const [sidebar, setSidebar] = useState<SideBarDisplay>('full')
  const [visible, setVisible] = useState<boolean>(false)

  const setNav = (t: NavigationDisplay, c?: string) => {
    setNavigation(t)
    setNavigationClassNames(c)
  }

  const resetNav = () => {
    setNavigation('full')
    setNavigationClassNames(undefined)
  }
  const setSide = (t: SideBarDisplay) => {
    setSidebar(t)
  }
  const resetSide = () => {
    setSidebar('full')
  }
  const setVisibleFalse = () => {
    setVisible(false)
  }

  const showSideBar = Session.hasDashboardAccess && sidebar !== 'hidden'

  return (
    <Context.Provider value={{ navigation, sidebar, setNav, resetNav, setSide, resetSide }}>
      {/* nav */}
      {navigation !== 'hidden' && <Navigation type={navigation} navClassNames={navigationClassNames} />}
      {/* content */}
      {showSideBar && (
        <>
          <Button icon className="toggle-sidebar" onClick={() => setVisible(!visible)}>
            <Icon name="sidebar" />
          </Button>
          <Sidebar type={sidebar} className={`${visible && 'visible'}`} setVisibleFalse={setVisibleFalse}/>
        </>
      )}
      {/*  */}
      <main className={`${showSideBar ? 'with-sidebar' : ''}`} onClick={() => setVisible(false)}>
        {children}
      </main>
    </Context.Provider>
  )
})

export { Provider }
