import React, { FC, useState } from 'react'
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import Router from '../../../shared/router'
import { SimpleBox } from '../../SimpleBox'
import { CancelModal } from '../CancelModal'
import { IdentitySecurityHeaderStep } from './IdentitySecurityHeaderStep'

type Props = {
  selectedTab: 'personal-information' | 'dark-web-monitoring'
}

const IdentitySecurityHeader: FC<Props> = props => {
  const [cancelOpen, setCancelOpen] = useState(false)

  const handleSave = () => {
    Router.redirect('/identity-security')
  }

  const handleDiscard = () => {
    Router.redirect('/identity-security')
  }

  return <>
    <Modal className="identity-security-cancel-modal" closeIcon open={cancelOpen} onClose={() => setCancelOpen(false)}>
      <CancelModal onDiscardClicked={handleDiscard} onSaveClicked={handleSave} />
    </Modal>
    <SimpleBox display="flex" flexDirection="row" className="identity-security-header" justifyContent="space-between">
      <SimpleBox className="identity-security-header-logo">
        <Image src={require('../../../assets/images/logo-black.svg')} />
      </SimpleBox>
      <SimpleBox className="identity-security-header-controls">
        <Header as="h5">Identity Security</Header>
        <ul className="segments">
          <IdentitySecurityHeaderStep title="Personal Information" step="1" totalSteps="2" active={props.selectedTab === 'personal-information'} />
          <IdentitySecurityHeaderStep title="Dark Web Monitoring" step="2" totalSteps="2" active={props.selectedTab === 'dark-web-monitoring'} />
        </ul>
      </SimpleBox>
      <SimpleBox className="identity-security-header-cancel">
        <Button size="medium" primary={true} onClick={() => { setCancelOpen(true) }}>Cancel</Button>
      </SimpleBox>
    </SimpleBox>
  </>
}

export { IdentitySecurityHeader }
