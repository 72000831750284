import React, {FC, useState, useEffect} from 'react'
import { Modal, Image, Header } from 'semantic-ui-react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'
import {IdentisafeAlert} from '../../../type'
import Notifications from '../../../shared/notifications'
import Mutation from '../../../shared/mutation'
import {IDENTISAFE_SSO_URL} from '../../../const'
import {DarkWebScanResults} from './DarkWebScanResults'

const alertIcon = require('../../../assets/images/icons/protection-alert.svg')
const chevronIcon = require('../../../assets/images/icons/chevron.svg')

type Props = {
    alert: IdentisafeAlert
}

type Solution = {
  title: string
  description: string
  recommended: boolean
  action: () => void
}

const JWT_MUTATION = `mutation identisafeJwt {
  identisafeJwt
}`

const AlertModal: FC<Props> = props => {
  const [solutions, setSolutions] = useState<Solution[]>([])
  const mutation = new Mutation<{ identisafeJwt: string }>(JWT_MUTATION)

  useEffect(() => {
    // TODO: Fetch actual solutions

    setSolutions([
      {
        title: 'Jump over to IdentiSafe',
        description: 'On the identiSafe website you can resolve this issue',
        recommended: true,
        action: () => {
          mutation.exec().then(() => {
            if (mutation.hasErrors) {
              Notifications.error(mutation.error())
            } else if (mutation.data?.identisafeJwt) {
              window.open(`${IDENTISAFE_SSO_URL}?jwt=${mutation.data.identisafeJwt}`, '_blank')
            }
          })
        }
      }])
    /*
    setSolutions([
      {
        title: 'Manual fix',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        recommended: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        action: () => {}
      },
      {
        title: 'Automatic fix',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        recommended: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        action: () => {}
      },
      {
        title: 'Ignore this file',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        recommended: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        action: () => {}
      },
    ])*/
  }, [])

  const applySolution = (solution: Solution) => {
    solution.action()
  }

  const openChat = () => {
    // @ts-ignore
    // @ts-ignore
    window.fcWidget.open()
  }

  return (<Modal.Content>
    <SimpleBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="20px">
      <Image className="issue-icon" src={alertIcon} />
      <Header as="h5">{props.alert.title}</Header>
      {props.alert.type === 'dark_web_scan' && <DarkWebScanResults />}
      {solutions.map((solution, index) => (<SimpleCard key={index} fluid>
        <SimpleBox display="flex" flexDirection="row" alignItems="center" gap="10px">
          <SimpleBox flex="1" flexDirection="column">
            <SimpleBox flexDirection="row" alignItems="center" gap="10px">
              <SimpleText size="title3">{solution.title}</SimpleText>
              {solution.recommended && <SimpleText className="recommended-badge">Recommended</SimpleText>}
            </SimpleBox>
            {solution.description}
          </SimpleBox>
          <SimpleBox>
            <a className="apply-solution-btn" onClick={() => { applySolution(solution) }}>
              <Image src={chevronIcon} />
            </a>
          </SimpleBox>
        </SimpleBox>
      </SimpleCard>))}

      <SimpleBox flexDirection="row" justifyContent="center" alignItems="center" gap="16px">
        <SimpleText className="contact-us-hint">Didnt find a solution?</SimpleText>
        <a onClick={openChat} className="contact-us-btn" target={'_blank'}>Contact us</a>
      </SimpleBox>
    </SimpleBox>
  </Modal.Content>)
}

export { AlertModal }
