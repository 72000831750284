import React, { FC } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { SimpleBox } from '../SimpleBox'

const IMAGE = require('../../assets/images/addon/insurance.png')

const SignUp: FC = () => {
  const openChat = () => {
    // @ts-ignore
    window.fcWidget.open()
  }

  return (
    <SimpleBox className="sign-up">
      <Image src={IMAGE} />
      <h5>Keep your identity secured</h5>
      <SimpleBox className="steps" marginTop="33px" marginBottom="33px">
        <SimpleBox className="step">
          <span className="dot"></span>
          <span className="text">Personal Information</span>
        </SimpleBox>
        <SimpleBox className="step">
          <span className="dot"></span>
          <span className="text">Dark Web Monitoring</span>
        </SimpleBox>
      </SimpleBox>
      <Button primary={true} size="large" onClick={openChat}>
        Upgrade
      </Button>
    </SimpleBox>
  )
}

export { SignUp }
