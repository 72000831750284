import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleText } from '../components/SimpleText'
import { Account } from '../type'

/**
 * PaymentStatus renders a text about when the next payment will be for
 * the user subsciprtion. If the subscription is cancelled the
 * cancellation date will be shown.
 *
 */
const PaymentStatus = () => {
  /* Fetch the next billing date and the subscription status */
  const { data } = useQuery(
    gql`
        query {
            account {
                subscriptions {
                    nextBillingAt
                    status
                    period
                }
            }
        }
        `
  )

  const account = data?.account as Account
  const subscription = (account?.subscriptions || [])[0]
  const dateFormatter = Intl.DateTimeFormat('en-US', ({ dateStyle: 'long' } as any))

  /* Set statusText according to subscription status */
  if(!subscription) {
    return (<></>)
  }

  return (
    <SimpleBox mt={2}>
      <SimpleBox className="text">
        <SimpleText as="span" className="text--subdued">
          {(subscription.status === 'CANCELLED') && (
            <>
              Your subscription was cancelled on{' '}.
              {subscription.period?.finish ? dateFormatter.format(new Date(subscription.period.finish)) : 'unknown date'}
            </>
          ) || (!!subscription.period?.finish) && (
            <>
              Your subscription ends on{' '}
              {dateFormatter.format(new Date(subscription.period.finish))}
              {' '}and will not be automatically renewed.
            </>
          ) || (
            <>
              Your subscription will automatically renew on{' '}
              {subscription?.nextBillingAt && dateFormatter.format(new Date(subscription.nextBillingAt))}
              .
            </>
          )}
        </SimpleText>
      </SimpleBox>
    </SimpleBox>
  )
}

export { PaymentStatus }
