import React, { FC } from 'react'
import { GridColumn, Image } from 'semantic-ui-react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'
import { ProtectedItem } from './ProtectedItem'

const okIcon = require('../../../assets/images/icons/ok-circle.svg')
const lockedIcon = require('../../../assets/images/icons/locked.svg')

type Props = {
    items: ProtectedItem[]
    locked: boolean
}

const RenderProtectedArea: FC<Props> = props => {
  const icon = (item: ProtectedItem) => {
    if (props.locked) {
      return <Image src={lockedIcon} className="icon" />
    } else if (item.active) {
      return <Image src={okIcon} className="icon" />
    } else {
      return <div className="icon"></div>
    }
  }

  const cssClass = (item: ProtectedItem) => props.locked ? 'locked' : (item.active ? 'active' : 'inactive')

  return <>{props.items.map((item: ProtectedItem) => (<GridColumn key={item.title} className={`protected-area ${cssClass(item)}`}>
    <SimpleBox display="flex" flexDirection="row" alignItems="center" gap="8px">
      {icon(item)}
      <SimpleText className="text">{item.title}</SimpleText>
    </SimpleBox>
  </GridColumn>))}</>
}

export { RenderProtectedArea }
