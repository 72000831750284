import React, { FC, useEffect, useState } from 'react'
import { SimpleBox } from '../SimpleBox'
import { useNavigation } from '../../hooks/useNavigation'
import { ProtectionArea } from './Dashboard/ProtectionArea'
import {gql, useMutation, useQuery} from '@apollo/client'
// import { useDataRemoval } from './context'
import List, { DataRemovalItem } from './Dashboard/List'
import ExposedInfo from './Dashboard/ExposedInfo'
import {useTimer} from 'use-timer'
import {Button} from 'semantic-ui-react'
import Notifications from '../../shared/notifications'

const styles = require('./Dashboard.module.scss')

type Props = {
  setupPressed: () => void
}

// const CreateScanMutation = gql`mutation createScan($profileId: string!) {
//     createScan(profileId: $profileId)
// }`

const PrivacyScanQuery = gql`query privacy_scan_results {
  me {
      privacyProfiles {
          id
          scanResults
          enrollments {
            id
          }
      }
  }
}`

const CancelEnrollmentMutation = gql`mutation cancelEnrollment($profileId: String!) {
  cancelEnrollment(profileId: $profileId)
}`

const EnableEnrollmentMutation = gql`mutation enableEnrollment($profileId: String!) {
  enableEnrollment(profileId: $profileId)
}`

const Dashboard: FC<Props> = ({setupPressed}) => {
  const { time, start, pause } = useTimer({interval: 5000})

  // const dataRemoval = useDataRemoval()
  // const [createScanMutation] = useMutation(CreateScanMutation)
  const [cancelEnrollmentMutation] = useMutation(CancelEnrollmentMutation)
  const [enableEnrollmentMutation] = useMutation(EnableEnrollmentMutation)
  const [toggleLoading, setToggleLoading] = useState(false)

  const {data, refetch, loading} = useQuery(PrivacyScanQuery, { fetchPolicy: 'network-only' })
  const { setNav, setSide } = useNavigation()

  const [items, setItems] = useState<DataRemovalItem[]>([])
  const [riskLevel, setRiskLevel] = useState<number | null>(null)
  const [nextScan, setNextScan] = useState<Date>(new Date())
  const [lastScan, setLastScan] = useState<Date>(new Date())
  const [active, setActive] = useState<boolean>(false)


  useEffect(() => {
    setNav('hidden')
    setSide('full')
    return () => {
      pause()
    }
  }, [])

  useEffect(() => {
    if (active) {
      start()
    } else {
      pause()
    }

    return () => {
      pause()
    }
  }, [active])

  useEffect(() => {
    if(!loading) {
      refetch()
    }
  }, [time])

  useEffect(() => {
    if (data && data.me && data.me.privacyProfiles) {
      const results = data.me.privacyProfiles[0].scanResults
      const enrollments = data.me.privacyProfiles[0].enrollments

      setActive(enrollments.length > 0)
      setItems(results.items || [])

      if (results.riskLevel) {
        setRiskLevel(results.riskLevel)
      }

      if (results.nextScan) {
        setNextScan(new Date(results.nextScan))
      }

      if(results.lastScan) {
        setLastScan(new Date(results.lastScan))
      }
    }
  }, [data])

  const toggleEnrollment = () => {
    if (data && data.me && data.me.privacyProfiles) {
      const profile = data.me.privacyProfiles[0]
      if (profile) {
        setToggleLoading(true)
        if (active) {
          cancelEnrollmentMutation({variables: {profileId: profile.id}}).then(_ => refetch()).then(_ => setToggleLoading(false)).catch(e => Notifications.error(e.message))
        } else {
          enableEnrollmentMutation({variables: {profileId: profile.id}}).then(_ => refetch()).then(_ => setToggleLoading(false)).catch(e => Notifications.error(e.message))
        }
      }
    }
  }

  return (<>
    <div className={styles.changeProfileButton}><Button secondary={true} onClick={setupPressed}>Change Profile</Button></div>
    <SimpleBox className={styles.dashboard}>
      {active && (
        <ExposedInfo
          items={items}
          riskLevel={riskLevel}
          lastScan={lastScan}
          nextScan={nextScan}
        />)}
      <ProtectionArea togglingLoading={toggleLoading} active={active} onToggleActive={() => { toggleEnrollment() }} />
    </SimpleBox>
    <List items={items} enrolled={active} />
    {/* <Alerts />
    <button onClick={startScanPressed}>Start Scan</button>
    <button onClick={setupPressed}>Change Profile</button> */}
  </>)
}

export { Dashboard }
