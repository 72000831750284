import React, { FC } from 'react'
import { Button } from 'semantic-ui-react'
import { Plan, PlanSellingPoint } from '../../../../type'
import { SimpleBox } from '../../../SimpleBox'
import { Single } from '../Single'
import {Coupon} from '../../../../utils/coupon'
import Router from '../../../../shared/router'

const styles = require('./Pair.module.scss')
const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

type Props = { plans?: Plan[], coupon?: Coupon }
const Pair: FC<Props> = ({ plans, coupon}) => {
  const pointsForPlan = (plan: Plan): PlanSellingPoint[] => {
    const isFamily = plan?.name.toLowerCase().includes('family')
    const isPlus = plan?.name.toLowerCase().includes('plus')

    if (isPlus) {
      return [
        { title: 'Unlimited Devices & Bandwidth', icon: PlanCheckMarkIcon },
        { title: 'Malware Blocking', icon: PlanCheckMarkIcon },
        { title: 'Ad Blocking', icon: PlanCheckMarkIcon },
        { title: 'Unrestricted Streaming', icon: PlanCheckMarkIcon },
        { title: 'Priority Phone Support', icon: PlanCheckMarkIcon },
        { title: 'SSN Monitoring with Real-Time Alerts', icon: PlanCheckMarkIcon },
      ]
    } else {
      return [
        { title: '60 Day Risk Free Guarantee', icon: PlanCheckMarkIcon },
        { title: '24/7 Support', icon: PlanCheckMarkIcon },
        { title: 'Unlimited Devices', icon: PlanCheckMarkIcon },
        { title: 'Full Access', status: 'success' },
        { title: isFamily ? '5 users' : '1 user' },
      ]
    }
  }

  const checkoutUrl = (plan: Plan) => Router.qs.coupon ? `/checkout/${plan.id}?coupon=${Router.qs.coupon}` : `/checkout/${plan.id}`

  const footerForPlan = (plan: Plan) => (
    <SimpleBox pt={6} pl={6} pr={6}>
      <Button
        as="a"
        href={checkoutUrl(plan)}
        color="red"
        size="huge"
        fluid
      >
        Try FREE for {plan.trialPeriod} days
      </Button>
      <div className="text text--subdued text--x-small mt-1">
        All prices are shown in USD
      </div>
    </SimpleBox>
  )

  return (
    <>
      {plans && (
        <div className={styles.Main}>
          {plans.map(p => (
            <Single
              key={p.id}
              plan={p}
              asPair={true}
              points={pointsForPlan(p)}
              footer={footerForPlan(p)}
              coupon={coupon}
            />
          ))}
        </div>
      )}
    </>
  )
}
export { Pair }
