import React, {VFC} from 'react'
import {SimpleForm} from '../../../SimpleForm'

const styles = require('./FormTextField.module.scss')

type Props = {
  label: string
  value: string | null
  onChange: (value: string) => void
  pattern?: string
  type?: string
  required?: boolean
}

const FormTextField:VFC<Props> = ({label, value, onChange, pattern, type, required}) => (<div className={styles.container}>
  <div className={styles.label}>{label}</div>
  <div className={styles.input}>
    <SimpleForm.Input
      pattern={pattern}
      type={type || 'text'}
      value={value}
      onChange={(d: any) => onChange(d.value)}
      size="small"
      required={required}
      fluid
      showValidation={true}
    />
  </div>
</div>
)

export { FormTextField }
