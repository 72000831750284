import React, {FC} from 'react'
import {Button, Image } from 'semantic-ui-react'
import { SimpleBox } from '../SimpleBox'

const onboardingImage = require('../../assets/images/identity-setup-onboarding.png')

const styles = require('./Onboarding.module.scss')

type Props = {
  onBeginSetup: () => void
}

const Onboarding:FC<Props> = ({ onBeginSetup }) => (
  <SimpleBox className={styles.onboarding}>
    <Image src={onboardingImage} />
    <h5 className={styles.title}>See which sites are exposing your private information</h5>
    <Button primary={true} size="large" onClick={onBeginSetup}>
        Set up
    </Button>
  </SimpleBox>
)

export { Onboarding }
