import React, { FC, useEffect } from 'react'
import { Header } from 'semantic-ui-react'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleLayout } from '../components/SimpleLayout'
import { useNavigation } from '../hooks/useNavigation'
import {Onboarding} from '../components/DataRemoval/Onboarding'
import {Setup} from '../components/DataRemoval/Setup'
import {DataRemovalContextProvider} from '../components/DataRemoval/context'
import {Dashboard} from '../components/DataRemoval/Dashboard'
import {gql, useQuery} from '@apollo/client'
import {SetupCompleted} from '../components/DataRemoval/SetupCompleted'

const ProfilesQuery = gql`query privacy_profiles {
    me {
        privacyProfiles {
            id
        }
    }
}`

const PersonalDataRemoval: FC = () => {
  const {data, loading} = useQuery(ProfilesQuery, {fetchPolicy: 'network-only'})
  const [mode, setMode] = React.useState<'onboarding' | 'setup' | 'setup-completed' | 'dashboard' | 'loading'>('loading')
  const { setNav, setSide } = useNavigation()

  useEffect(() => {
    setNav('hidden')
  }, [])

  useEffect(() => {
    if (mode === 'setup') {
      return
    }

    if (loading) {
      setMode('loading')
    } else if (data.me.privacyProfiles.length === 0) {
      setMode('onboarding')
    } else {
      setMode('dashboard')
    }
  }, [data])

  const handleGoBack = () => {
    if (data.me.privacyProfiles.length === 0) {
      setMode('onboarding')
    } else {
      setMode('dashboard')
    }
    setNav('hidden')
    setSide('full')
  }

  const markupSetup = <Setup onSetupComplete={() => {
    setMode('setup-completed')
  }} handleGoBack={handleGoBack}/>


  const markupOther = <SimpleLayout>
    <SimpleBox className="personal-data-removal">
      {mode !== 'setup-completed' && (<Header as="h4">Data Removal</Header>)}
      {mode === 'onboarding' && <Onboarding onBeginSetup={() => setMode('setup')} />}
      {mode === 'dashboard' && <Dashboard setupPressed={() => {
        setMode('setup')
      }} />}
      {mode === 'setup-completed' && (<SetupCompleted onViewProtectionClicked={() => {
        setMode('dashboard')
      }} />)}
      {mode === 'loading' && <div>Loading...</div>}
    </SimpleBox>
  </SimpleLayout>

  return (
    <DataRemovalContextProvider>
      {mode === 'setup' ? markupSetup : markupOther}
    </DataRemovalContextProvider>
  )
}

PersonalDataRemoval.displayName = 'PersonalDataRemoval'

export default PersonalDataRemoval
