import React, { FC } from 'react'
import { Button, Image, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../../components/SimpleBox'

type Props = { onContinue?: () => void }
const Success: FC<Props> = ({ onContinue }) => (
  <>
    <Modal.Header>
      <h6 className="title">Change Password</h6>
    </Modal.Header>
    <Modal.Content>
      <SimpleBox textAlign="center">
        <SimpleBox display="inline-flex">
          <Image src={require('~assets/images/success.png')} height={200} />
        </SimpleBox>
        <h4>Password changed!</h4>
        <br />
        <br />
        <SimpleBox maxWidth={300} mx="auto">
          <Button size="huge" fluid primary onClick={onContinue}>
              Back to Settings
          </Button>
        </SimpleBox>
      </SimpleBox>
      <br />
    </Modal.Content>
  </>
)
export { Success }
