import {ICardProps} from './ICardProps'
import {useSiteSetting} from '../../hooks/useSiteSetting'
import {AuthorizeNetCard} from './AuthorizeNetCard'
import {Card} from './Card'
import React, {FC} from 'react'

type Props = {
  forceAuthorize?: boolean
}

export const CardComponent: FC<ICardProps & Props> = ({ forceAuthorize, ...rest }) => {
  const authorizeSetting = useSiteSetting('AUTHORIZE_ENABLED')

  if (authorizeSetting.loading) {
    return <></>
  }

  const authorizeEnabled = forceAuthorize === true || authorizeSetting.enabled
  return authorizeEnabled ? (<AuthorizeNetCard {...rest} />) : <Card {...rest} />
}
