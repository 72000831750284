import { gql, useQuery } from '@apollo/client'
import { action } from 'mobx'
import React, { VFC, useState, useEffect } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../../components/SimpleBox'
import { SimpleText } from '../../../components/SimpleText'
import Mutation from '../../../shared/mutation'
import Notifications from '../../../shared/notifications'
import { PaymentSource } from '../../../type'
import { PaymentSourceCell } from '../../PaymentSourceCell'
import { PaymentStatus } from '../../PaymentStatus'

const iconMore = require('../../../assets/images/icons/icon-more.svg')

type Props = {
  onAddMorePressed: () => void
  onDeletePressed: (paymentSource: PaymentSource) => void
}

type PaymentRoleResult = {
  updatePaymentRole: boolean
}

const PaymentMethods: VFC<Props> = ({ onAddMorePressed, onDeletePressed }) => {
  const paymentRoleMutation = new Mutation<PaymentRoleResult>(`
    mutation updatePaymentRole($input: UpdatePaymentRoleInput!) {
      updatePaymentRole(input: $input)
    }
  `)

  const { data } = useQuery(
    gql`
      query {
        account {
          id
          customer {
            primaryPaymentSource {
              id
              type
              card
              paypal
            }
            paymentSources {
              id
              type
              card
              paypal
            }
          }
        }
      }
    `
  )

  const paymentSources: PaymentSource[] = data?.account?.customer?.paymentSources
  const [primaryPaymentSource, setPrimaryPaymentSource] = useState<PaymentSource>(data?.account?.customer?.primaryPaymentSource)
  const [selectedPaymentSource, setSelectedPaymentSource] = useState<PaymentSource>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setSelectedPaymentSource(primaryPaymentSource)
  }, [primaryPaymentSource])

  useEffect(() => {
    setPrimaryPaymentSource(data?.account?.customer?.primaryPaymentSource)
  }, [data?.account?.customer?.primaryPaymentSource])

  const handleChangePrimaryPaymentSource = () => {
    if (selectedPaymentSource) {
      setLoading(true)

      paymentRoleMutation.exec({ input: { paymentSourceId: selectedPaymentSource.id, role: 'primary' }}).then(
        action(() => {
          setLoading(false)
          if (paymentRoleMutation.data?.updatePaymentRole) {
            setPrimaryPaymentSource(selectedPaymentSource)
            Notifications.success('Payment method was updated')
          } else {
            Notifications.error('Unable to update payment method')
          }
        }))
    }
  }

  return <>
    <Modal.Header className="payment-methods-header">
      <SimpleText size="large" weight="bold">Payment method</SimpleText>
    </Modal.Header>
    <Modal.Content className="white">
      <SimpleBox className="payment-methods-status" paddingBottom="35px">
        <PaymentStatus />
      </SimpleBox>
      <SimpleText size="small" className="subdued">Choose</SimpleText>
      <SimpleBox paddingTop="10px" paddingBottom="5px">
        {paymentSources?.map(value => <SimpleBox paddingBottom="8px" key={value.id}><PaymentSourceCell
          paymentSource={value}
          onDelete={value.id !== primaryPaymentSource?.id && paymentSources.length > 1 ? onDeletePressed : undefined}
          onSelected={setSelectedPaymentSource}
          selected={value.id === selectedPaymentSource?.id} /></SimpleBox>)}
      </SimpleBox>
      <a onClick={onAddMorePressed} className="payment-methods-add-more"><img src={iconMore} /> Add payment method</a>
      <SimpleBox paddingTop="20px" display="flex" justifyContent="center">
        <Button
          disabled={selectedPaymentSource?.id === primaryPaymentSource?.id}
          onClick={handleChangePrimaryPaymentSource}
          content="Update payment method"
          color="blue"
          size="huge"
          primary
          loading={loading}
        />
      </SimpleBox>
    </Modal.Content>
  </>
}

export { PaymentMethods }
