import React, {FC, useEffect} from 'react'
import { SimpleBox } from '../SimpleBox'
import {useNavigation} from '../../hooks/useNavigation'
import {PersonalInformation} from './Setup/PersonalInformation'
import {EmailAdresses} from './Setup/EmailAddresses'
import {PhoneNumbers} from './Setup/PhoneNumbers'
import {Addresses} from './Setup/Addresses'
import { BackButton } from '../BackButton/BackButton'

const styles = require('./Setup.module.scss')

type Props = {
  onSetupComplete: () => void
  handleGoBack: () => void;
}

interface Step {
    title: string
    component: (next: () => void) => JSX.Element
}

const Steps: Step[] = [
  {
    title: 'Personal Information',
    component: next => <PersonalInformation onStepComplete={next} />
  },
  {
    title: 'Email Addresses',
    component: next => <EmailAdresses onStepComplete={next} />
  },
  {
    title: 'Phone Numbers',
    component: next => <PhoneNumbers onStepComplete={next} />
  },
  {
    title: 'Addresses',
    component: next => <Addresses onStepComplete={next} />
  },
]

const Setup:FC<Props> = ({onSetupComplete, handleGoBack}) => {
  const { setNav, setSide } = useNavigation()
  const [step, setStep] = React.useState<Step>(Steps[0])

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
  }, [])

  return (
    <SimpleBox className={styles.setup}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <BackButton onBackPressed={handleGoBack} className={styles.backButton}/>
          <h5>Personal Data Removal</h5>
          <div className={styles.stepsContainer}>
            {Steps.map((s, i) => (
              <div onClick={() => { setStep(s) }} key={i} className={s === step ? styles.stepActive : styles.step}>
                <div className={styles.stepNumber}>{i+1}</div>
                <div className={styles.stepTitle}>{s.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {step.component(() => {
          const index = Steps.indexOf(step)
          if (index === Steps.length - 1) {
            onSetupComplete()
          } else {
            setStep(Steps[index + 1])
          }
        })}
      </div>
    </SimpleBox>
  )
}

export { Setup }
