import React, {VFC, useMemo, useState} from 'react'
import {Button} from 'semantic-ui-react'
import {Plan} from '../../type'
import {toCouponPrice, toMoney} from '../../shared/format'
import {gql, useQuery} from '@apollo/client'
import {Coupon} from '../../utils/coupon'
import {Item} from '../../shared/types/chargebee'

const styles = require('./upsell.module.scss')
const tickGreen = require('../../assets/images/tick-green-round.svg')

const QUERY = gql`
  query($currency: String) {
    plans(currency: $currency, upgrade: true) {
      id
      name: externalName
      price
      period
      periodUnit
      item { name featuresList }
    }
  }
`

type PlanOption = Pick<Plan, 'id' | 'name' | 'period' | 'periodUnit' | 'price'> &
  { item: Pick<Item, 'name' | 'featuresList'> }

type UpsellProps = {
  currentPlan: Plan
  onUpgrade: (newPlan: PlanOption) => void
  coupon?: Coupon
}

/**
 * Upsell component to render an upsell offer for a user to upgrade to a new plan.
 *
 * @param currentPlan
 * @param onUpgrade
 * @constructor
 */
const Upsell: VFC<UpsellProps> = ({currentPlan, onUpgrade, coupon}) => {
  const { data } = useQuery<{ plans: PlanOption[] }>(QUERY, { variables: { currency: currentPlan.currencyCode } })
  const suggestedPlan: PlanOption | undefined = useMemo(() => {
    if (!data) {
      return
    }

    const currentPrice = parseFloat(currentPlan.price)
    const options = data.plans.filter(plan => (
      plan.period === currentPlan.period &&
        plan.periodUnit === currentPlan.periodUnit &&
        parseFloat(plan.price) > currentPrice
    )).sort((a, b) => parseFloat(a.price) - parseFloat(b.price))

    return options.at(0)
  }, [data])

  /// If we don't have a suggested plan then bail out
  if (!suggestedPlan) {
    return null
  }

  /// Calculate the difference in price between the current plan and the suggested plan
  const difference = toCouponPrice(parseFloat(suggestedPlan.price), coupon) - toCouponPrice(parseFloat(currentPlan.price), coupon)

  // Calculates the extra monthly price
  const { name, featuresList } = suggestedPlan.item!
  const monthlyPrice = suggestedPlan.periodUnit === 'MONTH' ? difference / suggestedPlan.period :
    difference / (suggestedPlan.period * 12)

  return (<div className={styles.Container}>
    <span className={styles.Headline}>Would you like to upgrade to {name} for only {toMoney(monthlyPrice)}/mo more?</span>
    {featuresList?.slice(3, 6)?.map((feature, index) => (
      <span className={styles.Text} key={index}>
        <img src={tickGreen} className={styles.Tick} /> {feature.split(' / ')[0]}
      </span>
    ))}
    <Button size={'large'} color={'red'} onClick={() => onUpgrade(suggestedPlan)} className={styles.Button} primary>Upgrade</Button>
  </div>)
}


export { Upsell }
