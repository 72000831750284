import { createContext } from 'react'
import { Provider } from './Provider'

export type NavigationDisplay = 'full' | 'simple' | 'hidden' | 'no-sign-in'
export type SideBarDisplay = 'full' | 'hidden'

type State = {
  navigation: NavigationDisplay
  sidebar: SideBarDisplay
  setNav: (display: NavigationDisplay, navClassNames?: string) => void
  setSide: (display: SideBarDisplay) => void
  resetNav: () => void
  resetSide: () => void
}

const Context = createContext<State>({
  navigation: 'full',
  sidebar: 'full',
  setNav: () => undefined,
  setSide: () => undefined,
  resetNav: () => undefined,
  resetSide: () => undefined
})

export { Context, Provider }
