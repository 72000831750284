import { useQuery, gql } from '@apollo/client'
import React, { FC } from 'react'
import { Plan } from '../../../../type'
import { Form } from 'semantic-ui-react'
import { SimpleText } from '../../../SimpleText'
import { SimpleBox } from '../../../SimpleBox'
import { toMoney } from '~shared/format'
import { toPlanPeriod } from '../../../../utils'
import { Coupon } from '../../../../utils/coupon'
import { toCouponPrice } from '../../../../shared/format'
import { Item } from '../../../../shared/types/chargebee'

const styles = require('./Selection.module.scss')

type PlanOption = Pick<Plan, 'id' | 'name' | 'period' | 'periodUnit' | 'price'> &
  { item: Pick<Item, 'metadata' | 'users'> }

type Props = {
  plan: Plan
  coupon?: Coupon
  expanded?: boolean
  onChangePlan?: (plan: PlanOption) => void
}

/**
 * Generates a list of plans that the user can select
 * @param plans A list of plans
 * @param isFamily If current plan is a family plan
 * @param productSlug The product slug of the current plan
 * @returns
 */
const FilteredPlans = (plans: PlanOption[], isFamily: boolean, productSlug: string): PlanOption[] => plans
  .filter((value: PlanOption) => value.item?.metadata !== null)
  .filter((value: PlanOption) => typeof(value.item?.metadata) === 'object')
  .filter((value: PlanOption) => value.item!.metadata!.is_family === isFamily)
  .filter((value: PlanOption) => value.item!.metadata!.product === productSlug)
  .filter(value => [1, 12, 24].includes(value.period))

/**
 * Calcuates a title for the plan to use as option text
 * @param plan The plan to calculate a title for
 * @returns
 */
const PlanOptionTitle = (plan: PlanOption): string => {
  if (plan.periodUnit === 'MONTH' && plan.period >= 12) {
    const years = plan.period / 12
    return `${years} year${years === 1 ? '' : 's'}`
  }

  return `${plan.period} ${plan.periodUnit === 'MONTH' ? 'month' : 'year'}${plan.period === 1 ? '' : 's'}`
}

/**
 * Renders a selection box where user cna change plan in the checkout flow
 *
 * @param plan Then selected plan
 * @param onChangePlan A callback to call once user selects a different plan
 * @returns
 */
const Selection: FC<Props> = ({ plan, onChangePlan, coupon, expanded}) => {
  const { data } = useQuery<{ plans: PlanOption[] }>(gql`
    query($itemId: ID!, $currency: String) {
      plans(itemId: $itemId, currency: $currency) {
        id name: externalName period periodUnit price item { users metadata }
      }
    }
  `, { variables: { itemId: plan.item?.id, currency: plan.currencyCode } })

  const isFamily = plan.item?.metadata?.is_family || false
  const productSlug = plan.item?.metadata?.product || ''
  const filteredPlans: PlanOption[] = FilteredPlans(data?.plans || [], isFamily, productSlug)
  const planOptions = filteredPlans.map(value => ({key: value.id, value: value.id, text: PlanOptionTitle(value)}))
  const oneMonthPlan = filteredPlans.find(value => value.period === 1 && value.periodUnit === 'MONTH')

  const totalPrice = () => toCouponPrice(parseFloat(plan.price), coupon)
  const displayUsers = (value?: number) => value && value > 1 ? `${value} users` : '1 user'

  const callPlanChange = (_ev: any, { value }: { value: string }) => {
    const newPlan = filteredPlans?.find(entry => entry.id === value)
    newPlan && onChangePlan?.(newPlan)
  }

  const savings = (price: number, item: PlanOption) => {
    const period = (item.periodUnit === 'YEAR') ? item.period * 12 : item.period
    const total = parseFloat(oneMonthPlan?.price || '0') * period

    return Math.round(((total - toCouponPrice(price, coupon)) / total) * 100)
  }

  const expandedMarkup = () => (filteredPlans.map(item => {
    const price = parseFloat(item.price)
    const savingAmount = savings(price, item)

    return (
      <div key={item.id}
        onClick={() => { onChangePlan?.(item) }}
        className={`${styles.expandedContainer} ${item.id === plan.id ? styles.active : styles.inactive}`}>
        <div className={styles.expandedPlanInfo}>
          {savingAmount > 0 && (
            <div className={styles.expandedPlanInfoSavings}>Save an extra {savingAmount}% with this plan!</div>
          )}
          <div className={styles.expandedPlanInfoTitle}>
            {item.name}
          </div>
          <div>
            {displayUsers(item.item?.users)} - Billed {toPlanPeriod(item)} at {toMoney(toCouponPrice(price, coupon))}
          </div>
        </div>
        <div className={styles.price}>
          {toMoney(toCouponPrice(price / item.period, coupon))}/mo
        </div>
      </div>
    )
  }))

  return (<SimpleBox textAlign="left" marginTop={`${expanded ? '' : '10px'}`} className="plan-selection">
    {expanded && expandedMarkup()}
    {!expanded && (
      <>
        <SimpleText className="billed">Billed</SimpleText>
        <Form.Dropdown
          fluid
          selection
          required
          value={plan.id}
          onChange={callPlanChange}
          options={planOptions}
        />
        <SimpleBox className="description">
          {displayUsers(plan.item?.users)} - Billed {toPlanPeriod(plan)} - {toMoney(totalPrice())}
        </SimpleBox>
      </>
    )}
  </SimpleBox>)
}

export { Selection }
