import React, {VFC} from 'react'
import {Button, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../components/SimpleBox'
import {toMoney} from '../../shared/format'

const chekmarkIcon = require('../../assets/images/icon-charkmark-roundbg.png')

type CancelledStepProps = {
  onNext: () => void;
  refundAmount: number
}

const CancelledStep: VFC<CancelledStepProps> = ({onNext, refundAmount}) => {
  const handleClick = () => {
    onNext()
  }

  const refundAmountMarkup = <SimpleBox flexDirection={'row'} >
    <SimpleBox color={'#787E8D'} fontWeight={400} fontSize={14} as={'p'} marginRight={10}>Refund amount: </SimpleBox>
    <SimpleBox color={'#1AB233'} fontWeight={400} fontSize={14} as={'p'}>{toMoney(refundAmount/100.0)}</SimpleBox>
  </SimpleBox>

  return (<>
    <Modal.Content>
      <SimpleBox display="flex" justifyContent="center" alignItems="center" flexDirection={'column'} padding={55}>
        <img src={chekmarkIcon} />
        <SimpleBox as={'p'} color={'#000000'} fontWeight={700} fontSize={30} textAlign={'center'} marginTop={20} maxWidth={343}>Subscription cancelled</SimpleBox>
        <SimpleBox as={'p'}
          color={'#787E8D'}
          fontWeight={400}
          fontSize={14}
          textAlign={'center'}
          maxWidth={343}
          marginTop={10}>Your subscription will be downgraded to our free plan (no access).</SimpleBox>
      </SimpleBox>
      <SimpleBox paddingTop="20px" display="flex" flexDirection={'column'} gap={20} alignItems={'center'} justifyContent="center">
        {refundAmount > 0 && refundAmountMarkup}
        <Button
          onClick={handleClick}
          content="Back to Overview"
          size="big"
          primary
        />
      </SimpleBox>
    </Modal.Content></>
  )
}

export {CancelledStep}
