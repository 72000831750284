import { gql, useMutation } from '@apollo/client'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, FormField, Input, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../../components/SimpleBox'
import { SimplePasswordInput } from '../../../components/SimplePasswordInput/SimplePasswordInput'
import Session from '../../../shared/storages/session'

type Props = { onContinue?: (type: 'success' | 'email', payload?: any) => void }
const Password: FC<Props> = ({ onContinue }) => {
  const profile = Session.profile

  const [updatePassword, { loading, error, data }] = useMutation(
    gql`
      mutation($input: PasswordChangeInput!) {
        changePassword(input: $input)
      }
    `
  )

  const [resetPassword, { loading: resetLoading }] = useMutation(
    gql`
      mutation($email: String!) {
        forgotPassword(email: $email)
      }
    `
  )


  const [oldPassword, setOldPassword] = useState<string>('')
  const [{ password, strength }, setPasswordAndStrength] = useState<{ password: string | undefined; strength: number }>(
    {
      password: '',
      strength: 0,
    }
  )

  const [hasError, setHasError] = useState(false)

  const onUpdatePassword = async () => {
    await updatePassword({
      variables: { input: { newPassword: password, currentPassword: oldPassword } },
    })
  }

  const onResetPassword = useCallback(async () => {
    await resetPassword({ variables: { email: profile?.email } })
    onContinue!('email')
  }, [])

  useEffect(() => {
    if (!data) { return }
    if (data.changePassword) { onContinue!('success') } else if (error) { setHasError(true) } else { setHasError(true) }
  }, [data])

  return (
    <>
      <Modal.Header className="white">
        <h6 className="title">Change Password</h6>
      </Modal.Header>
      <Modal.Content className="white">
        <SimpleBox maxWidth={360} mx="auto">
          <FormField>
            <label htmlFor="old-password">Old Password</label>
            <Input
              id="old-password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              fluid
              type="password"
              error={hasError}
              size="large"
            />
            {hasError && (
              <SimpleBox mt={1} display="flex" alignItems="center" justifyContent="space-between">
                <div className="text red-text">Don’t remember your password?</div>
                <Button primary className="subdued" onClick={onResetPassword} loading={resetLoading}>
                  Reset
                </Button>
              </SimpleBox>
            )}
          </FormField>
          <br />
          <FormField>
            <label>New Password</label>
            <SimplePasswordInput
              value={password}
              onChange={(p, s) => {
                setPasswordAndStrength({ password: p, strength: s })
              }}
              size="large"
              fluid
            />
          </FormField>
        </SimpleBox>
        <SimpleBox mt={7} mb={4} maxWidth={300} mx="auto">
          <Button size="huge" fluid primary onClick={onUpdatePassword} disabled={strength < 2} loading={loading}>
            Confirm
          </Button>
        </SimpleBox>
      </Modal.Content>
    </>
  )
}
export { Password }
