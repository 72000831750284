import React, {VFC} from 'react'
import {Button, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../components/SimpleBox'

const headphonesIcon = require('../../assets/images/icon-headphones.png')

type Props = {
  onNext: () => void;
}

const CancelSupportStep: VFC<Props> = ({onNext}) => {
  /// Opens the chatra live chat
  const handleTryLiveChat = () => {
    setTimeout(() => {
      // @ts-ignore
      window.fcWidget.open()
    }, 100)
  }

  /// Initiates a call
  const handleCallUs = () => {
    window.open('https://calendly.com/d/drs-2s3-dhy/virtualshield-vpn-phone-support-schedule')
  }

  return (<><Modal.Header>
    <SimpleBox display="flex" justifyContent="center" alignItems="center" flexDirection={'column'}>
      <SimpleBox as={'p'} color={'#000000'} fontWeight={700} fontSize={30} textAlign={'center'} marginTop={55} maxWidth={343}>We&rsquo;re so sorry!</SimpleBox>
      <SimpleBox as={'p'} color={'#000000'} fontWeight={700} fontSize={30} textAlign={'center'} maxWidth={343}>We&rsquo;d love to help you stay protected.</SimpleBox>
    </SimpleBox>
  </Modal.Header>
  <Modal.Content>
    <SimpleBox as={'p'} color={'##787E8D'} fontWeight={400} fontSize={16} textAlign={'center'}>We&rsquo;ve got LIVE customer representatives available 24/7.</SimpleBox>
    <SimpleBox backgroundColor={'#F5F6F9'} padding={15} borderRadius={18} marginTop={49} marginBottom={41} display={'flex'} flexDirection={'row'} >
      <img src={headphonesIcon} />
      <SimpleBox display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={15}>
        <SimpleBox as={'p'} color={'#000000'} fontWeight={600} fontSize={'16px'} textAlign={'left'}>Click to call or chat with our Customer Representative!</SimpleBox>
        <Button
          onClick={handleTryLiveChat}
          content="Try live chat"
          size="large"
          secondary
        />
        <Button
          onClick={handleCallUs}
          content="Call us"
          size="large"
          secondary
        />
      </SimpleBox>
    </SimpleBox>
    <SimpleBox as={'p'} color={'##787E8D'} fontWeight={400} fontSize={16} textAlign={'center'}>I still want to cancel my subscription</SimpleBox>
    <SimpleBox paddingTop="20px" display="flex" justifyContent="center" gap={20}>
      <Button
        onClick={onNext}
        content="Cancel my subscription"
        size="big"
        secondary
      />
    </SimpleBox>
  </Modal.Content></>
  )
}

export {CancelSupportStep}
