import React, { FC, useEffect, useState } from 'react'
import { Form, Modal } from 'semantic-ui-react'
import { BankAccountsForm, ContactInformationForm, CreditCardsForm, DocumentsForm } from '../../components/IdentitySecurity'
import { IdentitySecurityHeader } from '../../components/IdentitySecurity/header/'
import { VerificationModal } from '../../components/IdentitySecurity/verification/VerificationModal'
import { SimpleBox } from '../../components/SimpleBox'
import { useNavigation } from '../../hooks/useNavigation'

const DarkWebMonitoring: FC = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()
  const [modal, setModal] = useState<'verification' | undefined>(undefined)

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')

    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  const handleSave = () => {
    setModal('verification')
    //Router.redirect('/identity-security')
  }

  return (<>
    <Modal className="identity-security-modal" closeIcon size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)}>
      <VerificationModal />
    </Modal>
    <IdentitySecurityHeader selectedTab={'dark-web-monitoring'} />
    <Form id="dark-web-monitoring" onSubmit={handleSave}>
      <SimpleBox className="identity-security-body">
        <ContactInformationForm />
        <CreditCardsForm />
        <BankAccountsForm />
        <DocumentsForm />
        <Form.Button size="large" primary>Save and Continue</Form.Button>
      </SimpleBox>
    </Form></>)
}

DarkWebMonitoring.displayName = 'DarkWebMonitoring'

export default DarkWebMonitoring
