import React, { FC, useState } from 'react'
import { MaskedNumberInput, FormContent, FormHeader } from '.'

const CreditCardsForm: FC = () => {
  const [creditCards, setCreditCards] = useState<string[]>([''])

  const handleAddNewCard = () => {
    setCreditCards([...creditCards, ''])
  }

  const handleOnChangeCreditCardNumber = (index: number, creditCardNumber: string) => {
    setCreditCards([
      ...creditCards.slice(0, index),
      creditCardNumber,
      ...creditCards.slice(index + 1)
    ])
  }

  return (<div className="identity-security-form">
    <FormHeader
      image={require('../../../assets/images/identity-security/creditcards.svg')}
      title="Credit & Debit Cards"
      text="Please fill in your credit & debit cards"
    />
    <FormContent>
      {creditCards.map((creditCardNumber, index) => <MaskedNumberInput
        key={index} value={creditCardNumber}
        title="Credit Card Number"
        onChange={(changedCreditCardNumber: string) => {
          handleOnChangeCreditCardNumber(index, changedCreditCardNumber)
        }} />)}
      <a className="add-new-link" onClick={handleAddNewCard}>+ Add more</a>
    </FormContent>
  </div>)
}

CreditCardsForm.displayName = 'CreditCardsForm'

export { CreditCardsForm }
