import React, { FC, useState } from 'react'
import { FormContent, FormHeader } from '.'
import { PhoneNumber, PhoneWithPrefixInput } from './inputs/PhoneWithPrefixInput'
import { EmailInput } from './inputs/EmailInput'

const ContactInformationForm: FC = () => {
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([{prefix: '', number: ''}])
  const [emails, setEmails] = useState<string[]>([''])

  const handleOnChangePhoneNumber = (index: number, phoneNumber: PhoneNumber) => {
    setPhoneNumbers([
      ...phoneNumbers.slice(0, index),
      phoneNumber,
      ...phoneNumbers.slice(index + 1)
    ])
  }

  const handleOnChangeEmail = (index: number, email: string) => {
    setEmails([
      ...emails.slice(0, index),
      email,
      ...emails.slice(index + 1)
    ])
  }

  const handleAddNewPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, {prefix: '', number: ''}])
  }

  const handleAddNewEmail = () => {
    setEmails([...emails, ''])
  }

  return (<div className="identity-security-form">
    <FormHeader
      image={require('../../../assets/images/identity-security/contact.svg')}
      title="Contact Information"
      text="Please fill in your contact infirmation"
    />
    <FormContent>
      {phoneNumbers.map((phoneNumber, index) => <PhoneWithPrefixInput
        key={index} phoneNumber={phoneNumber}
        onChange={(changedPhoneNumber: PhoneNumber) => {
          handleOnChangePhoneNumber(index, changedPhoneNumber)
        }} />)}
      <a className="add-new-link" onClick={handleAddNewPhoneNumber}>+ Add more</a>
      <hr />
      {emails.map((_email, index) => <EmailInput
        key={index} email={_email}
        onChange={(email: string) => {
          handleOnChangeEmail(index, email)
        }} />)}
      <a className="add-new-link" onClick={handleAddNewEmail}>+ Add more</a>
    </FormContent>
  </div>)
}

ContactInformationForm.displayName = 'ContactInformationForm'

export { ContactInformationForm }
