import React, {useState, FC} from 'react'
import { Form, Modal } from 'semantic-ui-react'
import { SimpleForm } from '../../../SimpleForm'
import { BirthdayInputModal } from './BirthdayModal'
import moment from 'moment'

const birthdateValidator = new RegExp('([0-9]{2})\/([0-9]{2})\/([0-9]{4})')

type Props = {
  birthdate: Date
  onBirthdateChanged: (date: Date) => void
}

const BirthdayInput: FC<Props> = ({birthdate, onBirthdateChanged}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const handleBirthdateChanged = (changedDate: Date) => {
    setModalOpen(false)
    onBirthdateChanged(changedDate)
  }

  return (
    <>
      <Modal className="identity-security-dateinput-modal" closeIcon open={modalOpen} onClose={() => setModalOpen(false)}>
        <BirthdayInputModal birthDay={birthdate} onBirthdateChanged={handleBirthdateChanged} />
      </Modal>
      <Form.Field>
        <label>Date of Birth</label>
        <SimpleForm.Input
          fluid
          value={birthdate ? moment(birthdate).format('DD/MM/YYYY') : ''}
          required
          showValidation={birthdateValidator.test(birthdate ? moment(birthdate).format('DD/MM/YYYY') : '')}
          loading={false}
          onClick={() => setModalOpen(true) }
        />
      </Form.Field>
    </>
  )
}

export { BirthdayInput }
