import React, { FC } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'

const RenderNoAlerts: FC = () => (
  <SimpleCard className="mt-1">
    <SimpleBox display="flex" justifyContent="center" alignItems="center"><SimpleText>There are no alerts right now</SimpleText></SimpleBox>
  </SimpleCard>
)

export { RenderNoAlerts }
