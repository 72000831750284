export default abstract class Storage {
  static readonly storage = window.localStorage

  static get(key: string): string | null {
    return this.storage.getItem(key)
  }

  static set(key: string, value: string): void {
    this.storage.setItem(key, value)
  }

  static clearItem(key: string): void {
    this.storage.removeItem(key)
  }

  static clearItems(keys: string[]): void {
    keys.forEach(key => this.clearItem(key))
  }
}
