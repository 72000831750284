import {gql, useQuery} from '@apollo/client'

export const useSiteSetting = (key: string) => {
  const { data, loading } = useQuery(gql`
        query {
          siteSettings {
              key
              enabled
          }
        }
    `)

  return {
    enabled: data?.siteSettings.find((s: any) => s.key === key)?.enabled,
    loading,
  }
}
