import React, { FC, useEffect } from 'react'
import { SimpleBox } from '../components/SimpleBox'
import { IPStatusBox } from '../components/IPStatusBox'
import { Container, Header, Image } from 'semantic-ui-react'
import { useNavigation } from '../hooks/useNavigation'

const globe = require('~assets/images/ip-status/globe.svg')
const hub = require('~assets/images/ip-status/hub.svg')

const IPStatus: FC & { authorize: boolean } = () => {
  const { setSide, resetSide } = useNavigation()

  useEffect(() => {
    setSide('hidden')
    return () => {
      resetSide()
    }
  }, [])

  return (
    <SimpleBox className="ipstatus" mt={18} pb={12}>
      <Header as="h1" className="title-1">Online privacy<br/> and security checker</Header>

      <IPStatusBox />

      <section className="what-address">
        <div className="text">
          <Header style={{ position: 'inline-block' }} as="h5">What is an IP address?</Header>
          <p>
            The term IP address stands for Internet Protocol address.
            An Internet Protocol address is a unique numerical name that
            every electronic device connected to a computer network has.
            You can think of an IP address as being similar to your home address.
            It is specific to that particular device and serves as a way to
            identify and locate these devices. There are currently two types
            of IP addresses used: IPv4 and IPv6.
          </p>
        </div>
        <div className="image">
          <Image src={hub} height={540} width={540} style={{ position: 'inline-block' }}/>
        </div>
      </section>

      <section className="what-ip">
        <div className="image">
          <Image src={globe} height={360} width={360} style={{ position: 'inline-block' }}/>
        </div>
        <div className="text">
          <Header style={{ position: 'inline-block' }} as="h5">What is an IP address?</Header>
          <p>
            Anytime you visit a website, it can potentially
            gather even more information about you.
            By combining your IP address with other information
            gleaned from metadata, cookies, trackers, and
            browser-fingerprinting tactics, website owners,
            marketers, and advertisers can build quite
            a thorough profile about you.
            They can piece together your location,
            what websites you’re visiting, what you’re
            interested in, what youre downloading,
            and who you’re talking to, and then present
            you with targeted content and advertisements—or
            sell your data to the highest bidder.
          </p>
        </div>
      </section>

      <section className="everyone-online">
        <Container>
          <Header style={{ position: 'inline-block' }} as="h3">
              Everyone online has a public IP address
          </Header>
          <div className="texts">
            <div>
                To use the internet, all connected devices must
                have a public IP address. An IP address allows
                two devices—the sender and recipient of internet
                communications—to find and exchange information
                with each other.
            </div>
            <div>
                The setup is similar to real-life address systems.
                For example, when you order an item from an online
                shop, the sender would need your address to send you
                (the recipient) your copies. Without your address,
                the distributors wouldn’t know where to send your package.
                The same applies to the internet. Without an IP address, two
                devices would not be able to discover and communicate with each other.
            </div>
          </div>
        </Container>
      </section>
    </SimpleBox>
  )
}

IPStatus.authorize = false
export default IPStatus
