import React, {FC, ReactNode, VFC} from 'react'
import { PlanSellingPoint } from '../../type'
import { SimpleBox } from '../SimpleBox'
import { Pair, Single, Trial } from './components'
import {Coupon} from '../../utils/coupon'
import {toMoney, toPercent} from '../../shared/format'

const tickGreen = require('../../assets/images/tick-green-round.svg')

const styles = require('./Plans.module.scss')

type Props = { side?: ReactNode; descriptions?: PlanSellingPoint[], coupon?: Coupon, onRemoveCoupon?: () => void }
type Comps = { Pair: typeof Pair; Single: typeof Single; Trial: typeof Trial }

const CouponApplied: VFC<{coupon: Coupon, onRemoveCoupon: () => void}> = ({coupon, onRemoveCoupon}) => (
  <SimpleBox className={styles.couponBox} backgroundColor={'#FFFFFF'} borderRadius={8} padding={15} marginRight={17} display={'flex'} flexDirection={'column'} gap={10}>
    <SimpleBox display={'flex'} flexDirection={'row'} alignItems={'flex-start'} gap={20}>
      <SimpleBox flex={1} flexDirection={'column'} gap={10}>
        <SimpleBox as={'p'} fontSize={'14px'} fontWeight={400} color={'#787E8D'}>
        Coupon code applied
        </SimpleBox>
        <SimpleBox as={'p'} fontSize={'16px'} fontWeight={600} color={'#787E8D'}>
          {coupon.name} <img src={tickGreen} style={{width: '22px', height: '22px', marginLeft: '10px'}} />
        </SimpleBox>
      </SimpleBox>
      <SimpleBox backgroundColor={'rgba(251, 0, 35, 0.1)'}
        borderRadius={8}
        paddingLeft={14}
        paddingRight={14}
        paddingTop={7}
        paddingBottom={7}>
        <a onClick={onRemoveCoupon} style={{color: '#FB0023', fontWeight: 400, fontSize: 14}}>Remove</a>
      </SimpleBox>
    </SimpleBox>
    <SimpleBox as={'p'} fontSize={'13px'} fontWeight={400} color={'#1AB233'}>
      Coupon successfully applied with {coupon.discountType === 'FIXED_AMOUNT' ? toMoney(coupon.discountAmount!) : toPercent(coupon.discountPercentage!)} discount
    </SimpleBox>
  </SimpleBox>
)

const Plans: FC<Props> & Comps = ({ children, side, descriptions, coupon, onRemoveCoupon }) => {
  const sideMarkup = side !== undefined && side
  const descriptionsMarkup = descriptions && (
    <SimpleBox>
      <Plans.Single.Points
        items={descriptions}
        description
        footer={<Trial trialEnabled={true} />}
      />
    </SimpleBox>
  )
  return (
    <div className={styles.Main}>
      <div className={styles.Container}>
        <div className={styles.Side}>
          <header>{sideMarkup}</header>
          {descriptionsMarkup}
          {coupon && <><SimpleBox flex={1} /><CouponApplied coupon={coupon} onRemoveCoupon={onRemoveCoupon!} /></>}
        </div>
        {children}
      </div>
    </div>
  )
}

Plans.Single = Single
Plans.Trial = Trial
Plans.Pair = Pair

export { Plans }
