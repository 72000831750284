import React, { FC } from 'react'
import { Modal, Image, Header, Button } from 'semantic-ui-react'
import { SimpleBox } from '../SimpleBox'

const issueIcon = require('../../assets/images/icons/issue.svg')

type Props = {
    onSaveClicked: () => void
    onDiscardClicked: () => void
}

const CancelModal: FC<Props> = ({onDiscardClicked, onSaveClicked}) => (
  <Modal.Content>
    <SimpleBox display="flex" height="300px" justifyContent="space-between" alignItems="center" flexDirection="column">
      <Image src={issueIcon} />
      <Header className="mt-2" as="h5" textAlign="center">
            Do you want to save these changes?
      </Header>
      <Button size="large" fluid primary onClick={onSaveClicked}>
            Save and leave
      </Button>
      <Button size="large" fluid secondary onClick={onDiscardClicked}>
            Leave without saving
      </Button>
    </SimpleBox>
  </Modal.Content>
)

export { CancelModal }
