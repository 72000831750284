import { gql, useMutation } from '@apollo/client'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, Form, Header, Image, Input } from 'semantic-ui-react'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleLayout } from '../components/SimpleLayout'
import { emailRegex } from '../const'
import { useNavigation } from '../hooks/useNavigation'
import Notifications from '../shared/notifications'
import {SimpleCard} from '../components/SimpleCard'
import Router from '../shared/router'

const AuthCover = require('~assets/images/auth-cover.png')
const Logo = require('~assets/images/logo-black.svg')
const CheckIcon = require('~assets/images/icons/tick-green.svg')

const ForgotPassword: FC & { authorize: boolean; auth: string } = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()

  const [email, setEmail] = useState('')
  const [completed, setCompleted] = useState(false)

  const [requestPasswordReset] = useMutation(
    gql`
      mutation($email: String!) {
        forgotPassword(email: $email)
      }
    `
  )

  const onSubmit = async () => {
    try {
      await requestPasswordReset({ variables: { email } })
      setCompleted(true)
    } catch (e) {
      Notifications.error(e.message)
    }
  }

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  const isEmailValid = emailRegex.test(email)
  const isReady = isEmailValid

  const invalidLinkMarkup = (
    <SimpleBox marginTop="20px" marginBottom="20px" color="black">
      <SimpleCard backgroundColor="#F6DDE3" rounded="subtile">The link is no longer valid. Please try again.</SimpleCard>
    </SimpleBox>
  )

  const formMarkup = (
    <>
      <Header as="h3">Reset Password</Header>
      <p style={{ paddingTop: '15px' }}>Please enter your email address and instructions will be sent to you</p>

      <SimpleBox mt={5}>
        <Form id="reset-password" onSubmit={onSubmit}>
          <Form.Field
            type="email"
            name="email"
            label="Email address"
            placeholder="name@example.com"
            size="large"
            control={Input}
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            icon={
              isEmailValid && (
                <Image
                  src={CheckIcon}
                  height={22}
                  style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }}
                />
              )
            }
          />
          <SimpleBox mt={6}>
            <Form.Button color="red" disabled={!isReady} size="huge" content="Continue" primary fluid />
          </SimpleBox>
        </Form>
      </SimpleBox>
    </>
  )

  const completedMarkup = (
    <>
      <SimpleBox maxWidth={440}>
        <SimpleBox marginLeft={-60}>
          <Image src={require('~assets/images/success.png')} height={232} />
        </SimpleBox>
        <h3>We’ve sent a link to your email address</h3>
        <div style={{ paddingTop: '25px' }}>
          Check your email for instructions on how to reset your password. Can’t find it? Try checking your spam or junk folder.
        </div>
        <br />
        <br />
        <Button as="a" href="/" secondary>
          Back to Login
        </Button>
      </SimpleBox>
    </>
  )

  return (
    <SimpleLayout>
      <SimpleLayout.Section oneThird>
        <SimpleBox p={'50px 60px'} height="100%" backgroundColor="#fff" className="forgot-password">
          <SimpleBox mb={5}>
            <Button as="a" type="button" icon="arrow left" href={'/'} />
          </SimpleBox>
          <SimpleBox mb={5}>
            <Image src={Logo} height={38} id="logo" />
          </SimpleBox>
          {Router.qs.error === 'invalid_token' ? invalidLinkMarkup : null}
          {completed ? completedMarkup : formMarkup}
        </SimpleBox>
      </SimpleLayout.Section>
      <SimpleLayout.Section>
        <SimpleBox width={574} position="absolute" left={56} bottom={0} zIndex={6} p={'112px 0'}>
          <Header as="h1">Secure access to the open internet.</Header>
        </SimpleBox>
        <SimpleBox
          width="100%"
          height="100%"
          backgroundImage={`url('${AuthCover}')`}
          backgroundSize="846px 900px"
          backgroundPosition="right center"
          backgroundRepeat= "no-repeat"
        />
      </SimpleLayout.Section>
    </SimpleLayout>
  )
}

ForgotPassword.authorize = false
ForgotPassword.auth = '/apps'

export default ForgotPassword
