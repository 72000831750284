import React, { FC, useState } from 'react'
import { Form, Grid, GridColumn } from 'semantic-ui-react'
import { FormContent, FormHeader } from '.'
import { SimpleForm } from '../../SimpleForm'
import { CountryInput } from './inputs/CountryInput'
import { InputContainer } from './inputs/InputContainer'
import { StateInput } from './inputs/StateInput'

const medicalIcon = require('../../../assets/images/identity-security/medical')
const passportIcon = require('../../../assets/images/identity-security/passport')
const driverLicenseIcon = require('../../../assets/images/identity-security/driverlicense')

const DocumentsForm: FC = () => {
  const [passport, setPassport] = useState('')
  const [driverLicense, setDriverLicense] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [medialIds, setMedicalIds] = useState<string[]>([''])

  const handleAddNewMedicalId = () => {
    setMedicalIds([...medialIds, ''])
  }

  const handleOnChangeMedicalId = (index: number, medicalId: string) => {
    setMedicalIds([
      ...medialIds.slice(0, index),
      medicalId,
      ...medialIds.slice(index + 1)
    ])
  }

  return (<div className="identity-security-form">
    <FormHeader
      image={require('../../../assets/images/identity-security/documents.svg')}
      title="Documents"
      text="Please fill in your documents"
    />
    <FormContent>
      <Grid columns={2} stackable>
        <GridColumn>
          <CountryInput country={country} onChange={setCountry} />
        </GridColumn>
        <GridColumn>
          <StateInput state={state} onChange={setState} />
        </GridColumn>
      </Grid>
      <InputContainer image={medicalIcon}>
        {medialIds.map((medicalId, index) => (
          <Form.Field key={index}>
            <label>Medical ID Number</label>
            <SimpleForm.Input
              fluid
              value={medicalId}
              required
              showValidation={medicalId.length > 0}
              onChange={({value}) => handleOnChangeMedicalId(index, value) }
              loading={false}
            />
          </Form.Field>))}
        <a className="add-new-link" onClick={handleAddNewMedicalId}>+ Add more</a>
      </InputContainer>
      <InputContainer image={passportIcon}>
        <Form.Field>
          <label>Passport Number</label>
          <SimpleForm.Input
            fluid
            value={passport}
            required
            showValidation={passport.length > 0}
            onChange={({value}) => setPassport(value)}
            loading={false}
          />
        </Form.Field>
      </InputContainer>
      <InputContainer image={driverLicenseIcon}>
        <Form.Field>
          <label>Driver License Number</label>
          <SimpleForm.Input
            fluid
            value={driverLicense}
            required
            showValidation={driverLicense.length > 0}
            onChange={({value}) => setDriverLicense(value)}
            loading={false}
          />
        </Form.Field>
      </InputContainer>
    </FormContent>
  </div>)
}

DocumentsForm.displayName = 'DocumentsForm'

export { DocumentsForm }
