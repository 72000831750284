import React, { FC, useEffect, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Button, Card, Container, Header, Image, Loader } from 'semantic-ui-react'
import { useNavigation } from '../hooks/useNavigation'
import { WindowsAntivirus } from '../components/App/WindowsAntivirus'
import { App } from '../shared/types'

type AppsGroup = { key: string; title: string; apps: App[] }
type IconKey = 'android' | 'ios' | 'linux' | 'macos' | 'windows'

const QUERY = gql`
  { apps(published: true) { id key name notes downloadUrl } }
`

const icons = require.context('~assets/images/icons/os', false, /^((?!\/-).)*\.svg$/)
const iconsIndex = icons.keys().reduce((acc, key) => {
  const icon = (key.split(/\//).at(-1) as string).replace(/\.svg$/, '')
  return { ...acc, [icon]: icons(key) }
}, {} as Record<IconKey, string>)

export function iconFor(key: string): string {
  if (iconsIndex.hasOwnProperty(key)) {
    return iconsIndex[key as IconKey]
  }

  const fallback = Object.keys(iconsIndex).find(k => key.includes(k)) as IconKey | undefined
  return fallback ? iconsIndex[fallback] : require('~assets/images/icons/shield.svg')
}

const mobileApps = ['ios', 'android']
const customComponents: Record<string, FC<{ app: App }>> = {
  'windows-antivirus': WindowsAntivirus,
}

const Apps: FC = () => {
  const { setNav, resetNav } = useNavigation()
  const { data, loading } = useQuery<{ apps: App[] }>(QUERY)

  const groupedApps = useMemo(() => {
    const result = [
      { key: 'mobile', title: 'Mobile Apps', apps: [] },
      { key: 'desktop', title: 'Desktop Apps', apps: [] },
    ] as AppsGroup[]

    data?.apps?.forEach(app => result[mobileApps.some(v => app.key.includes(v)) ? 0 : 1].apps.push(app))

    return result
  }, [data?.apps])

  useEffect(() => {
    setNav('hidden')
    return resetNav
  }, [])

  if(loading) {
    return (
      <Container>
        <Loader active />
      </Container>
    )
  }

  return (
    <div>
      <Header as="h4">Apps</Header>
      {groupedApps.filter(l => l.apps.length).map((g: AppsGroup) => (
        <div key={g.key} style={{ marginTop: 24 }}>
          <div className="text">{g.title}</div>

          {g.apps.map(a => {
            const Component = customComponents[a.key]

            if (Component) {
              return <Component key={a.id} app={a} />
            }

            return (
              <Card fluid key={a.id} className="dense">
                <Card.Content>
                  <div className="grid grid-apps-item">
                    <Image src={iconFor(a.key)} height={58} className="mr-1" />
                    <h6>{a.name}</h6>
                    <div className="text--subdued text--small mr-auto">{a.notes}</div>
                    <Button size="big" primary onClick={() => window.open(a.downloadUrl, '_blank')}>
                      Download
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default Apps
