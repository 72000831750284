import React, {VFC} from 'react'
import {Card} from 'semantic-ui-react'

/// Props for the component
type Props = {
  onReactivateClicked: () => void
}

/**
 * Renders a component with information about reactivating the users subscription
 */
const ReactivateSubscription: VFC<Props> = ({onReactivateClicked}) => (<>
  <Card fluid>
    <Card.Content>
      <div className="card__title display-flex">
        <div className="mr-auto">Reactivate subscription</div>
      </div>
      <p className="text text--subdued text--small">
            If you would like to reactivate your protection subscription, you are able to do so{' '}
        <a onClick={onReactivateClicked} className="link" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </p>
    </Card.Content>
  </Card>
</>)

export { ReactivateSubscription }
