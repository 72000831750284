import React, { FC, useEffect, useState } from 'react'
import { Container, Loader, Button, Header, Input, Label, Modal } from 'semantic-ui-react'
import Mutation from '~shared/mutation'
import Notifications from '~shared/notifications'
import { gql, useQuery } from '@apollo/client'
import './style.scss'

const styles = require('./SubUsers.module.scss')

type Status = 'CANCELED' | 'SUSPENDED' | 'REJECTED' | 'OPEN' | 'ACCEPTED'
type UserAccount = { id: string, invitationEmail?: string, status: Status, user?: { id: string, email: string } }
type Query = { me: { id: string }, account: { assignedUserAccounts: UserAccount[] } }

type Usage = { current: number, limit: number }
type Props = { ownerId?: string, usage?: Usage }

const REMOVE_INVITE = 'mutation($id: ID!) { removeInvite(id: $id) }'
const SEND_INVITE = `mutation($email: String!) {
  sendInvite(email: $email) { id invitationEmail status user { id email } }
}`

const SubUsers: FC<Props> = ({ ownerId, usage }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [adding, setAdding] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [modal, setModal] = useState<UserAccount>()
  const [items, setItems] = useState<UserAccount[]>([])
  const { data, loading: gqlLoading } = useQuery<Query>(
    gql`
      query {
        me { id }
        account {
          assignedUserAccounts {
            id
            invitationEmail
            status
            user {
              id
              email
            }
          }
        }
      }
    `
  )

  const sendInviteMutation = new Mutation<{ sendInvite: UserAccount }>(SEND_INVITE)
  const removeInviteMutation = new Mutation<{ removeInvite: boolean }>(REMOVE_INVITE)

  useEffect(() => {
    setItems(data?.account?.assignedUserAccounts || [])
  }, [data?.account])

  if(!ownerId || !usage) {
    return <></>
  }

  if(gqlLoading || !data) {
    return (
      <Container className={styles.Base} fluid>
        <Loader active />
      </Container>
    )
  }

  if(ownerId !== data.me.id) {
    return <></>
  }

  const sendInvite = () => {
    if(loading) {
      return
    }

    setLoading(true)
    sendInviteMutation.exec({ email }).then(() => {
      setEmail('')
      setAdding(false)
      setLoading(false)
      if(sendInviteMutation.hasErrors) {
        Notifications.error(sendInviteMutation.error())
      } else if(sendInviteMutation.data?.sendInvite) {
        const result = sendInviteMutation.data.sendInvite
        const message = result.status === 'OPEN' ? 'Invite successfully sent' : 'User successfully invited'

        Notifications.success(message)
        setItems([...items, result])
      }
    })
  }

  const removeInvite = (item?: UserAccount) => {
    if(loading || !item) {
      return
    }

    setLoading(true)
    removeInviteMutation.exec({ id: item.id }).then(() => {
      setLoading(false)
      setModal(undefined)
      if(removeInviteMutation.hasErrors) {
        Notifications.error(removeInviteMutation.error())
      } else {
        const message = item.status === 'OPEN' ? 'Invite successfully cancelled' : 'User successfully removed'
        const newItems = [...items]

        Notifications.success(message)
        newItems.splice(items.indexOf(item), 1)
        setItems(newItems)
      }
    })
  }

  const buttonProps = loading ? { loading: true, disabled: true } : {}

  const renderRemoveButton = (item: UserAccount) => (
    <Button
      type="button"
      icon="trash alternate outline"
      onClick={() => setModal(item)}
      {...buttonProps}
    />
  )

  const renderLabelAction = (item: UserAccount) => {
    if(!item.user?.id) {
      return (<><Label>Invited</Label>{renderRemoveButton(item)}</>)
    } else if(ownerId === item.user.id) {
      return (<Label className="solo">Owner</Label>)
    } else if(data.me.id === item.user.id) {
      return (<Label className="solo">Myself</Label>)
    } else {
      return (<><Label color="green">Active</Label>{renderRemoveButton(item)}</>)
    }
  }

  return (
    <Container className={styles.Base} fluid>
      <Header as="h6" content="Family Members" />
      <Modal id="remove-invite" closeIcon size="mini" open={modal !== undefined} onClose={() => setModal(undefined)}>
        <Modal.Content>
          <Label icon="exclamation circle" />
          <p>Do you want remove the access for {modal?.user?.email || modal?.invitationEmail}?</p>
          <Button color="blue" content="Remove" size="big" fluid onClick={() => removeInvite(modal)} {...buttonProps} />
          <Button content="Cancel" size="big" inverted fluid onClick={() => setModal(undefined)} {...buttonProps} />
        </Modal.Content>
      </Modal>
      <ul id="members">
        {items.map((item, idx) => (
          <li key={item.id}>
            <Label className="number" color="blue" content={idx + 1} />
            <span className="name">{item.user?.email || item.invitationEmail}</span>
            {renderLabelAction(item)}
          </li>
        ))}
        {(items.length < usage.limit) && (
          <li key="adding" className="add">
            {adding && (
              <>
                <Input type="email" value={email} onChange={(ev, d) => setEmail(d.value)} autoFocus />
                <Button type="button" content="Invite" color="blue" onClick={sendInvite} {...buttonProps} />
                <Button type="button" icon="close" onClick={() => [setEmail(''), setAdding(false)]} {...buttonProps} />
              </>
            ) || (
              <Button
                type="button"
                color="blue"
                content="+ Add member"
                onClick={() => setAdding(true)}
                inverted
                fluid
                {...buttonProps}
              />
            )}
          </li>
        )}
      </ul>
    </Container>
  )
}

export { SubUsers }
