import React, {useState, VFC} from 'react'
import {Email} from './change-email/Email'
import {Success} from './change-email/Success'

const ChangeEmail: VFC = () => {
  const [step, setStep] = useState<'email' | 'success'>('email')
  const [newEmail, setNewEmail] = useState('')

  switch (step) {
    case 'email':
      return <Email onFinish={email => { setNewEmail(email); setStep('success') }} />
    case 'success':
      return <Success newEmail={newEmail} />
  }
}

export { ChangeEmail }
