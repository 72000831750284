
import React, { FC, useEffect } from 'react'
import { FullNameForm, HomeAddressForm, SocialSecurityNumberForm } from '../../components/IdentitySecurity/form'
import { IdentitySecurityHeader } from '../../components/IdentitySecurity/header/'
import { SimpleBox } from '../../components/SimpleBox'
import { useNavigation } from '../../hooks/useNavigation'
import { Form } from 'semantic-ui-react'
import Router from '../../shared/router'

const PersonalInformation: FC = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')

    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  const handleSave = () => {
    Router.redirect('/identity-security/dark-web-monitoring')
  }

  return (<>
    <IdentitySecurityHeader selectedTab={'personal-information'} />
    <Form id="personal-information" onSubmit={handleSave}>
      <SimpleBox className="identity-security-body">
        <FullNameForm />
        <HomeAddressForm />
        <SocialSecurityNumberForm />
        <Form.Button size="large" primary>Save and Continue</Form.Button>
      </SimpleBox>
    </Form></>)
}

PersonalInformation.displayName = 'PersonalInformation'

export default PersonalInformation
