import React, { FC } from 'react'
import { Header, Form } from 'semantic-ui-react'
import { SimpleBox } from '../../SimpleBox'

type Props = {
    step: {title: string, answers: string[]}
}

const VerificationStep: FC<Props> = ({step}) => (<>
  <Header className="mr-2" as="h6" textAlign="center">
    {step.title}
  </Header>
  <SimpleBox className="mt-2">
    {step.answers.map((answer, index) => (<SimpleBox key={index}
      borderRadius="18px"
      boxShadow="0px 4px 60px rgba(0, 0, 0, 0.05);"
      marginTop="4px"
      padding="15px"
      background="white">
      <SimpleBox display="flex" flexDirection="row">
        <Form.Checkbox label={answer} />
      </SimpleBox>
    </SimpleBox>))}
  </SimpleBox>
</>
)

export { VerificationStep }

