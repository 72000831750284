import React, {useState, VFC} from 'react'
import {Button, FormField, Image, Input, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../../components/SimpleBox'
import {gql, useMutation} from '@apollo/client'
import {emailRegex} from '../../../const'
import Notifications from '../../../shared/notifications'

const CheckIcon = require('~assets/images/icons/tick-green.svg')

type Props = {
  onFinish: (newEmail: string) => void;
}

const Email: VFC<Props> = ({ onFinish }) => {
  const [email, setEmail] = useState('')

  const [changePassword, { loading }] = useMutation(
    gql`
      mutation($input: ChangeUserInfoInput!) {
        changeInfo(input: $input)
      }
    `
  )

  const handleConfirm = async () => {
    try {
      await changePassword({ variables: { input: { email }} })
      onFinish(email)
    } catch (error) {
      Notifications.error('Unable to change your email')
    }
  }

  const isEmailValid = emailRegex.test(email!)

  return <>
    <Modal.Header>
      <h6 className="title">Change Email</h6>
    </Modal.Header>
    <Modal.Content className="white">
      <SimpleBox maxWidth={360} mx="auto">
        <SimpleBox mb={3}>
          <p>We will send a request to your new email so you can confirm it belongs to you.</p>
          <br />
          <p>Your email will only be changed after you have confirmed the new one.</p>
        </SimpleBox>
        <FormField>
          <label htmlFor="email">New email address</label>
          <Input
            id="email"
            value={email}
            type="email"
            placeholder="name@example.com"
            size="large"
            onChange={e => setEmail(e.target.value)}
            fluid
            icon={
              isEmailValid && (
                <Image
                  src={CheckIcon}
                  height={22}
                  style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }}
                />
              )
            }
          />
        </FormField>
      </SimpleBox>
      <SimpleBox mt={7} mb={4} maxWidth={300} mx="auto">
        <Button size="huge" fluid primary onClick={handleConfirm} loading={loading} disabled={!isEmailValid}>
          Request Confirmation Email
        </Button>
      </SimpleBox>
    </Modal.Content>
  </>
}

export { Email }
