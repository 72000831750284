import React, {useState, VFC} from 'react'
import {Button, Container, Loader, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../components/SimpleBox'
import Mutation from '../../shared/mutation'
import {action} from 'mobx'
import Notifications from '../../shared/notifications'
import {gql, useQuery} from '@apollo/client'
import {Account} from '../../type'
import {trackCancelSubscription} from '../../utils/tracking'
import {ChargebeeItemPrice} from '../../shared/types'

type CancelSubscriptionData = {
  cancelSubscription: {
    status: boolean
    refundAmount: number
  }
}

type CancelStepProps = {
  onNext: (refundAmount: number) => void;
  onCancelled: () => void;
}

const CancelStep: VFC<CancelStepProps> = ({onNext, onCancelled}) => {
  /// If the cancellation process is in progress
  const [loading, setLoading] = useState<boolean>(false)

  /// The actual cancel subscription mutation
  const cancelMutation = new Mutation<CancelSubscriptionData>(`
    mutation cancelSubscription($endOfTerm: Boolean!) {
      cancelSubscription(endOfTerm: $endOfTerm) {
        status
        refundAmount
      }
    }
  `)

  /* Fetch the next billing date and the subscription status */
  const {data, loading: plansLoading} = useQuery(
    gql`
      query {
        account {
          addons
          subscriptions {
            status
            period
            nextBillingAt
            itemPrices {
              name
              itemType
              period
              periodUnit
            }
          }
        }
      }
    `
  )

  /* Fetch settings data */
  const {data: settings, loading: settingsLoading} = useQuery(gql`
    query {
      siteSettings {
        key
        enabled
      }
  }`)

  // @ts-ignore
  const refundEnabled = settings?.siteSettings.find(s => s.key === 'REFUND_ENABLED')?.enabled

  const itemPrice = (data?.account?.subscriptions || [])[0]?.itemPrices?.filter((i:{itemType:string}) => i.itemType === 'PLAN')[0] as ChargebeeItemPrice

  /// Cancels the subscription and sets the loading state
  const handleCancel = (endOfTerm: boolean) => {
    setLoading(true)

    cancelMutation.exec({endOfTerm}).then(action(() => {
      setLoading(false)
      if (cancelMutation.data?.cancelSubscription.status) {
        Notifications.success('Subscription cancelled')
        onNext(cancelMutation.data?.cancelSubscription.refundAmount)

        const period = itemPrice?.period || 1
        const isFamily = itemPrice?.name?.toLowerCase().includes('family')
        const isOne = itemPrice?.name?.toLowerCase().includes('one')
        trackCancelSubscription(period, isFamily, isOne)

        onCancelled()
      } else {
        Notifications.error(cancelMutation.error() || 'Unable to cancel subscription', {timing: 8000})
      }
    }))
  }

  const cancelImmidiately = () => {
    const text = 'Are you sure you\'d like to cancel immidiately? You will no longer be protected as of today.'

    if (confirm(text)) { // eslint-disable-line no-alert
      handleCancel(false)
    }
  }

  /// If customer is eligible for a refund
  const account = data?.account as Account
  const subscription = (account?.subscriptions || [])[0]

  /// While loading reasons we just show a spinner
  if (plansLoading || subscription === null || subscription === undefined || settingsLoading) {
    return (
      <Container>
        <Loader active />
      </Container>
    )
  }

  /// Determine if the customer is eligible for a refund
  const daysBetween = (d1: Date, d2: Date) => {
    const diff = Math.abs(d1.getTime() - d2.getTime())
    return Math.ceil(diff / (1000 * 3600 * 24))
  }

  const isTrial = subscription.status === 'IN_TRIAL'
  const canRefund = refundEnabled && !isTrial && daysBetween(new Date(), new Date(subscription.period?.start || new Date())) <= 60

  /// When subscription is cancelled
  const nextBillingAt = subscription.nextBillingAt
  const dateFormatter = Intl.DateTimeFormat('en-US', ({dateStyle: 'long'} as any))
  const dateString = dateFormatter.format(new Date(nextBillingAt || new Date()))

  const refundMarkup = canRefund && (<>
    <SimpleBox height={30} />
    <SimpleBox as={'p'} color={'#787E8D'} fontWeight={400} fontSize={13} textAlign={'center'}>You can always cancel subscription immediately with refund.</SimpleBox>
    <Button
      onClick={cancelImmidiately}
      content="Cancel Immediately"
      size="small"
      loading={loading}
      secondary
    />
  </>)

  return (<><Modal.Header>
    <SimpleBox display="flex" justifyContent="center" alignItems="center" flexDirection={'column'}>
      <SimpleBox as={'p'} color={'#000000'} fontWeight={700} fontSize={30} textAlign={'center'} marginTop={55} maxWidth={343}>Disable auto-renewal</SimpleBox>
    </SimpleBox>
  </Modal.Header>
  <Modal.Content>
    <SimpleBox as={'p'} color={'##787E8D'} fontWeight={400} fontSize={16} textAlign={'center'}>After canceling the renewal,{' '}
          the product and service can be still used until {dateString}. No fees will be charged after the subscription{' '}
          expires, but the product and service will be not available anymore.
    </SimpleBox>
    <SimpleBox paddingTop="30px" display="flex" justifyContent="center" alignItems={'center'} flexDirection={'column'} gap={20}>
      <Button
        onClick={() => { handleCancel(true) }}
        content="Disable Auto Renewal"
        size="big"
        loading={loading}
        primary
      />
      {refundMarkup}
    </SimpleBox>
  </Modal.Content></>
  )
}

export {CancelStep}
