import React, { FC } from 'react'
import { Image, Label } from 'semantic-ui-react'
import { Plan } from '../../../type'
import { toMoneyWithoutCalculation as toMoney, toPercent } from '../../../shared/format'
import { Coupon } from '../../../utils/coupon'
import { SimpleBox } from '../../SimpleBox'
import { Feature } from '../../../types/checkout'
import Decimal from 'decimal.js'

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

type Props = {
  plan?: Plan
  total: number
  coupon?: Coupon
  activateNow: boolean
  priceWithAddon: number
  selectedFeatures: Feature[]
  bienniallyFreeMonths?: number
};

const Breakdown: FC<Props> = ({
  plan,
  total,
  coupon,
  activateNow,
  selectedFeatures = [],
  priceWithAddon,
  bienniallyFreeMonths,
}) => {
  const couponAmount = coupon?.discountAmount
  const couponPercentage = coupon?.discountPercentage
  const period = { 1: 'monthly', 12: '1 year', 24: '2 years' }[plan?.period || 12]
  const totalPeriod = (plan?.period || 12) + ((activateNow && bienniallyFreeMonths) || 0)

  const couponDiscount = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  return (
    <div className="checkout-breakdown">
      <SimpleBox display="flex" flexDirection="column">
        {selectedFeatures.map((feature, index) => {
          const addonPriceWithDiscount = new Decimal(feature.price)
            .mul(new Decimal(1).minus(couponPercentage || 0))
            .minus(new Decimal(couponAmount || 0))
          return (
            <SimpleBox
              key={feature.chargebeeId}
              display="flex"
              flexDirection="column"
              gap="5px"
              borderBottom="1px solid #E8EAF0"
              padding="12px 0px"
              className={ index === 0 ? 'breakdown-first-item' : ''}
            >
              <SimpleBox
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <SimpleBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="8px"
                >
                  <Image
                    className="checkout-breakdown-icon"
                    src={PlanCheckMarkIcon}
                  />
                  <p className="checkout-breakdown-text">{feature.title}</p>
                </SimpleBox>
                <div className="checkout-breakdown-text">
                  {toMoney(addonPriceWithDiscount.toNumber())}
                </div>
              </SimpleBox>
              {coupon && (
                <SimpleBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p className="checkout-gray-text">
                    {period} {(activateNow && bienniallyFreeMonths && `+ ${bienniallyFreeMonths} months`)} (
                    {toMoney(addonPriceWithDiscount.div(totalPeriod).toNumber())}/mo)
                  </p>
                  <p className="checkout-gray-text checkout-gray-text-crossed">
                    {toMoney(feature.price)}
                  </p>
                </SimpleBox>
              )}
            </SimpleBox>
          )
        })}
        {coupon && (
          <SimpleBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <SimpleBox display="flex" flexDirection="row" gap={8}>
              <p className="checkout-breakdown-text">Discount</p>
              <Label className="pill" color="green" size="tiny">
                {couponDiscount()}
              </Label>
            </SimpleBox>
            <div className="text">
              <div className="text">
                {coupon ? '-' : ''}
                {toMoney(priceWithAddon - total)}
              </div>
            </div>
          </SimpleBox>
        )}
      </SimpleBox>
      <SimpleBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <p className="checkout-breakdown-text">Total Due Today</p>
        <div className="checkout-breakdown-amount">
          {toMoney(activateNow ? total : 0)}
        </div>
      </SimpleBox>
    </div>
  )
}
export default Breakdown
