export const strengthForValue = (value?: string): number => {
  if (!value) { return 0 }
  const val = value

  let diversity = 0
  diversity += val.match(/[a-z]/) ? 1 : 0
  diversity += val.match(/[A-Z]/) ? 1 : 0
  diversity += val.match(/[0-9]/) ? 1 : 0
  diversity += val.match(/[!@#$%^&*]/) ? 1 : 0

  let result = 0
  result += (diversity >= 1 && val.length >= 4) ? 1 : 0
  result += (diversity >= 2 && val.length >= 6) ? 1 : 0
  result += (diversity >= 3 && val.length >= 8) ? 1 : 0
  result += (diversity >= 4 && val.length >= 10) ? 1 : 0

  return result
}
