import React, { CSSProperties, FC, useMemo } from 'react'
const styles = require('./Badge.module.scss')

interface Props {
    text: string | any
    color?: string
    backgroundColor?: string
    activeColor?: string
    active?: boolean
    height?: number
    style?: CSSProperties
    width?: number
}

/**
 * Badge component
 * renders a text inside a rounded DIV container
 * @param text string
 * @param color? string
 * @param backgroundColor? string
 * @param active? boolean
 * @param activeColor? string
 */
const Badge: FC<Props> = ({ text, color, backgroundColor, activeColor, active, height, style, width }) => {
  const background = useMemo((): string => {
    if (active) { return activeColor || '#FB0023' }
    if (style && style.backgroundColor) { return style.backgroundColor }
    return backgroundColor || '#B0B5C2'
  }, [backgroundColor, activeColor, active])

  const textColor = useMemo((): string => color || '#FFFFFF', [color])

  return <div className={styles.container}>
    <div className={styles.badge}
      style={{
        ...style,
        backgroundColor: background,
        height: height || 24,
        width: width || 24,
      }}>
      <span style={{ color: textColor }}>
        {text}
      </span>
    </div>
  </div>
}

export default Badge
