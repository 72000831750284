import React, { FC, useEffect, useState } from 'react'
import { Modal, Progress, Button } from 'semantic-ui-react'
import { VerificationStep } from '.'
import { SimpleBox } from '../../SimpleBox'

type Props = {
    onCompleted: () => void
}

const VerificationSteps: FC<Props> = ({onCompleted}) => {
  const [steps, setSteps] = useState<{title: string, answers: string[]}[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useEffect(() => {
    // TODO: Fetch verification info somewhere
    setSteps([
      {
        title: 'What state was your SSN issued in?',
        answers: [
          'Oregon',
          'Kentucky',
          'California',
          'Oklahoma',
          'None of the above'
        ]
      },
      {
        title: 'Hello World in Danish?',
        answers: [
          'Møjn Verden',
          'Hej Verden',
          'Party Party',
          'Ich bin ein weld',
          'None of the above'
        ]
      },
      {
        title: 'Big Brands You know?',
        answers: [
          'Byteable',
          'MacDonalds',
          'Coca Cola',
          'VirtualShield',
          'None of the above'
        ]
      }
    ])
  }, [])

  const handleNext = () => {
    const newIndex = currentIndex + 1
    if (newIndex >= steps.length) {
      onCompleted()
    } else {
      setCurrentIndex(newIndex)
    }
  }

  return (
    <>
      <Modal.Header>
        <h6 className="title">Verify identity</h6>
      </Modal.Header>
      <Modal.Content>
        <Progress percent={100.0 / (steps.length / (currentIndex+1))} size="tiny" success />
        {steps.length > 0 && steps[currentIndex] && <VerificationStep key={currentIndex} step={steps[currentIndex]} />}
        <SimpleBox mt={7} mx="auto">
          <Button size="large" fluid primary onClick={handleNext}>
            Next
          </Button>
        </SimpleBox>
      </Modal.Content>
    </>)
}

export { VerificationSteps }
