import React, { FC } from 'react'
import { Form } from 'semantic-ui-react'
import { Input } from './components'

type Comps = { Input: typeof Input }
type Props = { name: string }

const SimpleForm: FC<Props> & Comps = ({ children }) => <Form>{children}</Form>
SimpleForm.Input = Input
export { SimpleForm }
