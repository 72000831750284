import React, { FC } from 'react'
import { Image } from 'semantic-ui-react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'

type Props = {
  image: ImageBitmapSource
  title: string
  text: string
}

const FormHeader: FC<Props> = props => (<SimpleBox className="form-header">
  <SimpleBox className="image">
    <Image src={props.image} />
  </SimpleBox>
  <SimpleBox className="content">
    <SimpleText size="small">{props.title}</SimpleText>
    <SimpleText className="text">{props.text}</SimpleText>
  </SimpleBox>
</SimpleBox>)


export { FormHeader }
