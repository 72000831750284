import React, { FC, useState } from 'react'
import { FormContent, FormHeader } from '.'
import { Form } from 'semantic-ui-react'
import { SimpleForm } from '../../SimpleForm'

const FullNameForm: FC = () => {
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState('')
  const [lastName, setLastName] = useState('')

  return (<div className="identity-security-form">
    <FormHeader
      image={require('../../../assets/images/identity-security/full-name.svg')}
      title="Full Name"
      text="Please fill in your full name"
    />
    <FormContent>
      <Form.Field>
        <label>First Name</label>
        <SimpleForm.Input
          fluid
          value={firstName}
          required
          showValidation={firstName.length > 0}
          onChange={({value}) => setFirstName(value)}
          loading={false}
        />
      </Form.Field>
      <Form.Field>
        <label>Second Name</label>
        <SimpleForm.Input
          fluid
          value={secondName}
          showValidation={secondName.length > 0}
          onChange={({value}) => setSecondName(value)}
          loading={false}
        />
      </Form.Field>
      <Form.Field>
        <label>Last Name</label>
        <SimpleForm.Input
          fluid
          value={lastName}
          required
          showValidation={lastName.length > 0}
          onChange={({value}) => setLastName(value)}
          loading={false}
        />
      </Form.Field>
    </FormContent>
  </div>)
}

FullNameForm.displayName = 'FullNameForm'

export { FullNameForm }
