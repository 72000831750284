import React, { FC } from 'react'
import { Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../../SimpleBox'
import DatePicker from 'react-datepicker'

type Props = {
  birthDay: Date|null
  onBirthdateChanged: (date: Date) => void
}

const BirthdayInputModal: FC<Props> = ({birthDay, onBirthdateChanged}) => (<>
  <Modal.Header>
        Date of Birth
  </Modal.Header>
  <Modal.Content>
    <SimpleBox display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
      <div className="modal-datepicker">
        <DatePicker
          selected={birthDay}
          onChange={date => onBirthdateChanged(date as Date || null)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          inline
        />
      </div>
    </SimpleBox>
  </Modal.Content></>)

export { BirthdayInputModal }
