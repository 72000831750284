import React, { FC, useState } from 'react'
import { Email } from './reset-password/Email'
import { Success } from './reset-password/Success'

type Steps = 'email' | 'success'
type Props = { onFinish?: () => void }
const ResetPassword: FC<Props> = ({ onFinish }) => {
  const [step, setStep] = useState<Steps>('email')
  const [email, setEmail] = useState('')
  return (
    <>
      {step === 'email' && (
        <Email
          onContinue={data => {
            setStep('success')
            setEmail(data.email)
          }}
        />
      )}
      {step === 'success' && <Success payload={{ email }} onContinue={onFinish} />}
    </>
  )
}
export { ResetPassword }
