/* eslint-disable @typescript-eslint/ban-ts-comment */
import { classNames, variationName } from '@shopify/css-utilities'
import { createElement, CSSProperties, FC, ReactDOM } from 'react'

const styles = require('./SimpleText.module.scss')

type Props = {
  as?: keyof ReactDOM;
  size?:
    | 'small'
    | 'medium'
    | 'large'
    | 'extraLarge'
    | 'title1'
    | 'title2'
    | 'title3'
    | 'textSmall'
    | 'buttonText'
    | 'text5';
  weight?: 'light' | 'bold' | 'extraBold';
  color?: 'grey' | 'white' | 'red' | 'black';
  className?: string;
  textDecoration?: 'underline' | 'lineThrough' | 'none';
} & CSSProperties;
const SimpleText: FC<Props> = ({
  children,
  as,
  size,
  weight,
  className,
  color,
  textDecoration,
  ...other
}) => {
  const style: CSSProperties = other
  const styleMain =
    className ||
    classNames(
      styles.Main,
      size && styles[variationName('size', size)],
      weight && styles[variationName('weight', weight)],
      color && styles[variationName('color', color)],
      textDecoration && styles[variationName('textDecoration', textDecoration)]
    )
  return createElement(as ?? 'p', { className: styleMain, style }, children)
}
export { SimpleText }
