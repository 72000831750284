import React, { FC } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'
import { Image } from 'semantic-ui-react'
import {IdentisafeAlert} from '../../../type'

const alertIcon = require('../../../assets/images/icons/protection-alert.svg')
const alertResolvedIcon = require('../../../assets/images/icons/protection-alert-resolved.svg')

type Props = {
    alerts: IdentisafeAlert[]
    getHelpClicked: (alert: IdentisafeAlert) => void
}

const RenderAlertList: FC<Props> = props => (<SimpleBox className="mt-1">{props.alerts.map((alert, index) => (
  <SimpleCard key={index} className="mt-1">
    <SimpleBox display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
      <SimpleBox gap={12} alignItems="center" >
        <Image src={alert.resolved ? alertResolvedIcon : alertIcon} />
        <SimpleText size="title3">{alert.title}</SimpleText>
      </SimpleBox>
      {alert.resolved && <SimpleBox className="alert-resolved-button">Resolved</SimpleBox>}
      {!alert.resolved && <a onClick={() => props.getHelpClicked(alert)} className="alert-gethelp-button">Get help</a>}
    </SimpleBox>
  </SimpleCard>))}</SimpleBox>)


export { RenderAlertList }
