import React, {FC, useEffect, useState} from 'react'
import {Button, Image} from 'semantic-ui-react'
import {FormHeader} from '../../Form/Header/FormHeader'
import {FormContainer} from '../../Form/Container/FormContainer'
import {FormTextField} from '../../Form/Fields/Text/FormTextField'
import {useDataRemoval} from '../context'
import Notifications from '../../../shared/notifications'

const styles = require('./PersonalInformation.module.scss')

const EmailImage = require('../../../assets/images/data-removal-email.svg')
const FormRemove = require('../../../assets/images/form-remove.svg')

type Props = {
  onStepComplete: () => void
}

const EmailAdresses:FC<Props> = ({ onStepComplete }) => {
  const dataRemoval = useDataRemoval()
  const [emails, setEmails] = React.useState<string[]>([])
  const altEmails = emails.slice(1)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (dataRemoval.primaryProfile?.emailAddresses) {
      setEmails(dataRemoval.primaryProfile.emailAddresses)
    }
  }, [dataRemoval.primaryProfile])

  const handleEmailChange = (index: number, email: string) => {
    const newEmails = [...emails]
    newEmails[index] = email
    setEmails(newEmails)
  }

  const addAltEmail = () => {
    setEmails([...emails, ''])
  }

  const removeAltEmail = (index: number) => {
    const newEmails = [...emails]
    newEmails.splice(index, 1)
    setEmails(newEmails)
  }

  const saveAndContinue = async () => {
    setLoading(true)
    try {
      await dataRemoval.saveEmailAddresses(emails)
      onStepComplete()
    } catch (e) {
      setLoading(false)
      Notifications.error(e.message || 'Something went wrong. Please try again later.')
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormHeader
          title={'Email Addresses'}
          description={'Please enter any email addresses you currently have or\nhave had in the past, even if you don’t use them anymore.'}
          image={EmailImage} />
        <br />
        <FormContainer>
          <FormTextField required={true} type={'email'} label={'Primary Email Address'} value={emails[0] || ''} onChange={value => {
            handleEmailChange(0, value)
          }} />
        </FormContainer>
        <br />
        {altEmails.map((email, index) => (
          <div key={index} className={styles.altNameForm}>
            <FormContainer>
              <FormTextField required={true} type={'email'} label={'Additional Email'} value={email} onChange={value => {
                handleEmailChange(index+1, value)
              }} />
            </FormContainer>
            <div className={styles.removeAltName} onClick={() => { removeAltEmail(index+1) }}>
              <Image src={FormRemove} />
            </div>
          </div>
        ))}
        <div className={styles.addName} onClick={addAltEmail}>+ Add additional email address</div>
      </div>
      <Button primary={true} size="large" loading={loading} onClick={saveAndContinue}>
        Save and Continue
      </Button>
      <br />
    </div>
  )
}

export { EmailAdresses }
