import React, {FC} from 'react'
import {ICardProps} from './ICardProps'
import {SimpleBox} from '../../components/SimpleBox'
import {SimpleText} from '../../components/SimpleText'
import {Form} from 'semantic-ui-react'
import {SimpleForm} from '../../components/SimpleForm'
import {Settings} from '../../config'
import { usePaymentInputs } from 'react-payment-inputs'

// @ts-ignore
import Recaptcha from 'react-google-recaptcha'
import {cardNumberValidator} from '../../utils/cardValidator'

const Styles = require('./AuthorizeNetCard.module.scss')

const AuthorizeNetCard: FC<ICardProps> = ({ data, captchaRef, nameDisabled, onChange }) => {
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs({cardNumberValidator})

  const handleCaptcha = (value: boolean) => {
    onChange && onChange({ name: 'captcha', value })
  }

  const onCardChange = (e: any) => {
    onChange && onChange({ name: 'card.number', value: e.target.value })
  }

  const onExpiryChange = (e: any) => {
    onChange && onChange({ name: 'card.expiry', value: e.target.value })
  }

  const onCvvChange = (e: any) => {
    onChange && onChange({ name: 'card.cvv', value: e.target.value })
  }

  return (
    <>
      <SimpleBox mb={2}>
        <SimpleText size="title2">Enter card details</SimpleText>
      </SimpleBox>
      {!nameDisabled && (
        <Form.Group widths="equal">
          <Form.Field>
            <label>First Name</label>
            <SimpleForm.Input
              name="firstName"
              required
              fluid
              size="large"
              pattern="^[a-zA-Z\s]*$"
              restrict={true}
              value={data?.firstName}
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Last Name</label>
            <SimpleForm.Input
              name="lastName"
              required
              fluid
              size="large"
              pattern="^[a-zA-Z\s]*$"
              restrict={true}
              value={data?.lastName}
              onChange={onChange}
            />
          </Form.Field>
        </Form.Group>)}
      <Form.Field width="16">
        <label htmlFor="cardNumber">Card Number</label>
        <input className={Styles.input} {...getCardNumberProps({ onChange: onCardChange})} value={data?.card.number} />
      </Form.Field>
      <Form.Group widths="2">
        <Form.Field>
          <label htmlFor="cardExpiry">Expiration Date</label>
          <input className={Styles.input} {...getExpiryDateProps({ onChange: onExpiryChange})} value={data?.card.expiry} />
        </Form.Field>
        <Form.Field>
          <label>CVV Code</label>
          <input className={Styles.input} {...getCVCProps({ onChange: onCvvChange})} value={data?.card.cvv} />
        </Form.Field>
        {!nameDisabled && (<Form.Field>
          <label>Zip/Postal Code</label>
          <SimpleForm.Input name="zip" value={data?.zip} fluid size="large" onChange={onChange} />
        </Form.Field>)}
        {captchaRef && (<Recaptcha
          ref={captchaRef}
          size="invisible"
          badge="bottomleft"
          sitekey={Settings.recaptchaToken}
          onChange={() => handleCaptcha(true)}
          onExpired={() => handleCaptcha(false)}
        />)}
      </Form.Group>
      {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
    </>
  )
}

export { AuthorizeNetCard }
