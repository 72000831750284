import React, {useState, VFC} from 'react'
import { Container, Loader, Button, Header, Label } from 'semantic-ui-react'
import {gql, useQuery} from '@apollo/client'
import {toMoney} from '../shared/format'
import {toDateString} from '../utils'
import Mutation from '../shared/mutation'
import Notifications from '../shared/notifications'

const styles = require('./Invoices.module.scss')

type Status = 'PAID' | 'POSTED' | 'PAYMENT_DUE' | 'NOT_PAID' | 'VOIDED' | 'PENDING'
type Invoice = { id: string, status: Status, createdAt: string, total: number }
type Query = { account: { customer: { invoices: Invoice[] } } }

const GENERATE_PDF = `mutation generatePdf($invoiceId: String!) {
  generatePdf(invoiceId: $invoiceId)
}`

const SYNC_INVOICES = `mutation syncInvoices {
  syncInvoices
}`


const DownloadInvoiceButton: VFC<{invoiceId: string}> = ({invoiceId}) => {
  const invoiceMutation = new Mutation<{ generatePdf: string }>(GENERATE_PDF)
  const [loading, setLoading] = useState(false)

  const generateLink = () => {
    setLoading(true)
    invoiceMutation.exec({invoiceId: invoiceId}).then(() => {
      if (invoiceMutation.hasErrors) {
        Notifications.error(invoiceMutation.error())
      } else if (invoiceMutation.data?.generatePdf) {
        Notifications.success('Invoice generated')
        window.open(invoiceMutation.data.generatePdf, '_blank')
      }
      setLoading(false)
    })
  }

  return <Button primary onClick={() => generateLink()} loading={loading}>View Invoice</Button>
}

export const Invoices = () => {
  const [syncLoading, setSyncLoading] = useState(false)
  const syncInvoiceMutation = new Mutation<{ syncInvoices: boolean }>(SYNC_INVOICES)

  const {data, loading: gqlLoading, refetch} = useQuery<Query>(
    gql`
        query {
          account {
            customer {
              invoices {
                id
                createdAt
                status
                total
              }
            }
          }
        }
      `
  )

  const syncInvoices = () => {
    setSyncLoading(true)
    syncInvoiceMutation.exec().then(() => {
      if (syncInvoiceMutation.hasErrors) {
        Notifications.error(syncInvoiceMutation.error())
      } else if (syncInvoiceMutation.data?.syncInvoices) {
        refetch().then(() => {
          Notifications.success('Invoices successfully synced')
          setSyncLoading(false)
        })
      }
    })
  }

  const invoices = data?.account?.customer?.invoices || []

  const listMarkup = () => {
    if (syncLoading || gqlLoading) { return null }

    return (<table width={'100%'} style={{marginTop: '20px'}}>
      <tbody>
        {invoices.length === 0 && <tr><td>No invoices at the moment.</td></tr>}
        {invoices.map(invoice => (
          <tr key={invoice.id}>
            <td width={'120px'}>{toDateString(invoice.createdAt)}</td>
            <td width={'70px'}>{toMoney(invoice.total/100.0)}</td>
            <td width={'80px'} style={{textAlign: 'center'}}>
              <div style={{display: 'inline-block'}}>
                <Label size={'tiny'} color={invoice.status === 'PAID' ? 'green' : 'yellow'}>{invoice.status}</Label>
              </div>
            </td>
            <td>Subscription</td>
            <td width={'120px'} align={'right'}>
              <DownloadInvoiceButton invoiceId={invoice.id} />
            </td>
          </tr>))}
      </tbody></table>)
  }

  return <Container className={styles.Base} fluid>
    <Button floated={'right'} onClick={syncInvoices}>Update</Button>
    <Header as="h5" content="Billing"/>
    {(gqlLoading || syncLoading) && <Loader active />}
    {listMarkup()}
  </Container>
}
