import { observer } from '../decorators'
import React, { FC } from 'react'
import Index from '.'

const SignIn: FC & { authorize: boolean | string; auth: string } = () => (<Index />)

SignIn.authorize = false
SignIn.auth = '/apps'

export default observer(SignIn)
