import React, { VFC, useState } from 'react'
import Router from '../../../shared/router'
import { PaymentSource } from '../../../type'
import { PaymentMethods } from './PaymentMethods'
import { PaymentMethodsAdd } from './PaymentMethodsAdd'
import { PaymentMethodsDelete } from './PaymentMethodsDelete'

const PaymentMethodsModal: VFC = () => {
  const [step, setStep] = useState<'payment-methods' | 'add-payment-method' | 'delete-payment-method'>(Router.qs.paypal ? 'add-payment-method' : 'payment-methods')
  const [paymentSource, setPaymentSource] = useState<PaymentSource>()

  const onDeletePressed = (source: PaymentSource) => {
    setPaymentSource(source)
    setStep('delete-payment-method')
  }

  switch(step) {
    case 'payment-methods':
      return <PaymentMethods onAddMorePressed={() => setStep('add-payment-method') } onDeletePressed={onDeletePressed} />
    case 'add-payment-method':
      return <PaymentMethodsAdd onBackPressed={() => setStep('payment-methods') } />
    case 'delete-payment-method':
      return <>{paymentSource && (<PaymentMethodsDelete
        paymentSource={paymentSource}
        onCancel={() => setStep('payment-methods')}
        onDeleted={() => setStep('payment-methods')}
      />)}</>
  }
}

export { PaymentMethodsModal }
