import React from 'react'
import { Toggle } from '../../Toggle'
import {Loader} from 'semantic-ui-react'

const styles = require('./ProtectionArea.module.scss')
const TickGreen = require('../../../assets/images/icons/tick-green.svg')
const LockImage = require('../../../assets/images/data-removal.svg')

interface Props {
  active: boolean
  onToggleActive: () => void

  togglingLoading: boolean
}
const ProtectionArea = (props: Props) => {
  const renderTick = (enabled: boolean, text: string) => (
    <div className={styles.tick}>
      {enabled && (<img src={TickGreen} />)}
      {!enabled && (<div className={styles.tickDisabled} />)}
      {text}
    </div>
  )

  const activeHtml = <>
    <h4>We are protecting your private info on the sites below.</h4>
    <div style={{ marginBottom: 20, marginTop: 23 }}>
      <span color="#787E8D">We&apos;re constantly scanning the major data brokers for any newly exposed info, and removing any info that appears.
        <br/><br/>
        This way you can stay safe from privacy risks that could impact your life.</span>
    </div>
  </>

  const inactiveHtml = <>
    <h4>Activate your Data Removal to reduce a risk of your private info exposure.</h4>

    <div style={{ marginBottom: 20, marginTop: 23 }}>
      <span color="#787E8D">Your private info such as phone numbers and email addresses put you in risk of</span>
    </div>

    <div className={styles.personalInformationContent}>
      <div className={styles.tickContainer}>
        {renderTick(props.active, 'Identity theft')}
        {renderTick(props.active, 'Robocalls')}
      </div>
      <div className={styles.tickContainer}>
        {renderTick(props.active, 'Email spam')}
        {renderTick(props.active, 'Stalkers and creeps')}
      </div>
    </div></>

  return (<div
    className={styles.protectionArea}
    style={{ backgroundImage: `url(${LockImage})` }}
  >
    <div className={styles.container}>
      <div className={styles.toggle}>
        <span>Your data removal is: <span className={props.active ? styles.on : styles.off}>{props.active ? 'ON' : 'OFF'}</span></span>
        {props.togglingLoading && (<Loader size={'tiny'} active={true} inline={true} className={styles.loader} />)}
        {!props.togglingLoading && (<Toggle selected={props.active} onChange={() => props.onToggleActive()} />)}
      </div>
      {props.active && activeHtml}
      {!props.active && inactiveHtml}
    </div>
  </div >)
}

export { ProtectionArea }
