import React, {VFC} from 'react'
import Select, { StylesConfig } from 'react-select'

const styles = require('./FormSelectField.module.scss')

type Option = {
  value: string
  label: string
}

type Props = {
  label: string
  value: Option | null
  onChange: (option: Option) => void
  options: Option[]
}

const SelectStyle: StylesConfig = {
  control: provided => ({
    ...provided,
    borderStyle: 'none',
    backgroundColor: '#f5f6f9',
    minHeight: '48px',
    fontWeight: 'bold'
  })
}

const FormSelectField:VFC<Props> = ({label, value, options, onChange}) => (
  <div className={styles.container}>
    <div className={styles.label}>{label}</div>
    <div className={styles.input}>
      <Select styles={SelectStyle} value={value} options={options} onChange={newValue => {
        onChange(newValue as Option)
      }} />
    </div>
  </div>
)

export { FormSelectField, Option }
