import React, { FC, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { FormContent, FormHeader } from '.'
import { SimpleForm } from '../../SimpleForm'
import { BirthdayInput } from './inputs'

const SocialSecurityNumberForm: FC = () => {
  const [ssn, setSSN] = useState('')
  const [ssnConfirmation, setSSNConfirmation] = useState('')

  return <div className="identity-security-form">
    <FormHeader
      image={require('../../../assets/images/identity-security/ssn.svg')}
      title="Social Security Number"
      text="Please fill in your social security number and date of birth"
    />
    <FormContent>
      <Form.Field>
        <label>Social Security Number</label>
        <SimpleForm.Input
          fluid
          value={ssn}
          required
          showValidation={ssn.length > 0 && ssn === ssnConfirmation}
          onChange={({value}) => setSSN(value)}
          loading={false}
        />
      </Form.Field>
      <Form.Field>
        <label>Confirm Social Security Number</label>
        <SimpleForm.Input
          fluid
          value={ssnConfirmation}
          required
          showValidation={ssnConfirmation.length > 0 && ssn === ssnConfirmation}
          onChange={({value}) => setSSNConfirmation(value)}
          loading={false}
        />
      </Form.Field>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <BirthdayInput birthdate={new Date()} onBirthdateChanged={() => { }} />
    </FormContent>
  </div>
}

SocialSecurityNumberForm.displayName = 'SocialSecurityNumberForm'

export { SocialSecurityNumberForm }
