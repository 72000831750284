import React, { FC, useState } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'
import { Modal } from 'semantic-ui-react'
import { AlertModal } from './AlertModal'
import { RenderNoAlerts, RenderAlertList } from '.'
import {IdentisafeAlert} from '../../../type'

type Props = {
  alerts: IdentisafeAlert[]
}

const Alerts: FC<Props> = ({alerts}) => {
  const [selectedAlert, setSelectedAlert] = useState<IdentisafeAlert | null>(null)

  return (<>
    <Modal className="identity-security-alert-modal" closeIcon open={selectedAlert !== null} onClose={() => setSelectedAlert(null)}>
      {selectedAlert && <AlertModal alert={selectedAlert} />}
    </Modal>
    <SimpleBox className="mt-2">
      <SimpleText size="title2">Alerts</SimpleText>
      {alerts.length === 0 && <RenderNoAlerts />}
      {alerts.length > 0 && <RenderAlertList alerts={alerts} getHelpClicked={setSelectedAlert} />}
    </SimpleBox></>)
}

Alerts.displayName = 'Alerts'

export { Alerts }
